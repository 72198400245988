import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

const FilesUploadingModal = ({
  isOpen,
  uploadSuccess,
  uploadError,
  handleClose,
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isOpen && !showModal) {
      setShowModal(true);
    }
  }, [isOpen, showModal]);

  const closeModal = () => {
    setShowModal(false);
    handleClose();
  };

  return (
    <Modal show={showModal} backdrop="static" centered onHide={closeModal}>
      <Modal.Body>
        {uploadSuccess ? (
          <div className="success-message text-center">
            <h2>Uploaded successfully!</h2>
            <Button className="btn btn-primary" variant="primary" onClick={closeModal}>
              OK
            </Button>
          </div>
        ) : uploadError ? (
          <div className="error-message text-center">
            <h2>Upload failed!</h2>
            <p>{uploadError}</p>
            <Button className="btn btn-primary" variant="primary" onClick={closeModal}>
              OK
            </Button>
          </div>
        ) : (
          <div className="spinner text-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="sr-only"></span>
            </Spinner>
            <h2>Uploading, please wait...</h2>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default FilesUploadingModal;
