import React from "react";
import ProbandForm from "../probandForm/ProbandForm";
import ParentsForm from "../parentsForm/ParentsForm";
import SiblingForm from "../siblingForm/SiblingForm";
import GrandParentsForm from "../grandParentForm/GrandParentsForm";
import ProbandPartnerForm from "../probandPartnerForm/ProbandPartnerForm";

const formComponents = {
  P: ProbandForm,
  PP: ParentsForm,
  PS: SiblingForm,
  PHS: SiblingForm,
  PPG: GrandParentsForm,
  PMG: GrandParentsForm,
  PFS: SiblingForm,
  PFHS: SiblingForm,
  PMS: SiblingForm,
  PMHS: SiblingForm,
  PPT: ProbandPartnerForm,
};

const QuestionnaireForm = ({ sendFormData, handleCancel, autoSaveFormData, nodeRelation, collapseAllExcept, savedChoice, currentChoice, formEditStatus}) => {
  const FormToShow = formComponents[nodeRelation] || null;

  return <>{FormToShow ? <FormToShow sendFormData={sendFormData} handleCancel={handleCancel} autoSaveFormData={autoSaveFormData} nodeRelation={nodeRelation} collapseAllExcept={collapseAllExcept} savedChoice={savedChoice} currentChoice={currentChoice} formEditStatus={formEditStatus}/> : null}</>;
};

export default QuestionnaireForm;
