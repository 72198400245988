import React, {useState, useEffect} from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setFormField, setSubmitted } from "../../../store/probandFormSlice";
import {setIsCollapsed as setIsParentsCollapsed} from "../../../store/parentsFormSlice";
import '../../../style.css';
import {
  AdditionalInfoInput,
  AdoptionInput,
  DobOrAgeInput,
  NameInput,
  AliveOrDeceasedInput,
  GenderInput,
} from "../commonFormFields/CommonFormFields";
import { validateForm, clearFieldError, adjustLayout, scrollToElement } from "../../../pages/utility/utility";
import CustomAccordion from "../../customAccordion/CustomAccordion";
import { UpdateAlert } from '../../customAlert/CustomAlert';
import useEditModeFormNode from '../../../hooks/useEditModeFormNode';

const ProbandForm = ({ sendFormData, autoSaveFormData, nodeRelation, collapseAllExcept, savedChoice, currentChoice, formEditStatus }) => {
  const dispatch = useDispatch();
  const currentlyEditingFormId = useEditModeFormNode();
  const formState = useSelector((state) => state.probandForm);
  const [errors, setErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    firstName,
    lastName,
    dob,
    age,
    isAlive,
    dateOfDeath,
    ageOfDeath,
    gender,
    isAdopted,
    additionalInfo,
    isSubmitted,
    isCollapsed,
    isEditMode,
  } = formState;
  const conversationTone = useSelector((state) => state.storage.conversationTone);
  const [showSaveChangesAlert, setShowSaveChangesAlert] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isSubmitted) {
        handleAutoSave();
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [formState, isSubmitted]);

  const getFormData = (isProbandFormSubmitted) => {
    return {
      firstName: firstName,
      lastName: lastName,
      dob: dob,
      age: age,
      isAlive: isAlive,
      dateOfDeath: isAlive ? null : dateOfDeath,
      ageOfDeath: isAlive ? null : ageOfDeath,
      gender: gender,
      isAdopted: isAdopted,
      additionalInfo: additionalInfo,
      isFormSubmitted: isProbandFormSubmitted,
      isEditMode: isEditMode,
    };
  };
  const toggleCollapse = (e) => {
    if(formEditStatus){
      setShowSaveChangesAlert(true);
      scrollToElement(currentlyEditingFormId);
      return;
    }
    e.preventDefault();
    collapseAllExcept(nodeRelation, !isCollapsed);
  };

  const handleAutoSave = () => {
    const formData = getFormData(false);
    const jsonFormData = JSON.stringify(formData);
    autoSaveFormData(jsonFormData, nodeRelation);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm({ gender, age }, nodeRelation);
    const hasErrors = Object.values(formErrors).some(value => value !== undefined);

    if (!hasErrors) {
      setIsSubmitting(true); 
      dispatch(setSubmitted(true));
      toggleCollapse(e);
      const formData = getFormData(true);
      const jsonFormData = JSON.stringify(formData);
      sendFormData(jsonFormData, conversationTone, nodeRelation, "NA");
      dispatch(setIsParentsCollapsed(false));
      setIsSubmitting(false); 
    } else {
      setErrors(formErrors);
    }
  };

  useEffect(() => {
    adjustLayout();
  }, [isCollapsed]);

  const handleSaveChangesAlertClose = () => {
    setShowSaveChangesAlert(false);
  };

  return (
    <div className={`${!isCollapsed ? 'chat-form-container' : 'collapsed-chat-form-container'}`}>
      <CustomAccordion
        title={`Proband : ${firstName} ${lastName}`}
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
        isSubmitted={isSubmitted}
        isHeading={true}
        nodeRelation={nodeRelation}
        savedChoice={savedChoice}
        currentChoice={currentChoice}
      >
        <Form className={`chat-form ${isCollapsed ? '' : 'show'}`} onSubmit={handleSubmit}>
          {!isCollapsed && (
            <>
              <NameInput
                disableFormFeilds={isSubmitted}
                firstName={firstName}
                lastName={lastName}
                setFirstName={(value) => {
                  dispatch(setFormField({ field: "firstName", value }));
                  clearFieldError("firstName", errors, setErrors);
                }}
                setLastName={(value) => dispatch(setFormField({ field: "lastName", value }))}
              />
              <DobOrAgeInput
                nodeRelation={nodeRelation}
                dob={dob}
                age={age}
                disableFormFeilds={isSubmitted}
                setDob={(value) => {
                  dispatch(setFormField({ field: "dob", value }));
                  clearFieldError("firstName", errors, setErrors);
                  clearFieldError("age", errors, setErrors);
                }}
                setAge={(value) => {
                  dispatch(setFormField({ field: "age", value }));
                  clearFieldError("age", errors, setErrors);
                }}
                error={errors.age}
              />
              <GenderInput
                gender={gender}
                setGender={(value) => {
                  dispatch(setFormField({ field: "gender", value }));
                  clearFieldError("gender", errors, setErrors);
                }}
                disableFormFeilds={isSubmitted}
                error={errors.gender}
              />
              <AliveOrDeceasedInput
                nodeRelation={nodeRelation}
                isAlive={isAlive}
                disableFormFeilds={isSubmitted}
                dateOfDeath={dateOfDeath}
                ageOfDeath={ageOfDeath}
                dob={dob}
                age={age}
                setIsAlive={(value) => dispatch(setFormField({ field: 'isAlive', value }))}
                setDateOfDeath={(value) => dispatch(setFormField({ field: 'dateOfDeath', value }))}
                setAgeOfDeath={(value) => dispatch(setFormField({ field: 'ageOfDeath', value }))}
              />
              <AdoptionInput
                nodeRelation={nodeRelation}
                isAdopted={isAdopted}
                disableFormFeilds={isSubmitted}
                setIsAdopted={(value) => dispatch(setFormField({ field: 'isAdopted', value }))}
              />
              <AdditionalInfoInput
                nodeRelation={nodeRelation}
                disableFormFeilds={isSubmitted}
                additionalInfo={additionalInfo}
                setAdditionalInfo={(value) => dispatch(setFormField({ field: 'additionalInfo', value }))}
              />
              {!isSubmitted && (
                <Button className="form-submit-btn" type="submit" variant="primary" disabled={isSubmitted}>
                  {isSubmitting ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    "Continue"
                  )}
                </Button>
              )}
            </>
          )}
        </Form>
      </CustomAccordion>
      {showSaveChangesAlert && (
        <UpdateAlert
          title="Unsaved Changes"
          message="Please save your changes in the previous form."
          onConfirm={handleSaveChangesAlertClose}
          confirmButtonText="OK"
        />
      )}
  
    </div>
  );
};

export default ProbandForm;
