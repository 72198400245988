import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import "./FeedbackForm.css"

const FeedbackForm = ({handleFeedbackSubmit,handleFeedbackInputChange}) => {
    const [feedbackText, setFeedbackText] = useState("");
    const handleInputChange = (event) => {
        setFeedbackText(event.target.value);
        handleFeedbackInputChange(event);
    };

    const submitFeedback = (event) => {
        if (event.key === 'Enter' && !event.shiftKey && feedbackText.trim()) {
            event.preventDefault();
            handleFeedbackSubmit(event);
        }
    };
    return (
        <div className="feedback-form">
            <section className="feedback-form-heading">
                <h2>Please Share Your Feedback</h2>
            </section>
            <section className="feedback-form-input">
                <Form onSubmit = {handleFeedbackSubmit}>
                    <Form.Label>What can we add to improve?</Form.Label>
                    <Form.Group className="mb-3">
                        <Form.Control as="textarea" onKeyUp={submitFeedback} onChange = {handleInputChange} placeholder="Your feedback" rows={6} required />
                    </Form.Group>
                    <div className="send-feedback-btn">
                    <Button variant="primary" type="submit" disabled={!feedbackText.trim()}>
                        Send
                    </Button>
                    </div>
                </Form>
            </section>
        </div>
    )
};

export default FeedbackForm;
