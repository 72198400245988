import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NameInput,
  DobOrAgeInput,
  GenderInput,
  AliveOrDeceasedInput,
  AdoptionInput,
  AdditionalInfoInput,
} from "../commonFormFields/CommonFormFields";
import { clearFieldError } from "../../../pages/utility/utility";
import {
  updateChildField as updateFatherFullSiblingChildField,
  toggleChildCollapse as toggleFatherFullSiblingChildCollapse,
  deleteChild as deleteFatherFullSiblingChild,
} from "../../../store/fatherFullSiblingFormSlice";
import {
  updateChildField as updateFatherHalfSiblingChildField,
  toggleChildCollapse as toggleFatherHalfSiblingChildCollapse,
  deleteChild as deleteFatherHalfSiblingChild,
} from "../../../store/fatherHalfSiblingFormSlice";
import {
  updateChildField as updateMotherFullSiblingChildField,
  toggleChildCollapse as toggleMotherFullSiblingChildCollapse,
  deleteChild as deleteMotherFullSiblingChild,
} from "../../../store/motherFullSiblingFormSlice";
import {
  updateChildField as updateMotherHalfSiblingChildField,
  toggleChildCollapse as toggleMotherHalfSiblingChildCollapse,
  deleteChild as deleteMotherHalfSiblingChild,
} from "../../../store/motherHalfSiblingFormSlice";
import {
  updateChildField as updateProbandPartnerChildField,
  toggleChildCollapse as toggleProbandPartnerChildCollapse,
  deleteChild as deleteProbandPartnerChild,
} from "../../../store/probandPartnerFormSlice";
import CustomAccordion from "../../customAccordion/CustomAccordion";

const Child = ({ parent, childIndex, nodeRelation, parentIndex = 0, errors, setErrors, setChildCount, buttonClicked,setButtonClicked, formErrors, setContinueButtonClicked, continueButtonClicked}) => {
  const dispatch = useDispatch();
  const fatherFullSiblingsChildren = useSelector(
    (state) => state.fatherFullSiblingForm.siblings[parentIndex]?.children[childIndex]
  );
  const fatherHalfSiblingsChildren = useSelector(
    (state) => state.fatherHalfSiblingForm.siblings[parentIndex]?.children[childIndex]
  );
  const motherFullSiblingsChildren = useSelector(
    (state) => state.motherFullSiblingForm.siblings[parentIndex]?.children[childIndex]
  );
  const motherHalfSiblingsChildren = useSelector(
    (state) => state.motherHalfSiblingForm.siblings[parentIndex]?.children[childIndex]
  );
  const probandPartnerChildren = useSelector((state) => state.probandPartnerForm.partner?.children[childIndex]);

  const isFatherFullSiblingSubmitted = useSelector((state) => state.fatherFullSiblingForm.isSubmitted);
  const isFatherHalfSiblingSubmitted = useSelector((state) => state.fatherHalfSiblingForm.isSubmitted);
  const isMotherFullSiblingSubmitted = useSelector((state) => state.motherFullSiblingForm.isSubmitted);
  const isMotherHalfSiblingSubmitted = useSelector((state) => state.motherHalfSiblingForm.isSubmitted);
  const isProbandPartnerSubmitted = useSelector((state) => state.probandPartnerForm.isSubmitted);

  const isEditModeInFatherFullSibling = useSelector((state) => state.fatherFullSiblingForm.isEditMode);
  const isEditModeFatherHalfSibling = useSelector((state) => state.fatherHalfSiblingForm.isEditMode);
  const isEditModeMotherFullSibling = useSelector((state) => state.motherFullSiblingForm.isEditMode);
  const isEditModeMotherHalfSibling = useSelector((state) => state.motherHalfSiblingForm.isEditMode);
  const isEditModeProbandPartner = useSelector((state) => state.probandPartnerForm.isEditMode);

  const getChildrenData = () => {
    switch (nodeRelation) {
      case "PFS":
        return {
          child: fatherFullSiblingsChildren,
          isSubmitted: isFatherFullSiblingSubmitted,
          isEditMode: isEditModeInFatherFullSibling,
        };
      case "PFHS":
        return {
          child: fatherHalfSiblingsChildren,
          isSubmitted: isFatherHalfSiblingSubmitted,
          isEditMode: isEditModeFatherHalfSibling,
        };
      case "PMS":
        return {
          child: motherFullSiblingsChildren,
          isSubmitted: isMotherFullSiblingSubmitted,
          isEditMode: isEditModeMotherFullSibling,
        };
      case "PMHS":
        return {
          child: motherHalfSiblingsChildren,
          isSubmitted: isMotherHalfSiblingSubmitted,
          isEditMode: isEditModeMotherHalfSibling,
        };
      case "PPT":
        return {
          child: probandPartnerChildren,
          isSubmitted: isProbandPartnerSubmitted,
          isEditMode: isEditModeProbandPartner,
        };
      default:
        return { child: null, isSubmitted: null, isEditMode: null };
    }
  };

  const { child, isSubmitted, isEditMode } = getChildrenData();

  const disableFormFeilds = isSubmitted && !isEditMode;

  const handleUpdateField = (field, value) => {
    switch (nodeRelation) {
      case "PFS":
        dispatch(updateFatherFullSiblingChildField({ siblingIndex: parentIndex, childIndex, field, value }));
        break;
      case "PFHS":
        dispatch(updateFatherHalfSiblingChildField({ siblingIndex: parentIndex, childIndex, field, value }));
        break;
      case "PMS":
        dispatch(updateMotherFullSiblingChildField({ siblingIndex: parentIndex, childIndex, field, value }));
        break;
      case "PMHS":
        dispatch(updateMotherHalfSiblingChildField({ siblingIndex: parentIndex, childIndex, field, value }));
        break;
      case "PPT":
        dispatch(updateProbandPartnerChildField({ childIndex, field, value }));
        break;
      default:
        break;
    }
  };

  const handleToggleChildCollapse = () => {
    switch (nodeRelation) {
      case "PFS":
        dispatch(toggleFatherFullSiblingChildCollapse({ siblingIndex: parentIndex, childIndex }));
        break;
      case "PFHS":
        dispatch(toggleFatherHalfSiblingChildCollapse({ siblingIndex: parentIndex, childIndex }));
        break;
      case "PMS":
        dispatch(toggleMotherFullSiblingChildCollapse({ siblingIndex: parentIndex, childIndex }));
        break;
      case "PMHS":
        dispatch(toggleMotherHalfSiblingChildCollapse({ siblingIndex: parentIndex, childIndex }));
        break;
      case "PPT":
        dispatch(toggleProbandPartnerChildCollapse({ childIndex }));
        break;
      default:
        break;
    }
  };

  const handleDeleteChild = () => {
    switch (nodeRelation) {
      case "PFS":
        dispatch(deleteFatherFullSiblingChild({ siblingIndex: parentIndex, childIndex }));
        break;
      case "PFHS":
        dispatch(deleteFatherHalfSiblingChild({ siblingIndex: parentIndex, childIndex }));
        break;
      case "PMS":
        dispatch(deleteMotherFullSiblingChild({ siblingIndex: parentIndex, childIndex }));
        break;
      case "PMHS":
        dispatch(deleteMotherHalfSiblingChild({ siblingIndex: parentIndex, childIndex }));
        break;
      case "PPT":
        dispatch(deleteProbandPartnerChild({ childIndex }));
        break;
      default:
        break;
    }
    setChildCount((prevCount) => prevCount - 1)
  };

  // useEffect(()=> {
  //   setButtonClicked(false);
  //   setContinueButtonClicked(false);
  // },[]);
  
  return (
    <>
      {childIndex === 0 && <hr className="chat-form-separator" />}
      <CustomAccordion
        title={`Child ${parent.children.length !== 1 ? `${childIndex + 1}` : ""} details : ${child?.firstName} ${child?.lastName}`}
        isCollapsed={child?.isChildCollapsed}
        toggleCollapse={handleToggleChildCollapse}
        isSubmitted={isSubmitted}
        isSubSection={true}
        nodeRelation={nodeRelation}
        siblingIndex={parentIndex}
        childIndex={childIndex}
        deleteNode={handleDeleteChild}
        isChildForm={true}
        isEditMode={isEditMode}
      >
        <NameInput
          firstName={child?.firstName}
          lastName={child?.lastName}
          disableFormFeilds={disableFormFeilds}
          setFirstName={(value) => {
            handleUpdateField("firstName", value);
            clearFieldError(`childFirstName${childIndex}`, errors, setErrors);
          }}
          setLastName={(value) => handleUpdateField("lastName", value)}
        />
        <DobOrAgeInput
          nodeRelation={`${nodeRelation}-children`}
          dob={child?.dob}
          age={child?.age}
          disableFormFeilds={disableFormFeilds}
          setDob={(value) => {
            handleUpdateField("dob", value);
            clearFieldError(`childAge${childIndex}`, errors, setErrors);
          }}
          setAge={(value) => {
            handleUpdateField("age", value);
            clearFieldError(`childAge${childIndex}`, errors, setErrors);
          }}
        />
        <GenderInput
          nodeRelation={`${nodeRelation}-children`}
          gender={child?.gender}
          setGender={(value) => {
            handleUpdateField("gender", value);
            clearFieldError(`childGender${childIndex}`, errors, setErrors);
          }}
          disableFormFeilds={disableFormFeilds}
          childIndex={childIndex}
          siblingIndex={parentIndex}
          error={errors?.[`childGender${childIndex}`]}
        />
        {/* {!continueButtonClicked && buttonClicked && formErrors.siblings[parentIndex]?.[`childGender${childIndex}`] && (
          <p style={{color:'#FD6262'}}>
            Gender is required.
          </p>
        )} */}
        <AliveOrDeceasedInput
          nodeRelation={`${nodeRelation}-children`}
          isAlive={child?.isAlive}
          disableFormFeilds={disableFormFeilds}
          dateOfDeath={child?.dateOfDeath}
          ageOfDeath={child?.ageOfDeath}
          setIsAlive={(value) => handleUpdateField("isAlive", value)}
          setDateOfDeath={(value) => handleUpdateField("dateOfDeath", value)}
          setAgeOfDeath={(value) => handleUpdateField("ageOfDeath", value)}
          siblingIndex={parentIndex}
          childIndex={childIndex}
        />
        <AdoptionInput
          nodeRelation={`${nodeRelation}-children`}
          isAdopted={child?.isAdopted}
          disableFormFeilds={disableFormFeilds}
          setIsAdopted={(value) => handleUpdateField("isAdopted", value)}
          siblingIndex={parentIndex}
          childIndex={childIndex}
        />
        <AdditionalInfoInput
          disableFormFeilds={disableFormFeilds}
          additionalInfo={child?.additionalInfo}
          setAdditionalInfo={(value) => handleUpdateField("additionalInfo", value)}
        />
      </CustomAccordion>
      <hr className="chat-form-separator" />
    </>
  );
};

export default Child;
