import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import probandFormReducer from "./probandFormSlice";
import storageReducer from "./storageSlice";
import parentsFormReducer from "./parentsFormSlice";
import probandFullSiblingFormReducer from "./probandFullSiblingFormSlice";
import probandHalfSiblingFormReducer from "./probandHalfSiblingFormSlice";
import paternalGrandParentFormReducer from "./paternalGrandParentFormSlice";
import maternalGrandParentFormReducer from "./maternalGrandParentFormSlice";
import fatherFullSiblingFormReducer from "./fatherFullSiblingFormSlice";
import fatherHalfSiblingFormReducer from "./fatherHalfSiblingFormSlice";
import motherFullSiblingFormReducer from "./motherFullSiblingFormSlice";
import motherHalfSiblingFormReducer from "./motherHalfSiblingFormSlice";
import probandPartnerFormReducer from "./probandPartnerFormSlice";

const CLEAR_SESSION = "CLEAR_SESSION";

const appReducer = combineReducers({
  storage: storageReducer,
  probandForm: probandFormReducer,
  parentsForm: parentsFormReducer,
  probandFullSiblingForm: probandFullSiblingFormReducer,
  probandHalfSiblingForm: probandHalfSiblingFormReducer,
  paternalGrandParentsForm: paternalGrandParentFormReducer,
  maternalGrandParentsForm: maternalGrandParentFormReducer,
  fatherFullSiblingForm: fatherFullSiblingFormReducer,
  fatherHalfSiblingForm: fatherHalfSiblingFormReducer,
  motherFullSiblingForm: motherFullSiblingFormReducer,
  motherHalfSiblingForm: motherHalfSiblingFormReducer,
  probandPartnerForm: probandPartnerFormReducer,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_SESSION) {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
export { CLEAR_SESSION };
