import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { UpdateAlert } from "../customAlert/CustomAlert"; // Import your custom alert component
import "./ChoiceSelector.css";
import { updateState } from "../../store/storageSlice";
import { setIsCollapsed as setIsProbandPartnerCollapsed } from "../../store/probandPartnerFormSlice";
import { setIsCollapsed as setIsPaternalGrandParentCollapsed } from "../../store/paternalGrandParentFormSlice";
import { setIsCollapsed as setIsProbandFullSiblingCollapsed } from "../../store/probandFullSiblingFormSlice";
import { setIsCollapsed as setIsProbandHalfSiblingCollapsed } from "../../store/probandHalfSiblingFormSlice";
import { setIsCollapsed as setIsFatherFullSiblingCollapsed } from "../../store/fatherFullSiblingFormSlice";
import { setIsCollapsed as setIsFatherHalfSiblingCollapsed } from "../../store/fatherHalfSiblingFormSlice";
import { setIsCollapsed as setIsMotherFullSiblingCollapsed } from "../../store/motherFullSiblingFormSlice";
import { setIsCollapsed as setIsMotherHalfSiblingCollapsed } from "../../store/motherHalfSiblingFormSlice";
import useEditModeFormNode from "../../hooks/useEditModeFormNode";
import { scrollToElement } from "../../pages/utility/utility";

const ChoiceSelector = ({
  sendFormData,
  setChoiceSelected,
  nodeRelation,
  choiceSelected = null,
  selectedConversationTone,
  messageIndex,
  collapseAllExcept,
  formEditStatus
}) => {
  const dispatch = useDispatch()
  const currentlyEditingFormId = useEditModeFormNode();
  const activeConversationTone = selectedConversationTone === "indirect" ? "Behalf" : selectedConversationTone === "direct" ? "Patient" : null;
  const [selected, setSelected] = useState(messageIndex !== 1 ? choiceSelected : activeConversationTone);
  const [choiceBtn, setChoiceBtn] = useState({ btn1: "", btn2: "" });
  const {conversationTone} = useSelector((state) => state.storage);
  const isBtn1Selected = !selected || selected === choiceBtn.btn1;
  const isBtn2Selected = !selected || selected === choiceBtn.btn2;
  const [showEditAlert, setShowEditAlert] = useState(false);
  useEffect(() => {
    setChoiceBtn(
      nodeRelation === "CT"
        ? { btn1: "Patient", btn2: "Behalf" }
        : { btn1: "Yes", btn2: "No" }
    );
  }, [nodeRelation]);

  const handleButtonClick = (value) => {
    if(formEditStatus){
      setShowEditAlert(true);
      scrollToElement(currentlyEditingFormId);
      return;
    }
    setSelected(value);

    if (nodeRelation === "CT") {
      const tone = value === "Behalf" ? "indirect" : "direct";
      let formData = {
        conversationTone : tone,
        form_action: "continue",
      }
      const jsonFormData = JSON.stringify(formData)
      dispatch(updateState({ field: 'conversationTone', value: tone }));
      collapseAllExcept("PP");
      sendFormData(jsonFormData, tone, "CT");
    } else {
      setChoiceSelected((prev) => ({ ...prev, [nodeRelation]: value }));
      collapseAllExcept(nodeRelation);

      if (value === "No") {
        sendFormData("{}",conversationTone, nodeRelation, "No");
        switch (nodeRelation) {
          case "PHS":
            dispatch(setIsPaternalGrandParentCollapsed(false));
            dispatch(setIsProbandHalfSiblingCollapsed(true));
            break;
          case "PS":
            dispatch(setIsProbandPartnerCollapsed(false));
            dispatch(setIsProbandFullSiblingCollapsed(true));
            break;
          case "PFS":
            dispatch(setIsProbandPartnerCollapsed(false));
            dispatch(setIsFatherFullSiblingCollapsed(true));
            break;
          case "PFHS":
            dispatch(setIsProbandPartnerCollapsed(false));
            dispatch(setIsFatherHalfSiblingCollapsed(true));
            break;
          case "PMS":
            dispatch(setIsProbandPartnerCollapsed(false));
            dispatch(setIsMotherFullSiblingCollapsed(true));
            break;
          case "PMHS":
            dispatch(setIsProbandPartnerCollapsed(false));
            dispatch(setIsMotherHalfSiblingCollapsed(true));
            break;
          case "PPT":
            dispatch(setIsProbandPartnerCollapsed(false));
            dispatch(setIsProbandPartnerCollapsed(true));
            break;
          default:
            break;
        }
      }
    }
  };

  useEffect(() => {
    if (nodeRelation === "CT") {
      setChoiceBtn({ btn1: "Patient", btn2: "Behalf" });
    }
    if (choiceSelected !== null || nodeRelation !== "CT") {
      setChoiceBtn({ btn1: "Yes", btn2: "No" });
    }
  }, []);

  return (
    <>
      <div className="choices-btn">
        {isBtn1Selected && (
          <div>
            {selected === choiceBtn.btn1 && (
              <div className="selected-choice-text">{choiceBtn.btn1}</div>
            )}
            {!selected && (
              <Button
                className={selected === choiceBtn.btn1 ? "selected" : ""}
                onClick={() => handleButtonClick(choiceBtn.btn1)}
              >
                {choiceBtn.btn1}
              </Button>
            )}
          </div>
        )}
        {isBtn2Selected && (
          <div>
            {selected === choiceBtn.btn2 && (
              <div className="selected-choice-text">{choiceBtn.btn2}</div>
            )}
            {!selected && (
              <Button
                className={selected === choiceBtn.btn2 ? "selected" : ""}
                onClick={() => handleButtonClick(choiceBtn.btn2)}
              >
                {choiceBtn.btn2}
              </Button>
            )}
          </div>
        )}
      </div>
      {showEditAlert && (
        <UpdateAlert
          title="Unsaved Changes"
          message="Please save your changes in the previous form."
          onConfirm={() => setShowEditAlert(false)}
          confirmButtonText="OK"
        />
      )}
    </>
  );
};

export default ChoiceSelector;
