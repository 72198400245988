import { Outlet } from "react-router-dom";
import { LoginContext } from "./LoginContext";
import { useState } from "react";

const ContextOutlet = () => {
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [isNewChat, setIsNewChat] = useState();
  const [sessionId, setSessionId] = useState("");
  const [filesUpload, setfilesUpload] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [path, setPath] = useState("");
  return (
    <LoginContext.Provider
      value={{
        name,
        setName,
        dob,
        setDob,
        phone,
        setPhone,
        isNewChat,
        setIsNewChat,
        sessionId,
        setSessionId,
        filesUpload,
        setfilesUpload,
        isLink,
        setIsLink,
        path,
        setPath,
      }}
    >
      <Outlet />
    </LoginContext.Provider>
  );
};

export default ContextOutlet;
