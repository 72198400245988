import "./index.css";
import React from "react";
import HomePage from "./pages/homePage/HomePage";
import ChatPage from "./pages/chatPage/ChatPage";
import TreePage from "./pages/treePage/TreePage";
import MediaPage from "./pages/mediaPage/MediaPage";
import TranscriptPage from "./pages/transcriptPage/TranscriptPage"; 
import { Navigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ContextOutlet from "./contexts/ContextOutlet";
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import FreeTreePage from "./pages/freeTreePage/FreeTreePage";
import FamilyTreePage from "./pages/familyTreePage/FamilyTreePage"
import DoctorListPage from "./pages/doctorListPage/DoctorListPage";
import PatientListPage from "./pages/patientListPage/PatientListPage";
import FallbackPage from "./pages/fallbackPage/FallbackPage";
import RegisterPage from "./pages/registerPage/RegisterPage";
import SuperAdminPage from "./pages/superAdminPage/SuperAdminPage";
import AllPatientsPage from "./pages/allPatientsPage/AllPatientsPage";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route element={<ContextOutlet />}>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/chat" element={<ChatPage />} />
            <Route path="/media" element={<MediaPage />} />
            <Route path="/tree" element={<TreePage />} />
            <Route path="/transcript" element={<TranscriptPage />} />
            <Route path="/:hashId" element={<FreeTreePage />} />
            <Route path="/familytree" element={<FamilyTreePage/>}/>
            <Route path="/superadmin" element={<SuperAdminPage/>}/>
            <Route path="/doctor" element={<DoctorListPage/>} />
            <Route path="/patient" element={<PatientListPage/>}/>
            <Route path="/fallback" element={<FallbackPage/>}/>
            <Route path="/register" element={<RegisterPage/>}/>
            <Route path="/allpatients" element={<AllPatientsPage/>}/>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
