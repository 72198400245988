import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SERVER_HOST } from "../../../env";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import "./PreviewTreeModal.css";
import { generatePedigreeTree, getUserType, getAccessToken, getOpts } from "../../../pages/utility/utility";
import Loader from "../../loader/Loader";

const host = SERVER_HOST;

function PreviewTreeModal({ show, onHide }) {
  const { userId } = useSelector((state) => state.storage);
  const opts = getOpts();
  const [treeLoader, setTreeLoader] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTreeLoader(true);
    if (show) {
      const token = getAccessToken();
      const userType = getUserType(token);

      if (userType === "patient") {
        const endpoint = `${host}/generate_tree`;
        axios
          .post(
            endpoint,
            {},
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          )
          .then((response) => {
            const data = response.data;
            if (data && data.json_data) {
              setTreeLoader(false);
              generatePedigreeTree(data.json_data, opts);
            } else {
              console.error("Error: Empty pedigree data set");
            }
          })
          .catch((error) => {
            console.error("Error generating tree:", error);
          });
      } else {
        navigate("/chat");
      }
    }
  }, [show, userId]);

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <div className="preview-tree-modal">
      <Modal.Header className="preview-tree-modal-header">
      <div className="modal-header-content">
        <Modal.Title className="modal-title-centered">Tree Preview</Modal.Title>
        <Button className="cross-btn" onClick={onHide}>X</Button>
      </div>
      </Modal.Header>
      <Modal.Body className="preview-tree-container">
        {treeLoader && <Loader />}
        <div className="button-overlay"></div>
        <div id="pedigree_history"></div>
        <div id="ped"></div>
        <div id="node_properties"></div>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
      </div>
    </Modal>
  );
}

export default PreviewTreeModal;
