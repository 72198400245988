import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import HomepageModal from "../HomepageModal";
import NotifyModal from "../notifyModal/NotifyModal";
import { SERVER_HOST } from "../../../env";
import axios from "axios";
import "./AgreementModal.css";
import { LoginContext } from "../../../contexts/LoginContext";
import { useDispatch, useSelector } from 'react-redux';
import { clearSession } from "../../../sessionStateHandler/sessionStateHandler";
import { updateState} from "../../../store/storageSlice";

const host = SERVER_HOST;

const AgreementModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isChecked, setChecked] = useState(false);
  const [homeModalShow, setHomeModalShow] = useState(false);
  const [notify, setNotify] = useState(null);
  const [showNotifyModal, setShowNotifyModal] = useState(false);

  const { isLink, setIsLink } = useContext(LoginContext);
  const { path, setPath } = useContext(LoginContext);
  const {userId, password} = useSelector((state) => state.storage)

  const handleApiNotify = (notifyMessage) => {
    setNotify(notifyMessage);
    setShowNotifyModal(true);
  };
  const handleCloseNotify = () => {
    setNotify(null);
    setShowNotifyModal(false);
  };

  const handleSubmit = async () => {
    const apiEndpoint = `${host}/verify_key`;
    try {
      const response = await axios.post(
        apiEndpoint,
        {
          user_id: userId,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      if (data.success) {
        document.cookie = `accessToken=${data.accessToken}; path=/;`;
        if (isLink) {
          navigate(path);
        }
        if (!data.hasExistingChat) {
          dispatch(updateState({field : "isNewChat", value : 1}));
          dispatch(updateState({field : "userName", value : data.userName}));
          navigate("/chat");
        } else {
          dispatch(updateState({field : "isNewChat", value : 0}));
          dispatch(updateState({field : "userName", value : data.userName}));
          setHomeModalShow(true);
        }
        dispatch(clearSession());
      } else {
        handleApiNotify(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response.status === 401){
        handleApiNotify(error.response.data.message)
      } else {
        handleApiNotify("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-box"
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Welcome to the GeneConnect</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list-div">
            <ul>
              <li>The Patient has been referred to the Manchester Centre for Genomic Medicine.</li>
              <li>
                To facilitate the assessment of the patient, this automated system will ask questions regarding the
                patient's personal, and family information.
              </li>
              <li>
                The information you provide will be processed and summarized for use and review by authorized medical
                professionals.
              </li>
              <li>
                The process generally takes 5-10 minutes. It is possible that this automated system may ask questions
                that may occasionally seem intrusive or inappropriately worded.
              </li>
              <li>We would greatly appreciate it if you could provide feedback to improve the system.</li>
              <li>
                Please note that this URL and the password are for your use only, and you must not share, distribute, or
                disclose them to any third party.
              </li>
            </ul>
          </div>
          <Form className="modal-checkbox">
            <Form.Check
              label="By checking this box, you acknowledge that you have read, understood, and agree to the information provided above."
              checked={isChecked}
              onChange={() => setChecked(!isChecked)}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={props.onHide}>
            Close
          </Button>
          <Button
            type="submit"
            id="submit-button"
            disabled={!isChecked}
            onClick={() => {
              handleSubmit();
              props.onHide();
            }}
            className="btn btn-primary"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <NotifyModal notifyMessage={notify} show={showNotifyModal} onClose={handleCloseNotify} isLoading={false} />
      <HomepageModal show={homeModalShow} onHide={() => setHomeModalShow(false)} />
    </>
  );
};

export default AgreementModal;
