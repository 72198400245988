import React, { useEffect, useState } from "react";
import { SERVER_HOST } from "../../env";
import "./DoctorListPage.css";
import { useNavigate } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import LogoutButton from "../../components/logoutButton/LogoutButton";
import DataTable from "../../components/dataTable/DataTable";
import NotifyModal from "../../components/modals/notifyModal/NotifyModal";
import {getUserType,getAccessToken} from "../utility/utility";

const host = SERVER_HOST;

function DoctorListPage() {
  const navigate = useNavigate();
  const [doctors, setDoctors] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [activeTab, setActiveTab] = useState("admins");
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(false);
  const [notifyError, setNotifyError] = useState(true);
  const [showNotifyModal, setShowNotifyModal] = useState(false);

  const handleApiNotify = (notifyMessage) => {
    setNotify(notifyMessage);
    setShowNotifyModal(true);
  };

  const handleCloseNotify = () => {
    setNotify(null);
    setShowNotifyModal(false);
  };
  const [onApprove, setOnApprove] = useState(null)

  const fetchDoctors = async () => {
    try {
      const response = await axios.get(`${host}/get_all_doctors`, {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      });  
      const data = response.data;
      setDoctors(data.doctor_data);
    } catch (error) {
      console.error("Error fetching doctors:", error);
    }
  };

  const fetchAdmins = async () =>{
    try{
      const response = await axios.get(`${host}/get_all_admins`, {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      });  
      const data = response.data;
      setAdmins(data.admin_data);
    }
    catch (error) {
      console.error("Error fetching doctors:", error);
    }
  };

  const handleApprove = async (userId, status) => {
    try {
      setOnApprove(userId)
      setLoading(true);
      const response = await axios.post(`${host}/change_user_status`, 
      {
        user_status: "approved",
        user_id: userId,
      },
      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      }
    );
      if (response.data.success) {
        if (userId[0] === "d") {
          const updatedDoctors = doctors.map((user) => {
            if (user.user_id === userId) {
              user.user_status = status === "approved" ? "pending" : "approved";
            }
            return user;
          });
          setOnApprove(null)
          setDoctors(updatedDoctors);
        }
        else if (userId[0] === "a") {
          const updatedAdmins = admins.map((user) => {
            if (user.user_id === userId) {
              user.user_status = status === "approved" ? "pending" : "approved";
            }
            return user
          });
          setOnApprove(null)
          setAdmins(updatedAdmins);
        }
      } else {
        console.error("Error updating user status on the server");
      }
    } catch (error) {
      console.error("Error updating user status:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const viewDocument = async (userId) => {
    try {
      const response = await axios.get(`${host}/view_document`,{
          params:{
              user_id: userId,
          },
          headers: {
            Authorization: `${getAccessToken()}`,
          },
          responseType: 'blob',
      });
      
      if (response.data){
          const url = URL.createObjectURL(new Blob([response.data],{type: "application/pdf"}));
          window.open(url)
      }
            
    } catch (error) {
        setNotifyError(true);
        handleApiNotify("Unable to view file");
        console.error('Error fetching file:', error);
    }
  }

  useEffect(() => {
    const token=getAccessToken();
    var userType=""
    if(token!==null){
      userType= getUserType(token);
    }
    if (userType==="super_admin") {
      fetchAdmins();
      fetchDoctors();
    } else {
      navigate("/fallback");
    }
  }, [navigate]);

  return (
    <div className="doctor-list-page">
      <div>
        <LogoutButton />
      </div>
      <div className="data-list">
        <Tabs
          id="tab"
          activeKey={activeTab}
          onSelect={(key) => handleTabChange(key)}
          className="list-tabs"
        >
          <Tab eventKey="admins" title="Admins">
            <DataTable
              listData={admins}
              handleApprove={handleApprove}
              viewDocument={viewDocument}
              loading={loading}
              onApprove={onApprove}
            />
          </Tab>
          <Tab eventKey="doctors" title="Doctors">
            <DataTable
              listData={doctors}
              handleApprove={handleApprove}
              viewDocument={viewDocument}
              loading={loading}
              onApprove={onApprove}
            />
          </Tab>
        </Tabs>
      </div>
      <NotifyModal
          notifyMessage={notify}
          show={showNotifyModal}
          onClose={handleCloseNotify}
          notifyError={notifyError}
      />
    </div>
  );
}

export default DoctorListPage;
