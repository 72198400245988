import React, { useEffect, useState, useRef } from "react";
import { SERVER_HOST } from "../../env";
import "./AllPatientsPage.css";
import { useNavigate, Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import * as XLSX from 'xlsx';
import axios from 'axios';
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import LogoutButton from "../../components/logoutButton/LogoutButton";
import PatientReferralModal from "../../components/modals/patientReferralModal/PatientReferralModal";
import NotifyModal from "../../components/modals/notifyModal/NotifyModal";
import {getUserType,getAccessToken,userCreatedAt} from "../utility/utility";

const host = SERVER_HOST;

const AllPatientsPage = () => {
  const navigate = useNavigate();
  const [patients, setPatients] = useState([]);
  const [referredPatients, setReferredPatients] = useState([]);
  const [emails, setEmails] = useState([]);
  const [addUserModal, setAddUserModal] = useState(false);
  const [notify, setNotify] = useState(false);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [isNotifyError, setIsNotifyError] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("referrals");
  const [currentUrl, setCurrentUrl] = useState('');
  const [patientEmail, setPatientEmail] = useState('@gmail.com')
  const [sendEmail, setSendEmail] = useState(null);
  const [disableFlag, setDisableFlag] = useState(false)
  const [referralMail, setReferralMail] = useState(false)
  const inputFileRef = useRef(null);

  const sortedPatients = [...referredPatients].sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at);
  });

  const handleApiNotify = (notifyMessage) => {
    setNotify(notifyMessage);
    setShowNotifyModal(true);
  };

  const handleCloseNotify = () => {
    setNotify(null);
    setShowNotifyModal(false);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleAddUser = () => {
    setPatientEmail("@gmail.com")
    setDisableFlag(false)
    setAddUserModal(true);
  };

  const getSourceFromHashId = (hashId) => {
    const lastLetter = hashId.slice(-1).toLowerCase();
    switch (lastLetter) {
      case "m":
        return "Media";
      case "c":
        return "Chat";
      case "t":
        return "Transcript";
      default:
        return "";
    }
  };

  const handleFileChange = (event) => {
    setIsFileSelected(!!event.target.files.length);
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    if (!file.name.endsWith('.xlsx')) {
      handleApiNotify("Please upload a valid Excel file (.xlsx)")
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const emailsArray = XLSX.utils.sheet_to_json(sheet, { header: 'A' });
        const extractedEmails = emailsArray.slice(0, 3).map((item) => item.A);
        setEmails(extractedEmails);
      } catch (error) {
        handleApiNotify("Error reading Excel file. Please try again.")
        console.error('Error reading Excel file:', error);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${host}/get_patients`, {
        params: {
          patient_type: "patients"
        },
        headers: {
          Authorization: `${getAccessToken()}`
        } 
      });
      setPatients(response.data.patient_data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchReferredPatients = async () => {
    try {
      const response = await axios.get(`${host}/get_patients`, {
        params: {
          patient_type: "referrals"
        },
        headers: {
          Authorization: `${getAccessToken()}`
        } 
      });
      setReferredPatients(response.data.patient_data);
    }
    catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSendMail = (userEmail) => {
    setReferralMail(true)
    setAddUserModal(true)
    setDisableFlag(true)
    setPatientEmail(userEmail)
    setSendEmail(userEmail);
  };

  const handleUpload = async () => {
    try {
      const response = await axios.post(`${host}/create_patient`, {
        patients: emails,
        mail_message: "",
        add_user_flag: "false"
      },
      {
        headers: {
          Authorization: `${getAccessToken()}`
        } 
      }
      );

      if (response.data.success) {
        setIsNotifyError(false);
        handleApiNotify(response.data.message);
        fetchReferredPatients();
        if (inputFileRef.current) {
          inputFileRef.current.value = null;
        }
        setIsFileSelected(false);
      }
      else {
        setIsNotifyError(true);
        handleApiNotify(response.data.message);
        console.error("Invalid entry");
        if (inputFileRef.current) {
          inputFileRef.current.value = null;
        }
        setIsFileSelected(false);
      }
    }
    catch (error) {
      setIsFileSelected(false);
      setIsNotifyError(true);
      handleApiNotify("Failed to add new entry");
      console.error("Error", error);
    }
  }

  useEffect(() => {
    const token=getAccessToken();
    var userType=""
    if(token!==null){
      userType = getUserType(token);
    }
    if (userType==="admin") {
      fetchPatients();
      fetchReferredPatients();
      const url = window.location.href;
      setCurrentUrl(url);
    }
    else {
      navigate("/fallback");
    }
  }, [navigate]);

  return (
    <div className="patient-list-page">
      <LogoutButton />

      <div className="patient-data-list">
        <Tabs
          id="tab"
          activeKey={activeTab}
          onSelect={(key) => handleTabChange(key)}
          className="client-page-tabs"
        >
          <Tab eventKey="referrals" title="Referrals">
            <div className="patient-list-container">
              <div className="add-user-section">
                <div className="file-upload-input">
                  <Form.Group controlId="formFile">
                    <Form.Label className="file-input-label"><strong>Upload File</strong></Form.Label>
                    <Form.Control type="file" accept=".xlsx" ref={inputFileRef} onChange={handleFileChange} />
                  </Form.Group>
                  <div className="file-upload-btn">
                    <Button onClick={handleUpload} disabled={!isFileSelected} className="upload-button">Upload File</Button>
                  </div>
                </div>
                <div className="add-user-icon" onClick={handleAddUser}>
                  <Button className="icon-btn">
                    <i class="fa-solid fa-user-plus"></i>
                  </Button>
                </div>
              </div>
              <Table responsive size="xl">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Email</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedPatients.map((user, index) => (
                    <tr key={index}>
                      <td>{user.user_id}</td>
                      <td>{user.user_email}</td>
                      <td>
                        {user.user_status === "new" ?
                          <Button className="send-mail-btn"
                            onClick={() => {
                              handleSendMail(user.user_email)
                            }
                            }
                          >
                            Send mail
                          </Button> :
                          <p>Mail sent</p>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Tab>
          <Tab eventKey="patients" title="Patients">
            <div className="patient-list-container">
              <Table responsive size="xl">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>DOB</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Source</th>
                    <th>Pedigree Tree</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {patients.map((user, index) => (
                    <tr key={index}>
                      <td>{user.user_id}</td>
                      <td>{user.user_name}</td>
                      <td>{user.user_dob}</td>
                      <td>{user.user_phone}</td>
                      <td>{user.user_email}</td>
                      <td>{getSourceFromHashId(user.hash_id)}</td>
                      <td>
                        {user.user_status === "signedUp" ?
                          <p>Not generated</p>
                          :
                          <Link
                            to={`/${user.hash_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button className="btn btn-primary send-mail-btn">
                              View Tree
                            </Button>
                          </Link>
                        }
                      </td>
                      <td>{user.created_at === "" ? "None" : userCreatedAt(user.created_at)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Tab>
        </Tabs>
      </div>
      <PatientReferralModal
        show={addUserModal}
        onHide={() => setAddUserModal(false)}
        onClose={fetchReferredPatients}
        currentUrl={window.location.href}
        patientEmail={patientEmail}
        referralMail={referralMail}
        disableFlag = {disableFlag}
      />
      <NotifyModal
        notifyMessage={notify}
        show={showNotifyModal}
        onClose={handleCloseNotify}
        notifyError={isNotifyError}
      />
    </div>
  );
};

export default AllPatientsPage;
