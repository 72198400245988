import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import {
  addChild as addFatherFullSiblingChild,
  updateSiblingField as updateFatherFullSiblingField,
} from "../../../store/fatherFullSiblingFormSlice";
import {
  addChild as addFatherHalfSiblingChild,
  updateSiblingField as updateFatherHalfSiblingField,
} from "../../../store/fatherHalfSiblingFormSlice";
import {
  addChild as addMotherFullSiblingChild,
  updateSiblingField as updateMotherFullSiblingField,
} from "../../../store/motherFullSiblingFormSlice";
import {
  addChild as addMotherHalfSiblingChild,
  updateSiblingField as updateMotherHalfSiblingField,
} from "../../../store/motherHalfSiblingFormSlice";
import {
  addChild as addProbandPartnerChild,
  updateField as updateProbandPartnerField,
} from "../../../store/probandPartnerFormSlice";
import Child from "./Child";
import {getMaxNodeCount} from "../../../pages/utility/utility"
import { UpdateAlert } from "../../customAlert/CustomAlert";

const ChildForm = ({ nodeRelation, parentIndex = 0, errors, setErrors, hasErrors, formErrors, setButtonClicked, buttonClicked, setContinueButtonClicked, continueButtonClicked }) => {
  const fatherFullSibling = useSelector((state) => state.fatherFullSiblingForm.siblings[parentIndex]);
  const fatherHalfSibling = useSelector((state) => state.fatherHalfSiblingForm.siblings[parentIndex]);
  const motherFullSibling = useSelector((state) => state.motherFullSiblingForm.siblings[parentIndex]);
  const motherHalfSibling = useSelector((state) => state.motherHalfSiblingForm.siblings[parentIndex]);
  const probandPartner = useSelector((state) => state.probandPartnerForm.partner);

  const isFatherFullSiblingSubmitted = useSelector((state) => state.fatherFullSiblingForm.isSubmitted);
  const isFatherHalfSiblingSubmitted = useSelector((state) => state.fatherHalfSiblingForm.isSubmitted);
  const isMotherFullSiblingSubmitted = useSelector((state) => state.motherFullSiblingForm.isSubmitted);
  const isMotherHalfSiblingSubmitted = useSelector((state) => state.motherHalfSiblingForm.isSubmitted);
  const isProbandPartnerSubmitted = useSelector((state) => state.probandPartnerForm.isSubmitted);

  const isEditModeInFatherFullSibling = useSelector((state) => state.fatherFullSiblingForm.isEditMode);
  const isEditModeFatherHalfSibling = useSelector((state) => state.fatherHalfSiblingForm.isEditMode);
  const isEditModeMotherFullSibling = useSelector((state) => state.motherFullSiblingForm.isEditMode);
  const isEditModeMotherHalfSibling = useSelector((state) => state.motherHalfSiblingForm.isEditMode);
  const isEditModeProbandPartner = useSelector((state) => state.probandPartnerForm.isEditMode);
  const [showFillDetailsAlert, setShowFillDetailsAlert] = useState(false);
  const [showChildrenLimitAlert, setShowChildrenLimitAlert] = useState(false);

  const dispatch = useDispatch();

  const getSiblingsData = () => {
    switch (nodeRelation) {
      case "PFS":
        return { parent: fatherFullSibling, isSubmitted: isFatherFullSiblingSubmitted, isEditMode: isEditModeInFatherFullSibling };
      case "PFHS":
        return { parent: fatherHalfSibling, isSubmitted: isFatherHalfSiblingSubmitted, isEditMode: isEditModeFatherHalfSibling };
      case "PMS":
        return { parent: motherFullSibling, isSubmitted: isMotherFullSiblingSubmitted, isEditMode: isEditModeMotherFullSibling };
      case "PMHS":
        return { parent: motherHalfSibling, isSubmitted: isMotherHalfSiblingSubmitted, isEditMode: isEditModeMotherHalfSibling };
      case "PPT":
        return { parent: probandPartner, isSubmitted: isProbandPartnerSubmitted, isEditMode: isEditModeProbandPartner };
      default:
        return { parent: null, isSubmitted: null, isEditMode: null };
    }
  };

  const { parent, isSubmitted, isEditMode } = getSiblingsData();

  const [childCount, setChildCount] = useState(1);
  const maxChildCount = getMaxNodeCount();
  const displayAddButton = !isSubmitted || isEditMode;

  const handleUpdateField = (field, value) => {
    switch (nodeRelation) {
      case "PFS":
        dispatch(updateFatherFullSiblingField({ index: parentIndex, field, value }));
        break;
      case "PFHS":
        dispatch(updateFatherHalfSiblingField({ index: parentIndex, field, value }));
        break;
      case "PMS":
        dispatch(updateMotherFullSiblingField({ index: parentIndex, field, value }));
        break;
      case "PMHS":
        dispatch(updateMotherHalfSiblingField({ index: parentIndex, field, value }));
        break;
      case "PPT":
        dispatch(updateProbandPartnerField({ hasChild: value }));
        break;
      default:
        break;
    }
  };
  const clearChildrenData = () => {
    switch (nodeRelation) {
      case "PFS":
        dispatch(updateFatherFullSiblingField({ index: parentIndex, field: "children", value: [] }));
        break;
      case "PFHS":
        dispatch(updateFatherHalfSiblingField({ index: parentIndex, field: "children", value: [] }));
        break;
      case "PMS":
        dispatch(updateMotherFullSiblingField({ index: parentIndex, field: "children", value: [] }));
        break;
      case "PMHS":
        dispatch(updateMotherHalfSiblingField({ index: parentIndex, field: "children", value: [] }));
        break;
      case "PPT":
        dispatch(updateProbandPartnerField({ children: [] }));
        break;
      default:
        break;
    }
    setChildCount(1);
  };

  const addChild = () => {
    if (hasErrors) {
      setShowFillDetailsAlert(true);
      return;
    }
    
    if (childCount >= maxChildCount) {
      setShowChildrenLimitAlert(true);
      return;
    }
    
    switch (nodeRelation) {
      case "PFS":
        dispatch(addFatherFullSiblingChild(parentIndex));
        break;
      case "PFHS":
        dispatch(addFatherHalfSiblingChild(parentIndex));
        break;
      case "PMS":
        dispatch(addMotherFullSiblingChild(parentIndex));
        break;
      case "PMHS":
        dispatch(addMotherHalfSiblingChild(parentIndex));
        break;
      case "PPT":
        dispatch(addProbandPartnerChild());
        break;
      default:
        break;
    }
    
    setChildCount((prevCount) => prevCount + 1);
  };

  const handleShowFillDetailsAlertClose = () => {
    setShowFillDetailsAlert(false);
  };

  const handleShowChildrenLimitAlertClose = () => {
    setShowChildrenLimitAlert(false);
  };
  return (
    <>
      <Form.Group className="chat-form-component-spacing">
        <label className="custom-radio-button">
          Yes
          <input
            type="radio"
            name={`${nodeRelation}-has-child-${parentIndex}`}
            value={true}
            checked={parent.hasChild === true}
            onChange={(e) => {
              handleUpdateField("hasChild", e.target.value === "true");
            }}
            disabled={isSubmitted && !isEditMode}
          />
          <span className="checkmark"></span>
        </label>
        <label className="custom-radio-button">
          No
          <input
            type="radio"
            name={`${nodeRelation}-has-child-${parentIndex}`}
            value={false}
            checked={parent.hasChild === false}
            onChange={(e) => {
              handleUpdateField("hasChild", e.target.value === "true");
              if (e.target.value === "false") {
                clearChildrenData();
              }
            }}
            disabled={isSubmitted && !isEditMode}
          />
          <span className="checkmark"></span>
        </label>
      </Form.Group>
      {parent.hasChild && (
        <>
          {parent.children.map((child, childIndex) => (
            <div key={childIndex} className="child-form">
              <Child
                parent={parent}
                childIndex={childIndex}
                parentIndex={parentIndex}
                nodeRelation={nodeRelation}
                errors={errors}
                setErrors={setErrors}
                setChildCount = {setChildCount}
                formErrors={formErrors}
                buttonClicked={buttonClicked}
                setButtonClicked={setButtonClicked}
                setContinueButtonClicked= {setContinueButtonClicked}
                continueButtonClicked = {continueButtonClicked}
              />
            </div>
          ))}
          {displayAddButton && (
            <div className="add-child-btn-section element-spacing">
              <Button className="add-child-btn"
              onClick={() => {
                addChild();
              }}>
                <span className="plus-icon">+</span> Add Child
              </Button>
            </div>
          )}
        {showFillDetailsAlert && (
        <UpdateAlert
          title="Fill Mandatory Details"
          message="Please fill the mandatory details first!"
          onConfirm={handleShowFillDetailsAlertClose}
          confirmButtonText="OK"
        />
      )}
        {showChildrenLimitAlert && (
        <UpdateAlert
          title="Children Limit Reached"
          message={`You cannot add more than ${maxChildCount} children.`}
          onConfirm={handleShowChildrenLimitAlertClose}
          confirmButtonText="OK"
        />
      )}
        </>
      )}
    </>
  );
};

export default ChildForm;
