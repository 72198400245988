import React from 'react';
import './CustomAlert.css';

const CustomAlert = ({ title, message, onConfirm, onCancel }) => {
  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert-box">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="custom-alert-actions">
          <button className="custom-alert-confirm" onClick={onConfirm}>
            Yes
          </button>
          <button className="custom-alert-cancel" onClick={onCancel}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

const UpdateAlert = ({ title, message, onConfirm }) => {
  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert-box">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="custom-alert-actions">
          <button className="custom-alert-confirm" onClick={onConfirm}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export { CustomAlert, UpdateAlert };
