import { useSelector } from "react-redux";
import { useMemo } from "react";

const useEditModeFormNode = () => {
  const editModeStatus = useSelector((state) => {
    if (state.probandForm.isEditMode) return "P";
    if (state.parentsForm.isEditMode) return "PP";
    if (state.probandFullSiblingForm.isEditMode) return "PS";
    if (state.probandHalfSiblingForm.isEditMode) return "PHS";
    if (state.paternalGrandParentsForm.isEditMode) return "PPG";
    if (state.maternalGrandParentsForm.isEditMode) return "PMG";
    if (state.fatherFullSiblingForm.isEditMode) return "PFS";
    if (state.fatherHalfSiblingForm.isEditMode) return "PFHS";
    if (state.motherFullSiblingForm.isEditMode) return "PMS";
    if (state.motherHalfSiblingForm.isEditMode) return "PMHS";
    if (state.probandPartnerForm.isEditMode) return "PPT";
    return null;
  });

  useMemo(() => {
    if (editModeStatus) {
      console.log("Form in edit mode:", editModeStatus);
    }
  }, [editModeStatus]);

  return editModeStatus;
};

export default useEditModeFormNode;

