import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { updateState } from "../../store/storageSlice";
import "../../style.css";

function HomepageModal(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleExistingChatClick = () => {
    props.onHide();
    dispatch(updateState({field : "isNewChat", value : 0 }));
    navigate("/chat");
  };
  const handleNewChatClick = () => {
    props.onHide();
    dispatch(updateState({field : "isNewChat", value : 1}));
    navigate("/chat");
  };

  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>Select Conversation</Modal.Header>

      <Modal.Body>
        <p>We've found an existing chat of yours</p>
      </Modal.Body>

      <Modal.Footer centered="true">
        <div className="home-page-modal-btn">
          <Button className="btn btn-primary new-chat-btn" variant="success" onClick={handleNewChatClick}>
            New Chat
          </Button>
          <Button className="btn btn-primary existing-chat-btn" variant="primary" onClick={handleExistingChatClick}>
            Existing Chat
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default HomepageModal;
