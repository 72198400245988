import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Spinner } from "react-bootstrap";
import "../../../style.css";
import {
  NameInput,
  DobOrAgeInput,
  AliveOrDeceasedInput,
  AdoptionInput,
  AdditionalInfoInput,
  DivorcedAndRelatedByBloodInput,
  PregnancyLossInput,
} from "../commonFormFields/CommonFormFields";
import { validateForm, clearFieldError, adjustLayout, scrollToElement } from "../../../pages/utility/utility";
import {
  updateField,
  setSubmitted,
  setIsEditMode,
  setIsSkipped,
  setIsResumed,
  setIsCollapsed as setIsProbandPartnerCollapsed,
} from "../../../store/probandPartnerFormSlice";
import CustomAccordion from "../../customAccordion/CustomAccordion";
import ChildForm from "../childForm/ChildForm";
import {CustomAlert,UpdateAlert} from "../../customAlert/CustomAlert";
import useEditModeFormNode from "../../../hooks/useEditModeFormNode";

const ProbandPartnerForm = ({ sendFormData, handleCancel, autoSaveFormData, nodeRelation, collapseAllExcept, savedChoice, currentChoice, formEditStatus }) => {
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const currentlyEditingFormId = useEditModeFormNode();
  const [isAdditionalInfoCollapsed, setIsAdditionalInfoCollapsed] = useState(true);
  const [isChildrenCollapsed, setIsChildrenCollapsed] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const {
    partner,
    isSubmitted,
    isEditMode,
    isSkipped,
    isResumed,
    isCollapsed: isProbandPartnerCollapsed,
  } = useSelector((state) => state.probandPartnerForm);
  const { gender: probandGender } = useSelector((state) => state.probandForm);
  const { conversationTone } = useSelector((state) => state.storage);
  const partnerType = probandGender === "male" ? "wife" : "husband";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const disableFormFeilds = isSubmitted && !isEditMode;
  const displaySkipButton = !isSubmitted;
  const alertTitle = `Are you sure you want to ${isEditMode ? "cancel?" : "skip?"}`;
  const alertMessage = isEditMode ? "Unsaved data may be lost." : "Don’t worry, you can anytime open this section and fill the details.";;
  const [showSuccessAlert, setShowSuccessAlert] = useState(false); 
  const [initialpartner, setInitialPartner] = useState(partner);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [showSaveChangesAlert, setShowSaveChangesAlert] = useState(false);

  useEffect(() => {
    if (isEditMode) {
      setInitialPartner(partner);
      setIsFormChanged(false);
    }
  }, [isEditMode]);

  useEffect(() => {
    const hasPartnerChanged = JSON.stringify(partner) !== JSON.stringify(initialpartner);
    setIsFormChanged(hasPartnerChanged);
  }, [partner]);

  const formState = useSelector((state) => state.probandForm);
  const probandAge= formState.age;
  const probandpartnerAge= partner.age;
  const probandchildAge = partner.children.map(child => child.age);
  const formErrors = validateForm({ partner, partnerType }, nodeRelation);
  const hasErrors = !Object.values(formErrors).every((value) => {
    // Check if the current value is an array
    if (Array.isArray(value)) {
      return value.every((item) =>
        // For arrays, ensure that every item in the array has undefined values
        Object.values(item).every((itemValue) => itemValue === undefined)
      );
    }
    // Check if the current value is an object
    else if (typeof value === "object" && value !== null) {
      // For object, ensure that every fiels in the object has undefined values
      return Object.values(value).every((itemValue) => itemValue === undefined);
    }
    // For primitive values, check if the value is undefined
    else {
      return value === undefined;
    }
  });

  const getFormData = (isProbandPartnerSubmitted, isProbandPartnerFormSkipped) => {
    return {
      ...partner,
      isFormSubmitted: isProbandPartnerSubmitted,
      isEditMode: isEditMode,
      isFormSkipped: isProbandPartnerFormSkipped,
    };
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isSubmitted || isEditMode) {
        handleAutoSave();
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [partner, isSubmitted, isEditMode]);

  const handleAutoSave = () => {
    const isFormSubmitted = isEditMode ? true : false
    const formData = getFormData(isFormSubmitted, isSkipped);
    const jsonFormData = JSON.stringify(formData);
    autoSaveFormData(jsonFormData, nodeRelation);
  };

  const validateAgeDifference = (probandAge, probandpartnerAge, probandchildAge) => {  
    probandAge = Number(probandAge);
    probandpartnerAge = probandpartnerAge ? Number(probandpartnerAge) : null;
    probandchildAge = Number(probandchildAge);
    const isProbandValid = probandAge >= probandchildAge + 15; 
    const isProbandPartnerValid = probandpartnerAge !== null ? probandpartnerAge >= probandchildAge + 15 : true;
    return {
      isProbandValid,
      isProbandPartnerValid,
      errors: {
        proband: isProbandValid ? null : "The age difference between parents and their offspring must be more than 15 years",
        partner: isProbandPartnerValid ? null : "The age difference between parents and their offspring must be more than 15 years"
      }
    };
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!isEditMode || isFormChanged) {
      handleSubmit();
    }
  };

  const handleSubmit = (event) => {
    const formErrors = validateForm({ partner, partnerType }, nodeRelation);
    const hasErrors = !Object.values(formErrors).every(value => {
      // Check if the current value is an array
      if (Array.isArray(value)) {
        return value.every(item =>
          // For arrays, ensure that every item in the array has undefined values
          Object.values(item).every(itemValue => itemValue === undefined)
        );
      } 
      // Check if the current value is an object
      else if (typeof value === 'object' && value !== null) {
        // For object, ensure that every field in the object has undefined values
        return Object.values(value).every(itemValue => itemValue === undefined);
      } 
      // For primitive values, check if the value is undefined
      else {
        return value === undefined;
      }
    }); 
    if (!hasErrors) {
      setIsSubmitting(true)
      const formAction = isEditMode || savedChoice === "No" || currentChoice === "No" ? "update" : "continue";
      dispatch(setIsProbandPartnerCollapsed(true));
      dispatch(setSubmitted(true));
      const formData = getFormData(true, false);
      const jsonFormData = JSON.stringify(formData);
      sendFormData(jsonFormData, conversationTone, nodeRelation, "Yes", formAction)
        .then(() => {
          if (isEditMode) {
            setShowSuccessAlert(true);
            handleToggleCollapse(formAction)
          }
        })
        .catch(() => {
          if (isEditMode) {
            alert("Error updating data. Please try again.");
          }
        });
      dispatch(setIsEditMode(false));
      setIsSubmitting(false)
    } else {
      setErrors(formErrors);
    }
  };

  const handleClose = () => {
    setShowAlert(true);
  }

  const handleSkip = () => {
    setShowAlert(false)
    const formAction = "skip";
    const formData = getFormData(false, true);
    if (!isResumed) {
      sendFormData(JSON.stringify(formData), conversationTone, nodeRelation, "Yes", formAction);
    }
    dispatch(setIsSkipped(true));
    dispatch(setSubmitted(false));
    dispatch(setIsResumed(false));
    dispatch(setIsProbandPartnerCollapsed(true));
  };

  const handleCancelEdit = () => {
    setShowAlert(false);
    handleCancel(nodeRelation, "cancel");
  };

  const handleToggleCollapse = (formAction) => {
    if( formEditStatus && formAction !== 'update' || formAction === 'continue'){
      setShowSaveChangesAlert(true);
      scrollToElement(currentlyEditingFormId);
      return;
    }
    dispatch(setIsEditMode(false));
    collapseAllExcept(nodeRelation, !isProbandPartnerCollapsed);
  }

  useEffect(() => {
    adjustLayout();
  }, [isProbandPartnerCollapsed]);

  const handleEditClick = () => {
    if(formEditStatus){
      setShowSaveChangesAlert(true);
      scrollToElement(currentlyEditingFormId);
      return;
    }
    dispatch(setIsEditMode(true)); 
    collapseAllExcept(nodeRelation, false); 
  };

  const handleSaveChangesAlertClose = () => {
    setShowSaveChangesAlert(false);
  };
  
  return (
    <div className={`${!isProbandPartnerCollapsed ? "chat-form-container" : "collapsed-chat-form-container"}`}>
      <CustomAccordion
        title={`Proband's Spouse`}
        isCollapsed={isProbandPartnerCollapsed}
        toggleCollapse={(e) => handleToggleCollapse(e)}
        isSubmitted={isSubmitted}
        isSkipped={isSkipped}
        isHeading={true}
        handleEditClick={handleEditClick}
        savedChoice={savedChoice}
        nodeRelation={nodeRelation}
        currentChoice = {currentChoice}
        formEditStatus={isEditMode}
      >
        <Form onSubmit={submitForm} className="chat-form">
          <NameInput
            firstName={partner.firstName}
            lastName={partner.lastName}
            disableFormFeilds={disableFormFeilds}
            setFirstName={(value) => {
              dispatch(updateField({ firstName: value }));
              clearFieldError("firstName", errors, setErrors);
            }}
            setLastName={(value) => dispatch(updateField({ lastName: value }))}
          />
          <DobOrAgeInput
            nodeRelation={nodeRelation}
            dob={partner.dob}
            age={partner.age}
            disableFormFeilds={disableFormFeilds}
            setDob={(value) => {
              dispatch(updateField({ dob: value }));
              clearFieldError("age", errors, setErrors);
            }}
            setAge={(value) => {
              dispatch(updateField({ age: value }));
              clearFieldError("age", errors, setErrors);
            }}
          />
          <AliveOrDeceasedInput
            nodeRelation={nodeRelation}
            isAlive={partner.isAlive}
            dob={partner.dob}
            age={partner.age}
            disableFormFeilds={disableFormFeilds}
            dateOfDeath={partner.dateOfDeath}
            ageOfDeath={partner.ageOfDeath}
            setIsAlive={(value) => dispatch(updateField({ isAlive: value }))}
            setDateOfDeath={(value) => dispatch(updateField({ dateOfDeath: value }))}
            setAgeOfDeath={(value) => dispatch(updateField({ ageOfDeath: value }))}
          />
          <AdoptionInput
            nodeRelation={nodeRelation}
            isAdopted={partner.isAdopted}
            disableFormFeilds={disableFormFeilds}
            setIsAdopted={(value) => dispatch(updateField({ isAdopted: value }))}
          />
          {partnerType !== "husband" && (
            <PregnancyLossInput
              nodeRelation={partnerType}
              entity={partner}
              setEntity={(data) => dispatch(updateField(data))}
              disableFormFeilds={disableFormFeilds}
              errors={errors}
              clearFieldError={clearFieldError}
              setErrors={setErrors}
            />
          )}
          <AdditionalInfoInput
            disableFormFeilds={disableFormFeilds}
            additionalInfo={partner.additionalInfo}
            setAdditionalInfo={(value) => dispatch(updateField({ additionalInfo: value }))}
          />
          <hr className="chat-form-separator" />
          <CustomAccordion
            title="Children (Optional)"
            isCollapsed={isChildrenCollapsed}
            toggleCollapse={() => {
              setIsChildrenCollapsed(!isChildrenCollapsed);
              setIsAdditionalInfoCollapsed(true);
            }}
            isSubmitted={isSubmitted}
            isSubSection={true}
            nodeRelation={nodeRelation}
            headingFlag="childForm"
          >
            <ChildForm nodeRelation={nodeRelation} errors={errors} setErrors={setErrors} hasErrors={hasErrors} />
          </CustomAccordion>
          <hr className="chat-form-separator" />
          <CustomAccordion
            title="Additional Information"
            isCollapsed={isAdditionalInfoCollapsed}
            toggleCollapse={() => {
              setIsAdditionalInfoCollapsed(!isAdditionalInfoCollapsed);
              setIsChildrenCollapsed(true);
            }}
            isSubmitted={isSubmitted}
            isSubSection={true}
          >
            <DivorcedAndRelatedByBloodInput
              nodeRelation={nodeRelation}
              isBloodRelated={partner.isBloodRelated}
              setIsBloodRelated={(value) => dispatch(updateField({ isBloodRelated: value }))}
              isDivorced={partner.isDivorced}
              setIsDivorced={(value) => dispatch(updateField({ isDivorced: value }))}
              disableFormFeilds={disableFormFeilds}
            />
          </CustomAccordion>
          <div className="update-cancel-buttons">
            {(!isSubmitted || isEditMode) && (
              <Button className="form-submit-btn" type="submit" disabled={(!isFormChanged || isSubmitting) && isEditMode}>
                {isSubmitting ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    {isEditMode ? " Updating..." : " Submitting..."}
                  </>
                ) : isEditMode ? (
                  "Update"
                ) : (
                  "Continue"
                )}
              </Button>
            )}
            {displaySkipButton && (
              <Button className="form-skip-btn" onClick={isResumed ? handleSkip : handleClose} disabled={isSubmitted}>
                {isResumed ? "Close" : "Skip"}
              </Button>
            )}
            {isEditMode &&                     
              <Button className="cancel-btn" onClick={() => {
                if (isFormChanged) {
                  setShowAlert(true);
                } else {
                  handleCancelEdit();
                }
              }}>
                Cancel
              </Button>}
          </div>
        </Form>
      </CustomAccordion>
      {showSaveChangesAlert && (
        <UpdateAlert
          title="Unsaved Changes"
          message="Please save your changes in the previous form."
          onConfirm={handleSaveChangesAlertClose}
          confirmButtonText="OK"
        />
      )}

      {showSuccessAlert && (
        <UpdateAlert
          title="Success!"
          message="The information has been successfully updated."
          onConfirm={() => setShowSuccessAlert(false)}
        />
      )}
      {showAlert && (
        <CustomAlert
          title={alertTitle}
          message={alertMessage}
          onConfirm={isEditMode ? handleCancelEdit : handleSkip}
          onCancel={() => setShowAlert(false)}
        />
      )}
    </div>
  );
};

export default ProbandPartnerForm;
