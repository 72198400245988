import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: "",
  password: "",
  userName: "",
  conversationTone: null,
  isNewChat: 0,
};

const storageSlice = createSlice({
  name: "storage",
  initialState,
  reducers: {
    updateState(state, action) {
      const { field, value } = action.payload;
      state[field] = value;
    },
    clearState(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  updateState,
  clearState,
} = storageSlice.actions;

export default storageSlice.reducer;
