import Table from "react-bootstrap/Table";
import "./DataTable.css";
import { userCreatedAt } from "../../pages/utility/utility";

const DataTable = ({ listData, handleApprove, viewDocument, loading, onApprove }) => {
    return (
        <div className="doctor-table-container">
            <Table responsive size="xl">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>DOB</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Document</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {listData.map((user, index) => (
                        <tr key={index}>
                            <td>{user.user_id}</td>
                            <td>{user.user_name}</td>
                            <td>{user.user_dob}</td>
                            <td>{user.user_phone}</td>
                            <td>{user.user_email}</td>
                            <td>
                                {user.user_status === "approved" ? (
                                    "Approved"
                                ) : (
                                    <button
                                        onClick={() => handleApprove(user.user_id, user.user_status)}
                                        className="btn btn-primary table-btn"
                                        disabled={loading}
                                    >
                                        {user.user_id === onApprove ? (
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            "Approve"
                                        )}
                                    </button>
                                )}
                            </td>
                            <td>
                                <button
                                    onClick={() => viewDocument(user.user_id)}
                                    className="btn btn-outline-secondary table-btn"
                                >
                                    View
                                </button>
                            </td>
                            <td>{userCreatedAt(user.created_at)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default DataTable;
