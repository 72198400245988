// pedigreeUtils.js
import $ from "jquery";
import { scrollIntoView } from "seamless-scroll-polyfill";

function clearSessionStorageWithPrefix(prefix) {
  if (typeof prefix !== "string" || prefix.trim() === "") {
    console.error("Prefix must be a non-empty string");
    return;
  }
  const keysToDelete = Object.keys(sessionStorage).filter((key) =>
    key.startsWith(prefix)
  );
  keysToDelete.forEach((key) => {
    sessionStorage.removeItem(key);
  });
}

const getOpts = () => {
  const diseases = [
    { type: "cancer", colour: "#F68F35" },
    { type: "hemophilia", colour: "#297878" },
    { type: "diabetes", colour: "#4E8A16" },
    { type: "stroke", colour: "#295B78" },
    { type: "asthma", colour: "#7F7F7F" },
  ];

  const opts = {
    targetDiv: "ped",
    btn_target: "pedigree_history",
    width: 800,
    height: 600,
    symbol_size: 35,
    zoomIn: 0,
    zoomOut: 0,
    edit: true,
    diseases: diseases,
    DEBUG: pedigreejs.pedigreejs_utils.urlParam("debug") !== null,
  };
  return opts;
};

const generatePedigreeTree = (formattedTreeData, opts) => {
  try {
    const prefixToDelete = "PEDIGREE_pedigree_history_";
    clearSessionStorageWithPrefix(prefixToDelete);
    const pedContainer = document.getElementById("ped");
    if (pedContainer) {
      pedContainer.innerHTML = "";
    }
    var parent_width = pedContainer.parentElement.offsetWidth;
    var margin = window.innerWidth - parent_width > 10 ? 100 : 30;
    var svg_width = parent_width - margin;
    var opts = {
        targetDiv: "ped",
        btn_target: "pedigree_history",
        width: svg_width,
        height: 700,
        symbol_size: 35,
        zoomIn: 0.5,
        zoomOut: 1.5,
        edit: true,
        diseases: [
          { type: "cancer", colour: "#81f635" },
          { type: "diabetes", colour: "#f71212" },
          { type: "asthma", colour: "#1760df" },
          { type: "ast", colour: "#e8f144" },
          { type: "at", colour: "#cd0a0a" },
        ],
        DEBUG:
          pedigreejs.pedigreejs_utils.urlParam("debug") === null ? false : true,
      };
      var dataset = formattedTreeData;
      const uniqueDiseaseTypes = new Set();
      formattedTreeData.forEach((entry) => {
        for (const key in entry) {
          if (key.endsWith("_diagnosis_age")) {
            const diseaseType = key.replace("_diagnosis_age", "");
            uniqueDiseaseTypes.add(diseaseType);
          }
        }
      });
      uniqueDiseaseTypes.forEach((type) => {
        const existingDiseaseIndex = opts.diseases.findIndex(
          (disease) => disease.type === type
        );
        if (existingDiseaseIndex !== -1) {
        } else {
          const randomColor =
            "#" + Math.floor(Math.random() * 16777215).toString(16);
          opts.diseases.push({ type, colour: randomColor });
        }
      });
      $("#opts").append(JSON.stringify(opts, null, 4));
      opts.dataset = dataset;
      opts = pedigreejs.pedigreejs.build(opts);
      const span1 = document.getElementById("pedigree_history").getElementsByTagName("span")[0];
      const span2 = document.getElementById("pedigree_history").getElementsByTagName("span")[1];
      const span3 = document.getElementById("pedigree_history").getElementsByTagName("span")[2];
      const span4 = document.getElementById("pedigree_history").getElementsByTagName("span")[3];
      const span5 = document.getElementById("pedigree_history").getElementsByTagName("span")[4];
      const span6 = document.getElementById("pedigree_history").getElementsByTagName("span")[5];
      const span7 = document.getElementById("pedigree_history").getElementsByTagName("span")[6];
      const span8 = document.getElementById("pedigree_history").getElementsByTagName("span")[7];
      span1.classList.add("icon-display-hidden");
      span2.classList.add("icon-display-hidden");
      span3.classList.add("icon-display-hidden");
      span4.classList.add("icon-display-hidden");
      span5.classList.add("icon-display");
      span6.classList.add("icon-display");
      span7.classList.add("icon-display");
      span8.classList.add("icon-display");
    } catch (error) {
      console.error("Error while genrating tree:",error);
      setTimeout(() => {
        window.history.back();
      }, 5000);
    }
  };

  const getAccessToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === "accessToken") {
        return cookie[1];
      }
    }
    return null;
  };

  const getUserType = (accessToken) => {
    if (accessToken) {
      const [, payloadBase64] = accessToken.split('.');
      const payloadString = atob(payloadBase64);
      const decodedToken = JSON.parse(payloadString);
      
      switch (decodedToken.user_id[0]) {
        case 's':
          return 'super_admin';
        case 'a':
          return 'admin';
        case 'd':
          return 'doctor';
        case 'p':
          return 'patient';
      }
    }
  };

  const userCreatedAt = (created_at) =>{
    const dateObject = new Date(created_at);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth();
    const date = dateObject.getDate();

    const months = [
      'January', 'February', 'March', 'April',
      'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'
    ];

    return `${date} ${months[month]} ${year}`;
  }

const validateForm = (node, nodeRelation) => {
  const errors = {};

  const validateRequiredField = (field, fieldName) => {
    if (field === null || field === undefined || (typeof field === "string" && field.trim() === "")) {
      return `${fieldName} is required.`;
    }
    if (typeof field === "number" && (field < 0 || (fieldName === "Number of losses" && field === 0))) {
      return `${fieldName} is required.`;
    }
  };

  const validateParent = (parent) => {
    if (parent.pregnancyLoss) {
      errors.numberOfLosses = validateRequiredField(parent.numberOfLosses, "Number of losses");
      parent.lossCauses.forEach((cause, index) => {
        errors[`lossCause${index}`] = validateRequiredField(cause, `Cause of pregnancy loss`);
      });
    }
  };

  if (["PS", "PHS", "PFS", "PFHS", "PMS", "PMHS"].includes(nodeRelation)) {
    errors.siblings = node.map((sibling) => {
      const siblingErrors = {};
      siblingErrors.relationship = validateRequiredField(sibling.relationship, "Relationship");

      if (["PHS", "PFHS", "PMHS"].includes(nodeRelation)) {
        siblingErrors.commonParent = validateRequiredField(sibling.commonParent, "Common Parent");
      }

      if (["PFS", "PFHS", "PMS", "PMHS"].includes(nodeRelation) && sibling.hasChild) {
        sibling.children.forEach((child, index) => {
          if (!child.gender) {
            siblingErrors[`childGender${index}`] = `Gender is required.`;
          }
        });
      }

      return siblingErrors;
    });
  }

  if (["PP", "PPG", "PMG"].includes(nodeRelation)) {
    const fatherType =
      nodeRelation === "PP" ? "father" : nodeRelation === "PPG" ? "paternalGrandFather" : "maternalGrandFather";
    const motherType =
      nodeRelation === "PP" ? "mother" : nodeRelation === "PPG" ? "paternalGrandMother" : "maternalGrandMother";

    validateParent(node.father, fatherType);
    validateParent(node.mother, motherType);
  }

  if (nodeRelation === "PPT") {
    if (node.partnerType !== "husband" && node.partner.pregnancyLoss) {
      errors.numberOfLosses = validateRequiredField(node.partner.numberOfLosses,"Number of losses");
      node.partner.lossCauses.forEach((cause, index) => {
        errors[`lossCause${index}`] = validateRequiredField(cause, `Cause for loss ${index + 1}`);
      });
    }

    if (node.partner.hasChild) {
      node.partner.children.forEach((child, index) => {
        if (!child.gender) {
          errors[`childGender${index}`] = `Gender is required.`;
        }
      });
    }
  }

  if (nodeRelation === "P") {
    errors.gender = validateRequiredField(node.gender, "Gender");
    errors.age = validateRequiredField(node.age, "Age");
  }

  return errors;
};

const clearFieldError = (field, errors, setErrors) => {
  setErrors({ ...errors, [field]: null });
};

const adjustLayout = () => {
  window.dispatchEvent(new Event("resize"));
};

const getMaxNodeCount = () => {
  return 15;
}

const getAgeDifferenceRequirement = (hasParentAge) => {
  return hasParentAge ? 15 : 30;
};

const getErrorMessageGrandfather = (ageDifferenceRequirement) => {
  return ageDifferenceRequirement === 15
    ? "The age difference between parents and their offspring must be more than 15 years."
    : "The age difference between grandparents and their grandchildren must be more than 30 years";
};

const getErrorMessageGrandmother = (ageDifferenceRequirement) => {
  return ageDifferenceRequirement === 15
    ? "The age difference between parents and their offspring must be more than 15 years"
    : "The age difference between grandparents and their grandchildren must be more than 30 years";
};
const getTypingSpeed = () => {
  return 40;
};

const simulateTyping = (message, setCurrentMessage, typingSpeed, callback) => {
  const letters = message.split('');
  let typedMessage = '';
  let letterIndex = 0;
  
  const typingInterval = setInterval(() => {
    typedMessage += letters[letterIndex];
    setCurrentMessage(typedMessage);
    letterIndex++;
    
    if (letterIndex >= letters.length) {
      clearInterval(typingInterval);
      callback();
    }
  }, typingSpeed);
};
export {
  generatePedigreeTree,
  getOpts,
  getAccessToken,
  getUserType,
  userCreatedAt,
  validateForm,
  clearFieldError,
  adjustLayout,
  getMaxNodeCount,
  getAgeDifferenceRequirement,
  getErrorMessageGrandfather,
  getErrorMessageGrandmother,
  getTypingSpeed,
  simulateTyping,
};

export const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    setTimeout(() => {
      scrollIntoView(element, {
        behavior: "smooth",
        block: "start",
        top: 400,
      });
    }, 300);
  }
};


