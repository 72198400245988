import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AdditionalInfoInput,
  AdoptionInput,
  DobOrAgeInput,
  NameInput,
  AliveOrDeceasedInput,
  DivorcedAndRelatedByBloodInput,
  PregnancyLossInput,
} from "../commonFormFields/CommonFormFields";
import { clearFieldError, adjustLayout } from "../../../pages/utility/utility";
import CustomAccordion from "../../customAccordion/CustomAccordion";
import { updateMaternalGrandFather, updateMaternalGrandMother } from "../../../store/maternalGrandParentFormSlice";
import { updatePaternalGrandFather, updatePaternalGrandMother } from "../../../store/paternalGrandParentFormSlice";

const GrandParent = ({ nodeRelation, errors, setErrors }) => {
  const dispatch = useDispatch();
  const maternalGrandFather = useSelector((state) => state.maternalGrandParentsForm.grandFather);
  const maternalGrandMother = useSelector((state) => state.maternalGrandParentsForm.grandMother);
  const paternalGrandFather = useSelector((state) => state.paternalGrandParentsForm.grandFather);
  const paternalGrandMother = useSelector((state) => state.paternalGrandParentsForm.grandMother);
  const { 
    isSubmitted: isMaternalGrandParentSubmitted, 
    isEditMode: isEditModeInMaternalGrandParent 
  } = useSelector((state) => state.maternalGrandParentsForm);
  const { 
    isSubmitted: isPaternalGrandParentSubmitted, 
    isEditMode: isEditModeInPaternalGrandParent 
  } = useSelector((state) => state.paternalGrandParentsForm);

  const [isMaternalGrandFatherCollapsed, setIsMaternalGrandFatherCollapsed] = useState(false);
  const [isMaternalGrandMotherCollapsed, setIsMaternalGrandMotherCollapsed] = useState(true);
  const [isMaternalAdditionalInfoCollapsed, setIsMaternalAdditionalInfoCollapsed] = useState(true);
  const [isPaternalGrandFatherCollapsed, setIsPaternalGrandFatherCollapsed] = useState(false);
  const [isPaternalGrandMotherCollapsed, setIsPaternalGrandMotherCollapsed] = useState(true);
  const [isPaternalAdditionalInfoCollapsed, setIsPaternalAdditionalInfoCollapsed] = useState(true);

  const grandFather = nodeRelation === "PPG" ? paternalGrandFather : maternalGrandFather;
  const grandFatherUpdateAction = nodeRelation === "PPG" ? updatePaternalGrandFather : updateMaternalGrandFather;
  const grandFatherRelation = nodeRelation === "PPG" ? "paternalGrandFather" : "maternalGrandFather";
  const grandMother = nodeRelation === "PPG" ? paternalGrandMother : maternalGrandMother;
  const grandMotherUpdateAction = nodeRelation === "PPG" ? updatePaternalGrandMother : updateMaternalGrandMother;
  const grandMotherRelation = nodeRelation === "PPG" ? "paternalGrandMother" : "maternalGrandMother";

  const isPaternalGrandParent = nodeRelation === "PPG";
  const disableFormFeilds =
    isPaternalGrandParent
      ? isPaternalGrandParentSubmitted && !isEditModeInPaternalGrandParent
      : isMaternalGrandParentSubmitted && !isEditModeInMaternalGrandParent;

  const createGrandParentFields = (grandParent, updateAction, role) => (
    <>
      <NameInput
        disableFormFeilds={disableFormFeilds}
        firstName={grandParent.firstName}
        lastName={grandParent.lastName}
        setFirstName={(value) => {
          dispatch(updateAction({ firstName: value }));
          clearFieldError(`${role}FirstName`, errors, setErrors);
        }}
        setLastName={(value) => dispatch(updateAction({ lastName: value }))}
      />
      <DobOrAgeInput
        nodeRelation={nodeRelation}
        dob={grandParent.dob}
        age={grandParent.age}
        disableFormFeilds={disableFormFeilds}
        setDob={(value) => {
          dispatch(updateAction({ dob: value }));
          clearFieldError(`${role}Age`, errors, setErrors);
        }}
        setAge={(value) => {
          dispatch(updateAction({ age: value }));
          clearFieldError(`${role}Age`, errors, setErrors);
        }}
      />
      <AliveOrDeceasedInput
        nodeRelation={role}
        dob={grandParent.dob}
        age={grandParent.age}
        isAlive={grandParent.isAlive}
        disableFormFeilds={disableFormFeilds}
        dateOfDeath={grandParent.dateOfDeath}
        ageOfDeath={grandParent.ageOfDeath}
        setIsAlive={(value) => dispatch(updateAction({ isAlive: value }))}
        setDateOfDeath={(value) => dispatch(updateAction({ dateOfDeath: value }))}
        setAgeOfDeath={(value) => dispatch(updateAction({ ageOfDeath: value }))}
      />
      <AdoptionInput
        nodeRelation={role}
        isAdopted={grandParent.isAdopted}
        disableFormFeilds={disableFormFeilds}
        setIsAdopted={(value) => dispatch(updateAction({ isAdopted: value }))}
      />
      {role.includes("GrandMother") && (
        <PregnancyLossInput
          nodeRelation={role}
          entity={grandParent}
          setEntity={(value) => dispatch(updateAction(value))}
          disableFormFeilds={disableFormFeilds}
          errors={errors}
          clearFieldError={clearFieldError}
          setErrors={setErrors}
        />
      )}
      <AdditionalInfoInput
        disableFormFeilds={disableFormFeilds}
        additionalInfo={grandParent.additionalInfo}
        setAdditionalInfo={(value) => dispatch(updateAction({ additionalInfo: value }))}
      />
    </>
  );

  const getGrandFatherFormHeading = () => {
    if (nodeRelation === "PPG") {
      return `Paternal Grandfather : ${paternalGrandFather.firstName} ${paternalGrandFather.lastName}`;
    }
    if (nodeRelation === "PMG") {
      return `Maternal Grandfather : ${maternalGrandFather.firstName} ${maternalGrandFather.lastName}`;
    }
  };

  const getGrandMotherFormHeading = () => {
    if (nodeRelation === "PPG") {
      return `Paternal Grandmother : ${paternalGrandMother.firstName} ${paternalGrandMother.lastName}`;
    }
    if (nodeRelation === "PMG") {
      return `Maternal Grandmother : ${maternalGrandMother.firstName} ${maternalGrandMother.lastName}`;
    }
  };

  const isEditMode = isEditModeInMaternalGrandParent || isEditModeInPaternalGrandParent;

  useEffect(() => {
    if (isEditMode) {
      setIsPaternalGrandFatherCollapsed(true);
      setIsPaternalGrandMotherCollapsed(true);
      setIsPaternalAdditionalInfoCollapsed(true);
      setIsMaternalGrandFatherCollapsed(true);
      setIsMaternalGrandMotherCollapsed(true);
      setIsMaternalAdditionalInfoCollapsed(true);
    }
  }, [isEditMode]);

  useEffect(() => {
    adjustLayout();
  }, [
    isPaternalGrandFatherCollapsed,
    isPaternalGrandMotherCollapsed,
    isPaternalAdditionalInfoCollapsed,
    isMaternalGrandFatherCollapsed,
    isMaternalGrandMotherCollapsed,
    isMaternalAdditionalInfoCollapsed,
  ]);

  return (
    <>
      <CustomAccordion
        title={getGrandFatherFormHeading()}
        isCollapsed={nodeRelation === "PPG" ? isPaternalGrandFatherCollapsed : isMaternalGrandFatherCollapsed}
        toggleCollapse={() => {
          if (nodeRelation === "PPG") {
            setIsPaternalGrandFatherCollapsed(!isPaternalGrandFatherCollapsed);
            setIsPaternalAdditionalInfoCollapsed(true);
            setIsPaternalGrandMotherCollapsed(true);
          }
          if (nodeRelation === "PMG") {
            setIsMaternalGrandFatherCollapsed(!isMaternalGrandFatherCollapsed);
            setIsMaternalAdditionalInfoCollapsed(true);
            setIsMaternalGrandMotherCollapsed(true);
          }
        }}
        isSubSection={true}
      >
        <div>{createGrandParentFields(grandFather, grandFatherUpdateAction, grandFatherRelation)}</div>
      </CustomAccordion>
      <hr className="chat-form-separator" />
      <CustomAccordion
        title={getGrandMotherFormHeading()}
        isCollapsed={nodeRelation === "PPG" ? isPaternalGrandMotherCollapsed : isMaternalGrandMotherCollapsed}
        toggleCollapse={() => {
          if (nodeRelation === "PPG") {
            setIsPaternalGrandMotherCollapsed(!isPaternalGrandMotherCollapsed);
            setIsPaternalAdditionalInfoCollapsed(true);
            setIsPaternalGrandFatherCollapsed(true);
          }
          if (nodeRelation === "PMG") {
            setIsMaternalGrandMotherCollapsed(!isMaternalGrandMotherCollapsed);
            setIsMaternalAdditionalInfoCollapsed(true);
            setIsMaternalGrandFatherCollapsed(true);
          }
        }}
        isSubSection={true}
      >
        <div>{createGrandParentFields(grandMother, grandMotherUpdateAction, grandMotherRelation)}</div>
      </CustomAccordion>
      <hr className="chat-form-separator" />
      <CustomAccordion
        title={`Additional Information`}
        isCollapsed={nodeRelation === "PPG" ? isPaternalAdditionalInfoCollapsed : isMaternalAdditionalInfoCollapsed}
        toggleCollapse={() => {
          if (nodeRelation === "PPG") {
            setIsPaternalAdditionalInfoCollapsed(!isPaternalAdditionalInfoCollapsed);
            setIsPaternalGrandFatherCollapsed(true);
            setIsPaternalGrandMotherCollapsed(true);
          }
          if (nodeRelation === "PMG") {
            setIsMaternalAdditionalInfoCollapsed(!isMaternalAdditionalInfoCollapsed);
            setIsMaternalGrandFatherCollapsed(true);
            setIsMaternalGrandMotherCollapsed(true);
          }
        }}
        isSubSection={true}
      >
        <DivorcedAndRelatedByBloodInput
          nodeRelation={nodeRelation}
          isBloodRelated={
            nodeRelation === "PPG" ? paternalGrandFather.isBloodRelated : maternalGrandFather.isBloodRelated
          }
          setIsBloodRelated={(value) => {
            if (nodeRelation === "PPG") {
              dispatch(updatePaternalGrandFather({ isBloodRelated: value }));
              dispatch(updatePaternalGrandMother({ isBloodRelated: value }));
            }
            if (nodeRelation === "PMG") {
              dispatch(updateMaternalGrandFather({ isBloodRelated: value }));
              dispatch(updateMaternalGrandMother({ isBloodRelated: value }));
            }
          }}
          isDivorced={nodeRelation === "PPG" ? paternalGrandFather.isDivorced : maternalGrandFather.isDivorced}
          setIsDivorced={(value) => {
            if (nodeRelation === "PPG") {
              dispatch(updatePaternalGrandFather({ isDivorced: value }));
              dispatch(updatePaternalGrandMother({ isDivorced: value }));
            }
            if (nodeRelation === "PMG") {
              dispatch(updateMaternalGrandFather({ isDivorced: value }));
              dispatch(updateMaternalGrandMother({ isDivorced: value }));
            }
          }}
          disableFormFeilds={disableFormFeilds}
        />
      </CustomAccordion>
    </>
  );
};

export default GrandParent;
