import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import NotifyModal from "../modals/notifyModal/NotifyModal";
import { SERVER_HOST } from "../../env";
import "./Login.css";
import {getUserType,getAccessToken} from "../../pages/utility/utility";
import { clearState, updateState} from "../../store/storageSlice";
const host = SERVER_HOST;

const Login = () => {
  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [notify, setNotify] = useState(false);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [isNotifyError, setIsNotifyError] = useState(false);
  const [email,  setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const [showSubmitOTP, setShowSubmitOTP] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleApiNotify = (notifyMessage) => {
    setNotify(notifyMessage);
    setShowNotifyModal(true);
  };

  const handleCloseNotify = () => {
    setNotify(null);
    setShowNotifyModal(false);
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("user_id", userID);
      formData.append("password", password);
  
      const response = await axios.post(`${host}/login`, formData);
  
      const responseData = response.data;
  
      if (responseData.success) {
        dispatch(updateState({field : "userId", value : userID}));
        setIsNotifyError(false);
        handleApiNotify("OTP sent successfully");
        setEmail(responseData.user_email);
        document.cookie = `accessToken=${responseData.access_token}; path=/;`;
        setShowLoginForm(false);
        setShowSubmitOTP(true);
        startOtpTimer();
      } else {
        setIsNotifyError(true);
        handleApiNotify("Login failed");
        console.error("Login failed");
      }
    } catch (error) {
      setIsNotifyError(true);
      handleApiNotify("Login failed");
      console.error("Error:", error);
    }
    finally{
      setLoading(false); 
    }
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.get(`${host}/verify_otp`, {
        params: {
          otp: otp.trim(),
          email_id: email,
        },
      });

      if (response.data.success) {
        handleApiNotify("Login successful");
        setIsNotifyError(false);

        const token=getAccessToken();
        const userType = getUserType(token);
        if (userType==="super_admin") {
          navigate("/doctor");
        }
        else {
          console.error("Invalid user type");
        }
      } else {
        setIsNotifyError(true);
        handleApiNotify("OTP verification failed");
        console.error("OTP verification failed:", response.data.message);
      }
    } catch (error) {
      setIsNotifyError(true);
      handleApiNotify("OTP verification failed");
      console.error("Error:", error);
    }
  };


  const startOtpTimer = () => {
    let intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(intervalId);
          setShowSubmitOTP(false);
          return 60; // reset timer
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    localStorage.clear();
    dispatch(clearState())
    document.cookie = "accessToken=;";
  }, []);

  return (
    <div className="login-page">
      <div className={showLoginForm ? "login-form-show" : "login-form-hide"}>
        <header className="login-page-heading">
          <h1>Login</h1>
        </header>
        <section>
          <div className="container-fluid h-custom">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-0.5">
                <div className="text-center">
                  <h4 className="mt-1 mb-5 pb-1"></h4>
                </div>
                <Form onSubmit={handleLoginSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Label className="login-input-label">
                        Username
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setUserID(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                      <Form.Label className="login-input-label">
                        Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-2">
                    <Col className="d-flex justify-content-center">
                      <Button
                        type="submit"
                        id="submit-button"
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={!showLoginForm ? "login-form-show" : "login-form-hide"}>
        <h2 className="login-otp-heading">Enter OTP</h2>
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-0.5">
              <Form onSubmit={handleOtpSubmit}>
                <Row className="login-otp-form-div mb-3">
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Control
                      name="otp"
                      required
                      type="text"
                      placeholder="OTP"
                      onChange={(e) => setOtp(e.target.value)}
                      title="Please enter your OTP"
                      defaultValue=""
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    {showSubmitOTP && (
                      <small className="login-otp-timer">
                        {Math.floor(timer / 60)}:{timer % 60}s remaining
                      </small>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Col className="d-flex justify-content-center">
                    {showSubmitOTP && (
                      <Button
                        type="submit"
                        className="login-otp-submit-btn"
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit OTP"
                        )}
                      </Button>
                    )}
                  </Col>
                </Row>
                {/* Resend OTP Button */}
                <Row>
                  <Col className="d-flex justify-content-center">
                    {!showSubmitOTP && (
                      <Button
                        className="login-otp-submit-btn"
                        onClick={handleLoginSubmit}
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Resend OTP"
                        )}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <NotifyModal
        notifyMessage={notify}
        show={showNotifyModal}
        onClose={handleCloseNotify}
        isLoading={false}
        notifyError={isNotifyError}
      />
    </div>
  );
};

export default Login;
