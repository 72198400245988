import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsSubmitted as setPaternalIsSubmitted,
  setIsCollapsed as setIsPaternalGrandParentCollapsed,
  setIsEditMode as setEditModeInPaternalGrandParent,
  updatePaternalGrandFatherAge,
  updatePaternalGrandMotherAge
} from "../../../store/paternalGrandParentFormSlice";
import {
  setIsSubmitted as setMaternalIsSubmitted,
  setIsCollapsed as setIsMaternalGrandParentCollapsed,
  setIsEditMode as setEditModeInMaternalGrandParent,
  updateMaternalGrandFatherAge,
  updateMaternalGrandMotherAge
} from "../../../store/maternalGrandParentFormSlice";
import { setIsCollapsed as setIsFatherFullSiblingCollapsed } from "../../../store/fatherFullSiblingFormSlice";
import { validateForm, adjustLayout, getAgeDifferenceRequirement, getErrorMessageGrandfather, getErrorMessageGrandmother, scrollToElement } from "../../../pages/utility/utility";
import { Form, Button, Spinner } from "react-bootstrap";
import "../../../style.css";
import CustomAccordion from "../../customAccordion/CustomAccordion";
import GrandParent from "./GrandParent";
import {CustomAlert, UpdateAlert} from "../../customAlert/CustomAlert";
import useEditModeFormNode from "../../../hooks/useEditModeFormNode";

const GrandParentsForm = ({ sendFormData, handleCancel, autoSaveFormData, nodeRelation, collapseAllExcept, savedChoice, currentChoice, formEditStatus }) => {
  const dispatch = useDispatch();
  const currentlyEditingFormId = useEditModeFormNode();
  const {
    grandFather: paternalGrandFather,
    grandMother: paternalGrandMother,
    isSubmitted: isPaternalGrandParentSubmitted,
    isCollapsed: isPaternalGrandParentCollapsed,
    isEditMode: isEditModeInPaternalGrandParent,
  } = useSelector((state) => state.paternalGrandParentsForm);
  const {
    grandFather: maternalGrandFather,
    grandMother: maternalGrandMother,
    isSubmitted: isMaternalGrandParentSubmitted,
    isCollapsed: isMaternalGrandParentCollapsed,
    isEditMode: isEditModeInMaternalGrandParent,
  } = useSelector((state) => state.maternalGrandParentsForm);
  const { conversationTone } = useSelector((state) => state.storage);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const isPaternalGrandparent = nodeRelation === "PPG";
  const isCollapsed = isPaternalGrandparent ? isPaternalGrandParentCollapsed : isMaternalGrandParentCollapsed;
  const isSubmitted = isPaternalGrandparent ? isPaternalGrandParentSubmitted : isMaternalGrandParentSubmitted;
  const isEditMode = isPaternalGrandparent ? isEditModeInPaternalGrandParent : isEditModeInMaternalGrandParent;
  const containerClass = isCollapsed ? "collapsed-chat-form-container" : "chat-form-container";
  const buttonText = isEditMode ? "Update" : "Continue";
  const submittingText = isEditMode ? "Updating..." : "Submitting...";
  const formState = useSelector((state) => state.parentsForm);
  const fatherAge = formState.father.age; 
  const motherAge = formState.mother.age; 
  const probandformState = useSelector((state) => state.probandForm);
  const probandAge= probandformState.age; 
  const [showSuccessAlert, setShowSuccessAlert] = useState(false); 
  const [showGrandParentDetailsAlert, setShowGrandParentDetailsAlert] = useState({ show: false, message: "" });
  const [showSaveChangesAlert, setShowSaveChangesAlert] = useState(false);

  const toggleCollapse = (formAction) => {
    if( formEditStatus && formAction !== 'update' || formAction === 'continue'){
      setShowSaveChangesAlert(true);
      scrollToElement(currentlyEditingFormId);
      return;
    }
    if (nodeRelation === "PPG") {
      dispatch(setEditModeInPaternalGrandParent(false));
      dispatch(setEditModeInMaternalGrandParent(false));
      collapseAllExcept(nodeRelation, !isPaternalGrandParentCollapsed);
    }
    if (nodeRelation === "PMG") {
      dispatch(setEditModeInMaternalGrandParent(false));
      dispatch(setEditModeInPaternalGrandParent(false));
      collapseAllExcept(nodeRelation, !isMaternalGrandParentCollapsed);
    }
  };

  const [initialPaternalGrandFather, setInitialPaternalGrandFather] = useState(paternalGrandFather);
  const [initialPaternalGrandMother, setInitialPaternalGrandMother] = useState(paternalGrandMother);
  const [initialMaternalGrandFather, setInitialMaternalGrandFather] = useState(maternalGrandFather);
  const [initialMaternalGrandMother, setInitialMaternalGrandMother] = useState(maternalGrandMother);
  const [isFormChanged, setIsFormChanged] = useState(false);
  useEffect(() => {
    if (isEditMode) {
      setInitialPaternalGrandFather(paternalGrandFather);
      setInitialPaternalGrandMother(paternalGrandMother);
      setInitialMaternalGrandFather(maternalGrandFather);
      setInitialMaternalGrandMother(maternalGrandMother);
      setIsFormChanged(false);
    }
  }, [isEditMode]);

  useEffect(() => {
    const hasPaternalGrandFatherChanged = JSON.stringify(paternalGrandFather) !== JSON.stringify(initialPaternalGrandFather);
    const hasPaternalGrandMotherChanged = JSON.stringify(paternalGrandMother) !== JSON.stringify(initialPaternalGrandMother);
    const hasMaternalGrandFatherChanged = JSON.stringify(maternalGrandFather) !== JSON.stringify(initialMaternalGrandFather);
    const hasMaternalGrandMotherChanged = JSON.stringify(maternalGrandMother) !== JSON.stringify(initialMaternalGrandMother);
    setIsFormChanged(hasPaternalGrandFatherChanged || hasPaternalGrandMotherChanged || hasMaternalGrandFatherChanged || hasMaternalGrandMotherChanged);
  }, [paternalGrandFather, paternalGrandMother, maternalGrandFather, maternalGrandMother]);


  const getFormData = (isGrandParentFormSubmitted) => {
    let formData = null;
    if (nodeRelation === "PPG") {
      formData = {
        grandfather: { ...paternalGrandFather },
        grandmother: { ...paternalGrandMother },
        isFormSubmitted: isGrandParentFormSubmitted,
        isEditMode: isEditModeInPaternalGrandParent
      };
    }
    if (nodeRelation === "PMG") {
      formData = {
        grandfather: { ...maternalGrandFather },
        grandmother: { ...maternalGrandMother },
        isFormSubmitted: isGrandParentFormSubmitted,
        isEditMode: isEditModeInMaternalGrandParent
      };
    }
    return formData;
  };

  useEffect(() => {
    const noderelationSubmissionAndEditStatus = {
      PMG: { isSubmitted: isMaternalGrandParentSubmitted, isEditMode: isEditModeInMaternalGrandParent },
      PPG: { isSubmitted: isPaternalGrandParentSubmitted, isEditMode: isEditModeInPaternalGrandParent }
    };
  
    const intervalId = setInterval(() => {
      const nodeStatus = noderelationSubmissionAndEditStatus[nodeRelation];
      if (nodeStatus && (!nodeStatus.isSubmitted || nodeStatus.isEditMode)) {
        handleAutoSave();
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [
    paternalGrandFather,
    paternalGrandMother,
    maternalGrandFather,
    maternalGrandMother,
    isPaternalGrandParentSubmitted,
    isMaternalGrandParentSubmitted,
    isEditModeInMaternalGrandParent,
    isEditModeInPaternalGrandParent
  ]);

  const handleAutoSave = () => {
    let isFormSubmitted = false;
    if(nodeRelation === "PPG"){
      isFormSubmitted = isEditModeInPaternalGrandParent ? true : false;
    }
    if(nodeRelation === "PMG"){
      isFormSubmitted = isEditModeInMaternalGrandParent ? true : false
    }
    const formData = getFormData(isFormSubmitted);
    const jsonFormData = JSON.stringify(formData);
    autoSaveFormData(jsonFormData, nodeRelation);
  };

  const validateAgeDifference = (grandfatherAge, grandmotherAge, parentAge, hasParentAge) => {
    grandfatherAge = grandfatherAge ? Number(grandfatherAge) : null; 
    grandmotherAge = grandmotherAge ? Number(grandmotherAge) : null; 
    parentAge = Number(parentAge);
    const ageDifferenceRequirement = getAgeDifferenceRequirement(hasParentAge);
    const isGrandfatherAgeValid = grandfatherAge !== null ? grandfatherAge >= parentAge + ageDifferenceRequirement : true;
    const isGrandmotherAgeValid = grandmotherAge !== null ? grandmotherAge >= parentAge + ageDifferenceRequirement : true;
    const errorMessageGrandfather = getErrorMessageGrandfather(ageDifferenceRequirement);
    const errorMessageGrandmother = getErrorMessageGrandmother(ageDifferenceRequirement);
    
    return {
      isGrandfatherAgeValid,
      isGrandmotherAgeValid,
      errors: {
        grandfather: isGrandfatherAgeValid ? null : errorMessageGrandfather,
        grandmother: isGrandmotherAgeValid ? null : errorMessageGrandmother
      }
    };
  };

  const submitForm = (e) => {
    e.preventDefault();
    const isGrandparentIncomplete = (grandparent) => 
      !grandparent.firstName && !grandparent.lastName && !grandparent.age && !grandparent.dob;
  
    const paternalGrandparentsIncomplete = {
      grandfather: isGrandparentIncomplete(paternalGrandFather),
      grandmother: isGrandparentIncomplete(paternalGrandMother)
    };
  
    const maternalGrandparentsIncomplete = {
      grandfather: isGrandparentIncomplete(maternalGrandFather),
      grandmother: isGrandparentIncomplete(maternalGrandMother)
    };
  
    const grandparentStatus = nodeRelation === "PPG" ? paternalGrandparentsIncomplete : maternalGrandparentsIncomplete;
  
    if (grandparentStatus.grandfather && grandparentStatus.grandmother) {
      setShowGrandParentDetailsAlert({
        show: true,
        message: "We have found that grandparents' details are not filled. Do you want to continue without entering them?"
      });
      return;
    } else if (grandparentStatus.grandfather) {
      setShowGrandParentDetailsAlert({
        show: true,
        message: "We have found that grandfather's details are not filled. Do you want to continue without entering them?"
      });
      return;
    } else if (grandparentStatus.grandmother) {
      setShowGrandParentDetailsAlert({
        show: true,
        message: "We have found that grandmother's details are not filled. Do you want to continue without entering them?"
      });
      return;
    }
    if (!isEditMode || isFormChanged) {
      handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    let formErrors = null;
    let grandParents = null;
    if (nodeRelation === "PPG") {
      grandParents = {
        father: { ...paternalGrandFather },
        mother: { ...paternalGrandMother },
      };
      formErrors = validateForm(grandParents, nodeRelation);
    }
    if (nodeRelation === "PMG") {
      grandParents = {
        father: { ...maternalGrandFather },
        mother: { ...maternalGrandMother },
      };
      formErrors = validateForm(grandParents, nodeRelation);
    }
    const hasErrors = Object.values(formErrors).some((value) => value !== undefined);
    let formAction = "";
    if (!hasErrors) {
      setIsSubmitting(true);
      const formData = getFormData(true);
      if (nodeRelation === "PPG") {
        formAction = isEditModeInPaternalGrandParent ? "update" : "continue";
        dispatch(setEditModeInPaternalGrandParent(false));
        dispatch(setPaternalIsSubmitted(true));
        dispatch(setIsPaternalGrandParentCollapsed(true));
        if (formAction === "update"){
          dispatch(setIsMaternalGrandParentCollapsed(true));
        }
        else{
          dispatch(setIsMaternalGrandParentCollapsed(false));
        }
      }
      if (nodeRelation === "PMG") {
        formAction = isEditModeInMaternalGrandParent ? "update" : "continue";
        dispatch(setEditModeInMaternalGrandParent(false));
        dispatch(setMaternalIsSubmitted(true));
        dispatch(setIsMaternalGrandParentCollapsed(true));
        dispatch(setIsPaternalGrandParentCollapsed(true));
        if (formAction === "update"){
          dispatch(setIsFatherFullSiblingCollapsed(true));
        }
        else{
          dispatch(setIsFatherFullSiblingCollapsed(false));  
        }
      }
      const jsonFormData = JSON.stringify(formData);
      sendFormData(jsonFormData, conversationTone, nodeRelation, "NA", formAction)
        .then(() => {
          if (isEditModeInMaternalGrandParent|| isEditModeInPaternalGrandParent ) {
            setShowSuccessAlert(true);
            toggleCollapse(formAction);
          }
        })
        .catch(() => {
          if (isEditModeInMaternalGrandParent|| isEditModeInPaternalGrandParent) {
            alert("Error updating data. Please try again.");
          }
        });
      setIsSubmitting(false);
    } else {
      setErrors(formErrors);
    }
  };
  const handleAlertContinue = () => {
    setShowGrandParentDetailsAlert({ show: false, message: "" });
    handleSubmit(); 
  };

  const handleAlertNo = () => {
    setShowGrandParentDetailsAlert({ show: false, message: "" });
  };

  const handleEditClick = () => {
    if(formEditStatus){
      setShowSaveChangesAlert(true);
      scrollToElement(currentlyEditingFormId);
      return;
    }
    if(nodeRelation === "PPG"){
      dispatch(setEditModeInPaternalGrandParent(true));
    }
    if(nodeRelation === "PMG"){
      dispatch(setEditModeInMaternalGrandParent(true))
    }
    collapseAllExcept(nodeRelation, false); 
  };

  const handleCancelEdit = () => {
    setShowAlert(false);
    handleCancel(nodeRelation, "cancel");
  };

  useEffect(() => {
    adjustLayout();
  }, [isMaternalGrandParentCollapsed, isPaternalGrandParentCollapsed]);

  const handleSaveChangesAlertClose = () => {
    setShowSaveChangesAlert(false);
  };
  return (
    <div className={containerClass}>
      <CustomAccordion
        title={`Biological ${isPaternalGrandparent ? "Paternal" : "Maternal"} Grandparents`}
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
        isSubmitted={isSubmitted}
        isHeading={true}
        handleEditClick={handleEditClick}
        savedChoice={savedChoice}
        currentChoice = {currentChoice}
        formEditStatus={isEditMode}
      >
        <Form className={`chat-form ${isPaternalGrandParentCollapsed ? "" : "show"}`} onSubmit={submitForm}>
          {nodeRelation === "PPG" ? (
            <GrandParent nodeRelation={nodeRelation} errors={errors} setErrors={setErrors} />
          ) : (
            <GrandParent nodeRelation={nodeRelation} errors={errors} setErrors={setErrors} />
          )}
          <div className="update-cancel-buttons">
            {(!isSubmitted || isEditMode) && (
              <Button className="form-submit-btn" onClick={submitForm} type="submit" variant="primary" disabled={(!isFormChanged || isSubmitting) && isEditMode}>
                {isSubmitting ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    {submittingText}
                  </>
                ) : (
                  buttonText
                )}
              </Button>
            )}
            {isEditMode &&(
                  <div>
                  <Button className="cancel-btn" onClick={() => {
                    if (isFormChanged) {
                      setShowAlert(true);
                    } else {
                      handleCancelEdit();
                    }
                  }}>
                    Cancel
                  </Button>
                </div>
            )
          }
          </div>
        </Form>
      </CustomAccordion>

      {showSaveChangesAlert && (
        <UpdateAlert
          title="Unsaved Changes"
          message="Please save your changes in the previous form."
          onConfirm={handleSaveChangesAlertClose}
          confirmButtonText="OK"
        />
      )}
  
      {showGrandParentDetailsAlert.show && (
        <CustomAlert
          title="Incomplete Grandparent's Details"
          message={showGrandParentDetailsAlert.message}
          onConfirm={handleAlertContinue} 
          onCancel={handleAlertNo}
          confirmButtonText="Continue"
          cancelButtonText="No"
        />
      )}
      {showSuccessAlert && (
        <UpdateAlert
          title="Success!"
          message="The information has been successfully updated."
          onConfirm={() => setShowSuccessAlert(false)}
        />
      )}
      {showAlert && (
        <CustomAlert
          title="Are you sure you want to cancel?"
          message="Unsaved data may be lost."
          onConfirm={handleCancelEdit}
          onCancel={() => setShowAlert(false)}
        />
      )}
    </div>
  );
};

export default GrandParentsForm;
