import Modal from "react-bootstrap/Modal";

function SaveChatModal(props) {
  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header style={{ textAlign: "justify" }}>
        <p>Chats saved successfully</p>
      </Modal.Header>
    </Modal>
  );
}

export default SaveChatModal;
