import React, { useEffect, useState } from "react";
import { SERVER_HOST } from "../../env";
import "./PatientListPage.css";
import { useNavigate, Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import axios from "axios";
import Button from "react-bootstrap/Button";
import LogoutButton from "../../components/logoutButton/LogoutButton";
import {getUserType,getAccessToken, userCreatedAt} from "../utility/utility";

const host = SERVER_HOST;

function PatientListPage() {
  const navigate = useNavigate();
  const [patients, setPatients] = useState([]);

  function getSourceFromHashId(hashId) {
    const lastLetter = hashId.slice(-1).toLowerCase();
    switch (lastLetter) {
      case "m":
        return "Media";
      case "c":
        return "Chat";
      case "t":
        return "Transcript";
      default:
        return "";
    }
  }

  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${host}/get_patients`, {
        params: {
          patient_type: "patients"
        },
        headers: {
          Authorization: `${getAccessToken()}`
        }        
      });
        
      setPatients(response.data.patient_data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    const token=getAccessToken();
    var userType=""
    if(token!==null){
       userType = getUserType(token);
    }
    if (userType==="doctor") {
      fetchPatients();
    } else {
      // Redirect to a fallback page if not a doctor
      navigate("/fallback");
    }
  }, [navigate]);


  return (
    <div className="patient-list-page">
      <LogoutButton />
      <div className="patient-table-container">
        <Table responsive size="xl">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>DOB</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Source</th>
              <th>Tree</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {patients.map((user, index) => (
              <tr key={index}>
                <td>{user.user_id}</td>
                <td>{user.user_name}</td>
                <td>{user.user_dob}</td>
                <td>{user.user_phone}</td>
                <td>{user.user_email}</td>
                <td>{getSourceFromHashId(user.hash_id)}</td>
                <td>
                  {user.user_status === "signedUp" ?
                    <p>Not generated</p>
                    :
                    <Link
                      to={`/${user.hash_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="btn btn-primary table-btn">
                        View Tree
                      </Button>
                    </Link>
                  }
                </td>
                <td>{user.created_at === "" ? "None" : userCreatedAt(user.created_at)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default PatientListPage;
