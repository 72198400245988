import React, { useState, useEffect, useContext } from "react";
import "./TreePage.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import Loader from "../../components/loader/Loader";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { SERVER_HOST } from "../../env";
import { LoginContext } from "../../contexts/LoginContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import NotifyModal from "../../components/modals/notifyModal/NotifyModal";
import LogoutButton from "../../components/logoutButton/LogoutButton";
import { Spinner } from "react-bootstrap";
import { generatePedigreeTree, getUserType, getAccessToken, getOpts } from "../utility/utility";
import FeedbackForm from "../../components/forms/feedbackForm/FeedbackForm";
import { useDispatch, useSelector } from "react-redux";
import { updateState } from "../../store/storageSlice";
import BackButton from "../../components/backbutton/backButton";

const host = SERVER_HOST;

function TreePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isTreePrepared, setTreePrepared] = useState(false);
  const {
    userName,
    userId,
  } = useSelector((state) => state.storage);
  const { filesUpload, setfilesUpload } = useContext(LoginContext);
  const [checkedItems, setCheckedItems] = useState([]);
  const [email, setEmail] = useState("");
  const [feedbackSubmitCheck, setFeedbackSubmitCheck] = useState('');
  const [notify, setNotify] = useState(null);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);
  const [notifyError, setNotifyError] = useState(true);
  const [treeLoader, setTreeLoader] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [feedbackData, setFeedbackData] = useState("");
  const isTranscriptSelected = localStorage.getItem("isTranscriptSelected");
  const isMediaSelected = localStorage.getItem("isMediaSelected");
  const opts = getOpts();
  const isTreePageVisible = isTreePrepared && feedbackSubmitCheck === "true";
  const isFeedbackFormVisible = (!isTreePrepared && feedbackSubmitCheck !== "true") || (isTreePrepared && feedbackSubmitCheck !== "true")
  const isLoaderVisible = !isTreePrepared && feedbackSubmitCheck === "true" && treeLoader;
  const [containerWidth, setContainerWidth] = useState(0);

  const handleApiNotify = (notifyMessage) => {
    setNotify(notifyMessage);
    setShowNotifyModal(true);
  };
  const handleCloseNotify = () => {
    setNotify(null);
    setShowNotifyModal(false);
  };

  const fetchDoctorData = async () => {
    try {
      const response = await axios.get(`${host}/get_approved_doctors`,
      {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      });  
      const data = response.data;
      setDoctorList(data.doctor_data);

    } catch (error) {
      console.error("Error fetching doctors:", error);
    }
  }

  const downloadTreePdf = (opts) => {
    const svgElement = document.getElementById("ped");
    if (!svgElement) {
      console.error("SVG element not found.");
      handleApiNotify("SVG element not found. Please try again");
      return;
    }
    pedigreejs.pedigreejs_zooming.scale_to_fit(opts);
    setTimeout(() => {
      html2canvas(svgElement, { scale: 1.5 }).then((canvas) => {
        function isMobile() {
          const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
          return regex.test(navigator.userAgent);
        }
        let pdf = new jsPDF("landscape", "mm", "a4");
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        let imageWidth = pageWidth - 5;
        let imageHeight = pageHeight - 80;
        if (isMobile()) {
          imageWidth = 100
          imageHeight = 200
        }
        const x = (pageWidth - imageWidth) / 2;
        const y = (pageHeight - imageHeight) / 2;
        pdf.addImage(
          canvas.toDataURL("image/jpeg", 1.0),
          "JPEG",
          x,
          y,
          imageWidth,
          imageHeight
        );
        pdf.save("tree_diagram.pdf");
        
        setNotifyError(false);
        handleApiNotify("Downloading PDF...");
      });
    }, 2000);
  };

  const sendPdfToMail = (opts) => {
    const svgElement = document.getElementById("ped");
    if (!svgElement) {
      console.error("SVG element not found.");
      handleApiNotify("SVG element not found. Please try again later");
      return;
    }
    pedigreejs.pedigreejs_zooming.scale_to_fit(opts);
    setTimeout(() => {
      html2canvas(svgElement, { scale: 1.5 }).then((canvas) => {
        function isMobile() {
          const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
          return regex.test(navigator.userAgent);
        }
        let pdf = new jsPDF("landscape", "mm", "a4");
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        let imageWidth = pageWidth - 5;
        let imageHeight = pageHeight - 80;
        if (isMobile()) {
          imageWidth = 100
          imageHeight = 200
        }
        const x = (pageWidth - imageWidth) / 2;
        const y = (pageHeight - imageHeight) / 2;
        pdf.addImage(
          canvas.toDataURL("image/jpeg", 1.0),
          "JPEG",
          x,
          y,
          imageWidth,
          imageHeight
        );
        const pdfBlob = pdf.output("blob");
        const pdfFile = new File([pdfBlob], "tree_diagram.pdf", {
          type: "application/pdf",
        });
        const formData = new FormData();
        formData.append("upload_file", pdfFile);
        formData.append("pedigree_json_data", sessionStorage.getItem("PEDIGREE_pedigree_history_0"));
        formData.append("current_url", window.location.href);
        
        if (isTranscriptSelected === "true") {
          formData.append("tree_source","t");
        }
        else if (isMediaSelected === "true") {
          formData.append("tree_source","m");
        }
        else {
          formData.append("tree_source","c");
        }

        setIsEmailSending(true);
        if (!selectedDoctor) {
          setIsEmailSending(false);
          handleApiNotify("Please select a doctor.");
          return;
        }
        const [selectedEmail, selectedUserId] = selectedDoctor.split('|');
        formData.append("email", selectedEmail);
        formData.append("user_id", selectedUserId);
        formData.append("patient_id", userId);
        formData.append("file_name", userName);
         
        axios
          .post(`${host}/send_email`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `${getAccessToken()}`,
            },
          })
          .then((response) => {
            const data = response.data;

            if (data && data.success) {
              setIsEmailSending(false);
              setNotifyError(false);
              setShowDropdown(true);
              handleApiNotify("Email sent successfully!");
            } else {
              setIsEmailSending(false);
              handleApiNotify("Failed to send the email.");
            }
          })
          .catch((error) => {
            setIsEmailSending(false);
            console.error(error);
            handleApiNotify("An error occurred while sending the email.");
          });
      });
    }, 2000);
  };

  const handleFeedbackSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!isTreePrepared) {
        setTreeLoader(true);
      }
      localStorage.setItem("isFeedbackSubmit", true);
      setFeedbackSubmitCheck(localStorage.getItem("isFeedbackSubmit"));
      const response = await axios.post(
        `${host}/store_feedback_data`,
        {
          user_id: userId,
          feedback: feedbackData,
        },
        {
          headers: {
            Authorization: `${getAccessToken()}`,
          },
        }
      );
      const data = response.data;
      if (data.success) {
        handleApiNotify(data.message);
        setNotifyError(false);
      }
      else {
        handleApiNotify(data.message);
        console.error("Failed store feedback data");
      }
    }
    catch (error) {
      console.error("Error storing feedback data:", error);
      setNotifyError(true);
      handleApiNotify(error.response.data.message);
    }

  };

  const handleFeedbackInputChange = (e) => {
    setFeedbackData(e.target.value);
  };

  const updateSvgSize = (container) => {
    const parentWidth = container.clientWidth || 0;
    setContainerWidth(parentWidth);
    if (parentWidth > 0) {
      const data = sessionStorage.getItem("PEDIGREE_pedigree_history_0");
      if (data && isTreePrepared) {
        generatePedigreeTree(JSON.parse(data), {
          ...opts,
          width: parentWidth - 50,
        });
      }
    }
  };

  useEffect(() => {
    const container = document.getElementById("ped");
    if (container) {
      const resizeObserver = new ResizeObserver(() => {
        updateSvgSize(container);
      });
      resizeObserver.observe(container);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [isTreePrepared]);

  useEffect(() => {
    setTimeout(() => {
      setTreePrepared(true);
    }, 1000);
  }, []);

  useEffect(() => {
    const token= getAccessToken();
    const userType=getUserType(token);
    setFeedbackSubmitCheck(localStorage.getItem("isFeedbackSubmit"));
    fetchDoctorData()
    if (userType==="patient"){
        // This endpoint is for generating tree from forms
        let endpoint = `${host}/generate_tree?`;
        axios
          .post(
            endpoint,
            {},
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          )
          .then((response) => {
            const data = response.data;
            generatePedigreeTree(data.json_data, opts);
            setTreePrepared(true);
            dispatch(updateState({ field: 'userId', value : data.user_id }));
            dispatch(updateState({ field: 'userName', value : data.user_name }));
          })
          .catch((error) => {
            console.error(error);
            handleApiNotify(error);
          });
    } else {
      navigate("/");
    }
  }, [isTranscriptSelected, isMediaSelected]);

  return (
    <div className="tree-page">
      {!isFeedbackFormVisible && (
          <div className="button-container" style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between', marginBottom: '20px' }}>
              <BackButton />
              {isTreePageVisible && <LogoutButton />}
          </div>
      )}
      {isTreePageVisible && <div className="col-md-2 navbar-user-name">User: {userName}</div>}
      {isFeedbackFormVisible ? (
        <>
          <FeedbackForm
            handleFeedbackSubmit={handleFeedbackSubmit}
            handleFeedbackInputChange={handleFeedbackInputChange}
          />
        </>
      ) : isLoaderVisible ? (
        <Loader />
      ) : (
        <></>
      )}
      {isTreePageVisible && (
        <div className="input-container">
          {showDropdown && (
            <div className="email-box" style={{ display: doctorList.length ? "" : "none" }}>
              <div>
                <Form.Select value={selectedDoctor} onChange={(e) => setSelectedDoctor(e.target.value)}>
                  <option>Select a doctor</option>
                  {doctorList.map((doctor) => (
                    <option value={`${doctor.user_email}|${doctor.user_id}`}>{doctor.user_name}</option>
                  ))}
                </Form.Select>
              </div>
              <div className="send-btn">
                <button
                  type="submit"
                  onClick={() => sendPdfToMail(opts)}
                  className="btn btn-primary send-button"
                  disabled={!selectedDoctor.trim()}
                >
                  {isEmailSending ? <Spinner animation="border" variant="info" size="sm" /> : "Send"}
                </button>
              </div>
            </div>
          )}

          <div className="pdf-btn">
            <button className="pdf-download-btn" onClick={() => downloadTreePdf(opts)} title="Download Tree PDF">
              <i className="fas fa-download download-icon"></i>
            </button>
          </div>
        </div>
      )}

      <div className="tree-container" style={{ display: isTreePrepared && feedbackSubmitCheck === "true" ? "" : "none" }}>
        <div id="pedigree_history"></div>
        <div id="ped"></div>
        <div id="node_properties"></div>
      </div>
      {isTreePageVisible && (
        <div>
          <div className="note-text">
            <p>Above diagram is draggable</p>
          </div>
          <p className="note-text">
            Disclaimer: Generated diagrams are solely based on data provided to bot, ensuring safety and accuracy is the
            responsibility of the user. Exercise caution and verification when interpreting bot-generated diagrams.
          </p>
        </div>
      )}
      <NotifyModal
        notifyMessage={notify}
        show={showNotifyModal}
        onClose={handleCloseNotify}
        isLoading={false}
        notifyError={notifyError}
      />
    </div>
  );
}

export default TreePage;
