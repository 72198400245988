import React, { useState, useEffect } from "react";
import "./FamilyTreePage.css";
import $ from "jquery";
import { Button } from "react-bootstrap";
import LogoutButton from "../../components/logoutButton/LogoutButton";
import { generatePedigreeTree } from "../utility/utility";

function FamilyTreePage() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false)

  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      setSelectedFile(file);
      setIsFileSelected(true)
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file !== null && file !== undefined) {
      setSelectedFile(file)
      setIsFileSelected(true)
    }
  };

  const handleFileUpload = () => {
    if (selectedFile !== null && selectedFile !== undefined) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result;
        localStorage.setItem("treeData", fileContent);
        setFileUploaded(true);
      };
      reader.readAsText(selectedFile);
    }
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById('file-input');
    fileInput.click();
  }


  useEffect(() => {
    if (selectedFile && fileUploaded) {
      generatePedigreeTree(JSON.parse(localStorage.getItem("treeData")));
    }
  }, [selectedFile, fileUploaded]);

  return (
    <div className="family-tree">
      <LogoutButton />
      <div className="json-upload-container">
        {!fileUploaded && (
          <div className="upload-json-section">
            <h2 className="upload-json-heading">Upload JSON File</h2>
            <div className="upload-section">
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={triggerFileInput}
                className="upload-json-area"
              >
                {selectedFile !== null && selectedFile !== undefined ?
                  (<p>{selectedFile.name}</p>) :
                  (
                    <div>
                      <p><strong>Drag & Drop file</strong></p>
                      <p><strong>Or</strong></p>
                      <p><strong><label for="file-input" class="browse-btn">Browse</label></strong></p>
                      <p><small>Supports JSON file</small></p>
                      <p><small>(Max 10MB)</small></p>
                    </div>
                  )
                }
                <input
                  type="file"
                  accept=".json"
                  id="file-input"
                  class="file-input"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className="json-upload-button">
              <Button className="btn btn-primary upload-json-btn" onClick={handleFileUpload} disabled={!isFileSelected}>
                Upload & generate tree
              </Button>
            </div>
            <div>
              <p className="family-tree-content">ⓘ The family tree can be generated using the JSON file received via email.</p>
            </div>

          </div>

        )}
      </div>
      <div className="family-tree-container">
        <div id="pedigree_history"></div>
        <div id="ped"></div>
        <div id="node_properties"></div>
      </div>

    </div>
  );
}

export default FamilyTreePage;
