import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { SERVER_HOST } from "../../env";
import axios from "axios";
import NotifyModal from "../../components/modals/notifyModal/NotifyModal";
import FilesUploadingModal from "../../components/modals/FilesUploadingModal";
import LogoutButton from "../../components/logoutButton/LogoutButton";
import "./MediaPage.css";
import sample_media from "../../sampleMedia/helen_evans_convo.mp3"
import { getAccessToken } from "../utility/utility";

const host = SERVER_HOST;

const MediaPage = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();
  const [showUploadModal, setShowUploadModal] = useState(false);

  const [notify, setNotify] = useState(false);
  const [notifyError, setNotifyError] = useState(true);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const {userId} = useSelector((state) => state.storage)

  const handleApiNotify = (notifyMessage) => {
    setNotify(notifyMessage);
    setShowNotifyModal(true);
  };
  const handleCloseNotify = () => {
    setNotify(null);
    setShowNotifyModal(false);
    navigate("/tree");
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      setSelectedFile(file);
      setUploadSuccess(true);
    }

  };

  const handleDragOver = (e) => {
    e.preventDefault();
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0]
    if (file !== null && file !== undefined) {
      setSelectedFile(file);
      setUploadSuccess(true);
    }
  };

  const handleToggle = () => {
    if (window.location.pathname === "/chat") {
      navigate("/media");
    } else {
      navigate("/chat");
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      handleApiNotify("Please first select a file");
      return;
    }
    // To clear the tree data, media name and path before generating tree from new file
    localStorage.removeItem("treeData");
    localStorage.removeItem("treeData");
    localStorage.setItem("isTranscriptSelected", false);
    setShowUploadModal(true);
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const apiEndpoint = `${host}/generate_tree_from_media`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          Authorization: `${getAccessToken()}`,
        },
      });

      if (response.status === 200) {
        setNotifyError(false);
        handleApiNotify("File upload successful");
        localStorage.setItem("isMediaSelected", true);
        const data = response.data;
        localStorage.setItem("treeData", JSON.stringify(data.response));
      } else {
        handleApiNotify("Error in file upload");
        localStorage.setItem("isMediaSelected", false);
      }
    } catch (error) {
      handleApiNotify("Error occurred while uploading the audio file");
    }
    setShowUploadModal(false);
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById('file-input');
    fileInput.click();
  }

  return (
    <div className="media-page-container">
      <LogoutButton />
      <div className="upload-media-section">
        <h2 className="media-page-heading">Media File Upload </h2>
        <div className="upload-file-section">
          <div className="upload-media-area" onDrop={handleDrop} onDragOver={handleDragOver} onClick={triggerFileInput}>
            {selectedFile !== null && selectedFile !== undefined ?
              (<p>{selectedFile.name}</p>) :
              (
                <div>
                  <p><strong>Drag & Drop file</strong></p>
                  <p><strong>Or</strong></p>
                  <p><strong><label for="file-input" class="browse-btn">Browse</label></strong></p>
                  <p><small>Supports mp3, mp4 and wav file</small></p>
                  <p><small>(Max 10MB)</small></p>
                </div>
              )
            }
            <input
              type="file"
              accept=".mp3, .mp4, .wav"
              id="file-input"
              class="file-input"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <NotifyModal
          notifyMessage={notify}
          show={showNotifyModal}
          onClose={handleCloseNotify}
          isLoading={false}
          notifyError={notifyError}
        />
        <FilesUploadingModal
          isOpen={showUploadModal}
          uploadSuccess={false} // You can modify this based on your upload success status
          uploadError={false} // You can modify this based on your upload error status
          handleClose={() => setShowUploadModal(false)}
        />
      </div>

      <div className="upload-media-btn">
        <Button
          className="btn btn-primary upload-button"
          disabled={!uploadSuccess}
          onClick={handleUpload}
        >
          Upload & generate tree
        </Button>
      </div>

      <div className="btn-options">

        <div className="btn-options-heading">
          <p>Use Sample Document or go back to Chat</p>
        </div>

        <div className="options">
          <div className="btn-option">
            <a
              href={sample_media}
              download="audio.mp3"
            >
              <div>Download Sample File</div>
            </a>
          </div>

          <div className="btn-option">
            <div onClick={handleToggle}>
              Go back to Chat
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default MediaPage;
