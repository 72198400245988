import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { updateSiblingField as updateFatherFullSiblingField } from "../../../store/fatherFullSiblingFormSlice";
import { updateSiblingField as updateFatherHalfSiblingField } from "../../../store/fatherHalfSiblingFormSlice";
import { updateSiblingField as updateMotherFullSiblingField } from "../../../store/motherFullSiblingFormSlice";
import { updateSiblingField as updateMotherHalfSiblingField } from "../../../store/motherHalfSiblingFormSlice";
import {
  NameInput,
  DivorcedAndRelatedByBloodInput,
  DobOrAgeInput,
  AliveOrDeceasedInput,
  AdditionalInfoInput,
} from "../commonFormFields/CommonFormFields";
import {clearFieldError} from "../../../pages/utility/utility"

const MarriageForm = ({ siblingIndex, nodeRelation, errors, setErrors }) => {
  const dispatch = useDispatch();
  const fatherFullSiblings = useSelector((state) => state.fatherFullSiblingForm.siblings[siblingIndex]);
  const fatherHalfSiblings = useSelector((state) => state.fatherHalfSiblingForm.siblings[siblingIndex]);
  const motherFullSiblings = useSelector((state) => state.motherFullSiblingForm.siblings[siblingIndex]);
  const motherHalfSiblings = useSelector((state) => state.motherHalfSiblingForm.siblings[siblingIndex]);

  const isFatherFullSiblingSubmitted = useSelector((state) => state.fatherFullSiblingForm.isSubmitted);
  const isFatherHalfSiblingSubmitted = useSelector((state) => state.fatherHalfSiblingForm.isSubmitted);
  const isMotherFullSiblingSubmitted = useSelector((state) => state.motherFullSiblingForm.isSubmitted);
  const isMotherHalfSiblingSubmitted = useSelector((state) => state.motherHalfSiblingForm.isSubmitted);

  const isEditModeInFatherFullSibling = useSelector((state) => state.fatherFullSiblingForm.isEditMode);
  const isEditModeFatherHalfSibling = useSelector((state) => state.fatherHalfSiblingForm.isEditMode);
  const isEditModeMotherFullSibling = useSelector((state) => state.motherFullSiblingForm.isEditMode);
  const isEditModeMotherHalfSibling = useSelector((state) => state.motherHalfSiblingForm.isEditMode);

  const getSiblingsData = () => {
    switch (nodeRelation) {
      case "PFS":
        return { sibling: fatherFullSiblings, isSubmitted: isFatherFullSiblingSubmitted, isEditMode: isEditModeInFatherFullSibling };
      case "PFHS":
        return { sibling: fatherHalfSiblings, isSubmitted: isFatherHalfSiblingSubmitted, isEditMode: isEditModeFatherHalfSibling };
      case "PMS":
        return { sibling: motherFullSiblings, isSubmitted: isMotherFullSiblingSubmitted, isEditMode: isEditModeMotherFullSibling };
      case "PMHS":
        return { sibling: motherHalfSiblings, isSubmitted: isMotherHalfSiblingSubmitted, isEditMode: isEditModeMotherHalfSibling };
      default:
        return { sibling: null, isSubmitted: null, isEditMode: null };
    }
  };

  const { sibling, isSubmitted, isEditMode } = getSiblingsData();

  const disableFormFeilds = isSubmitted && !isEditMode;

  const handleUpdateField = (field, value) => {
    switch (nodeRelation) {
      case "PFS":
        dispatch(updateFatherFullSiblingField({ index: siblingIndex, field, value }));
        break;
      case "PFHS":
        dispatch(updateFatherHalfSiblingField({ index: siblingIndex, field, value }));
        break;
      case "PMS":
        dispatch(updateMotherFullSiblingField({ index: siblingIndex, field, value }));
        break;
      case "PMHS":
        dispatch(updateMotherHalfSiblingField({ index: siblingIndex, field, value }));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Form.Group className="chat-form-component-spacing">
        <label className="custom-radio-button">
          Yes
          <input
            type="radio"
            name={`${nodeRelation}-is-married-${siblingIndex}`}
            value={true}
            checked={sibling.isMarried}
            onChange={(e) => {
              handleUpdateField("isMarried", e.target.value === "true");
            }}
            disabled={isSubmitted && !isEditMode}
          />
          <span className="checkmark"></span>
        </label>
        <label className="custom-radio-button">
          No
          <input
            type="radio"
            name={`${nodeRelation}-is-married-${siblingIndex}`}
            value={false}
            checked={!sibling.isMarried}
            onChange={(e) => {
              handleUpdateField("isMarried", e.target.value === "true");
            }}
            disabled={isSubmitted && !isEditMode}
          />
          <span className="checkmark"></span>
        </label>
      </Form.Group>
      {sibling.isMarried && (
        <>
          <Form.Group className="label-input-gap">
            <NameInput
              firstName={sibling?.partnerFirstName}
              lastName={sibling?.partnerLastName}
              disableFormFeilds={disableFormFeilds}
              setFirstName={(value) => {
                handleUpdateField("partnerFirstName", value);
                clearFieldError("partnerFirstName", errors, setErrors);
              }}
              setLastName={(value) => handleUpdateField("partnerLastName", value)}
            />
            <DobOrAgeInput
              nodeRelation={`${nodeRelation}-spouse`}
              dob={sibling.partnerDob}
              age={sibling.partnerAge}
              disableFormFeilds={disableFormFeilds}
              setDob={(value) => {
                handleUpdateField("partnerDob", value);
                clearFieldError("partnerAge", errors, setErrors);
              }}
              setAge={(value) => {
                handleUpdateField("partnerAge", value);
                clearFieldError("partnerAge", errors, setErrors);
              }}
            />
            <AliveOrDeceasedInput
              nodeRelation={`${nodeRelation}-spouse`}
              isAlive={sibling.partnerIsAlive}
              dateOfDeath={sibling.partnerDateOfDeath}
              ageOfDeath={sibling.partnerAgeOfDeath}
              disableFormFeilds={disableFormFeilds}
              setIsAlive={(value) => handleUpdateField("partnerIsAlive", value)}
              setDateOfDeath={(value) => handleUpdateField("partnerDateOfDeath", value)}
              setAgeOfDeath={(value) => handleUpdateField("partnerAgeOfDeath", value)}
              siblingIndex={siblingIndex}
            />
            <DivorcedAndRelatedByBloodInput
              nodeRelation={`${nodeRelation}-spouse`}
              isBloodRelated={sibling.isBloodRelated}
              setIsBloodRelated={(value) => handleUpdateField("isBloodRelated", value)}
              isDivorced={sibling.isDivorced}
              setIsDivorced={(value) => handleUpdateField("isDivorced", value)}
              disableFormFeilds={disableFormFeilds}
            />
            <AdditionalInfoInput
              additionalInfo={sibling.partnerAdditionalInfo}
              disableFormFeilds={disableFormFeilds}
              setAdditionalInfo={(value) => handleUpdateField("partnerAdditionalInfo", value)}
            />
          </Form.Group>
        </>
      )}
    </>
  );
};

export default MarriageForm;
