import React, { useState, useRef, useEffect } from "react";
import "./CustomInput.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomInput = ({
  label,
  value,
  onChange,
  disabled,
  type = "text",
  min = null,
  maxInputLimit = null,
  isAgeInput = false,
  error = "",
  isAdditionalInfo = false,
}) => {
  const dateInputRef = useRef(null);
  const ageInputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker();
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (!isAdditionalInfo && type === "text") {
      if (/[^a-zA-Z\s]/.test(inputValue)) {
        return;
      }
    }
    if (isAdditionalInfo && inputValue.length > 200) {
      return;
    }
    if (type === "number") {
      if (inputValue === "") {
        onChange(e);
        return;
      }
      const numberValue = Number(inputValue);
      if (numberValue <= 0 || numberValue >= 116) {
        return;
      }
      if (isAgeInput && !Number.isInteger(numberValue)) {
        return;
      }
      if (maxInputLimit !== null && numberValue > maxInputLimit) {
        return;
      }
    }
    onChange(e);
  };
  
  const onKeyDown = (e) => {
    if (type === "number") {
      if (
        ["e", "E", "-", "+", "."].includes(e.key) || 
        (e.key < "0" || e.key > "9") && 
        e.key !== "Backspace"
      ) {
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    const preventScroll = (e) => {
      if (document.activeElement === ageInputRef.current) {
        e.preventDefault();
      }
    };
    window.addEventListener("wheel", preventScroll, { passive: false });

    return () => {
      window.removeEventListener("wheel", preventScroll);
    };
  }, []);

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const containerClass = `outlined-input-container ${isFocused || value  || value === 0 ? "focused" : ""}`;
  const inputClass = `outlined-input ${error && !value ? "input-error" : ""} ${disabled ? "disabled" : ""}`;

  return (
    <div className={containerClass}>
      {type == "date" ? (
        <DatePicker
          selected={value}
          onChange={(date) => handleChange({ target: { value: date } })}
          dateFormat="dd-MM-yyyy"
          placeholderText="DD-MM-YYYY" 
          className={`${inputClass} w-100`}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          maxDate={getCurrentDate()}
          disabled={disabled}
          ref={
            type === "date"
              ? dateInputRef
              : type === "number"
              ? ageInputRef
              : null
          }
        />
      ) : (
        <input
          type={type}
          className={inputClass}
          value={value !== undefined ? value : ""}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          disabled={disabled}
          onClick={type === "date" ? handleInputClick : null}
          min={type === "number" ? 0 : min}
          max={type === "date" ? getCurrentDate() : null}
          ref={
            type === "date"
              ? dateInputRef
              : type === "number"
              ? ageInputRef
              : null
          }
        />
      )}
      <label className={`outlined-label ${disabled ? "disabled" : ""}`}>
        {label}
      </label>
    </div>
  );
};

export default CustomInput;
