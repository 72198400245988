import React from "react";
import { Link } from "react-router-dom";
import "./FallbackPage.css";

const Fallback = () => {
  return (
    <div className="fallback-container">
      <h1 className="fallback-heading">404</h1>
      <p className="fallback-content ">
        This is not the web page you are looking for.
      </p>
      <Link to="/">Go to Home Page</Link>
    </div>
  );
};

export default Fallback;
