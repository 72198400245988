import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: '',
  lastName: '',
  dob: null,
  age: null,
  isAlive: true,
  dateOfDeath: null,
  ageOfDeath: null,
  gender: '',
  isAdopted: null,
  additionalInfo: null,
  errors: {},
  isSubmitted: false,
  isCollapsed: false,
  isEditMode: false,
};

const probandFormSlice = createSlice({
  name: 'probandForm',
  initialState,
  reducers: {
    setFormField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    resetForm: (state) => Object.assign(state, initialState),
    setSubmitted: (state, action) => {
      state.isSubmitted = action.payload;
    },
    setIsCollapsed(state, action){
      state.isCollapsed = action.payload
    },
    setIsEditMode(state, action) {  
      state.isEditMode = action.payload;
    }
  },
});

export const { setFormField, setErrors, resetForm, setSubmitted, setIsCollapsed, setIsEditMode } = probandFormSlice.actions;
export default probandFormSlice.reducer;
