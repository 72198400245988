import { createSlice } from '@reduxjs/toolkit';

const initialParentState = {
  firstName: "",
  lastName: "",
  dob: "",
  age: "",
  isAlive: true,
  dateOfDeath: null,
  ageOfDeath: null,
  isAdopted: null,
  additionalInfo: null,
  isBloodRelated: false,
  isDivorced: false,
};

const initialState = {
  father: { ...initialParentState },
  mother: {
    ...initialParentState,
    pregnancyLoss: false,
    numberOfLosses: 0,
    lossCauses: [],
  },
  isSubmitted: false,
  isCollapsed: false,
  isEditMode: false,
};

const parentsFormSlice = createSlice({
  name: 'parentsForm',
  initialState,
  reducers: {
    setFather(state, action) {
      state.father = { ...state.father, ...action.payload };
    },
    setMother(state, action) {
      state.mother = { ...state.mother, ...action.payload };
    },
    setIsSubmitted(state, action) {
      state.isSubmitted = action.payload;
    },
    setIsCollapsed(state, action){
      state.isCollapsed = action.payload
    },
    resetForm(state) {
      Object.assign(state, initialState);
    },
    setIsEditMode(state, action) {  
      state.isEditMode = action.payload;
    }
  },
});

export const {
  setFather,
  setMother,
  setIsSubmitted,
  setIsCollapsed,
  resetForm,
  setIsEditMode
} = parentsFormSlice.actions;

export default parentsFormSlice.reducer;
