import { resetForm as resetProbandForm } from '../store/probandFormSlice';
import { resetForm as resetParentForm } from '../store/parentsFormSlice';
import { resetForm as resetProbandFullSiblingForm } from '../store/probandFullSiblingFormSlice';
import { resetForm as resetProbandHalfSiblingForm } from '../store/probandHalfSiblingFormSlice';
import {resetForm as resetPaternalGrandParentForm} from '../store/paternalGrandParentFormSlice';
import {resetForm as resetMaternalGrandParentForm} from '../store/maternalGrandParentFormSlice';
import {resetForm as resetFatherFullSiblingForm} from '../store/fatherFullSiblingFormSlice';
import {resetForm as resetFatherHalfSiblingForm} from '../store/fatherHalfSiblingFormSlice';
import {resetForm as resetMotherFullSiblingForm} from '../store/motherHalfSiblingFormSlice';
import {resetForm as resetMotherHalfSiblingForm} from '../store/motherHalfSiblingFormSlice';
import {resetForm as resetProbandPartnerForm} from "../store/probandPartnerFormSlice";

export const clearSession = () => (dispatch) => {
  dispatch(resetProbandForm());
  dispatch(resetParentForm());
  dispatch(resetProbandFullSiblingForm());
  dispatch(resetProbandHalfSiblingForm());
  dispatch(resetPaternalGrandParentForm());
  dispatch(resetMaternalGrandParentForm());
  dispatch(resetFatherFullSiblingForm());
  dispatch(resetFatherHalfSiblingForm());
  dispatch(resetMotherFullSiblingForm());
  dispatch(resetMotherHalfSiblingForm());
  dispatch(resetProbandPartnerForm());
};
