import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Spinner } from "react-bootstrap";
import { SERVER_HOST } from "../../../env";
import axios from "axios";
import NotifyModal from "../notifyModal/NotifyModal";
import { getAccessToken } from "../../../pages/utility/utility";

const host = SERVER_HOST;

const PatientReferralModal = (props) => {
  const [email, setEmail] = useState([]);
  const [notify, setNotify] = useState(false);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [isNotifyError, setIsNotifyError] = useState(false);
  const [spin, setSpin] = useState(false);
  const url = props.currentUrl.replace("allpatients", "register");

  const defaultMessage = `You have been referred by a trusted healthcare professional, to join GeneticsCopilot a leading platform for exploring and understanding your genetic heritage. 
    Please create an account on GeneticsCoPilot for Family Tree Generation.
    Follow this link to visit the website : ${url} 

    Best regards,
    The GeneticsCopilot Team
    `;
  const [message, setMessage] = useState(defaultMessage);

  const handleApiNotify = (notifyMessage) => {
    setNotify(notifyMessage);
    setShowNotifyModal(true);
  };

  const handleCloseNotify = () => {
    setNotify(null);
    setShowNotifyModal(false);
  };

  const handleClick = () => {
    setSpin(true);
    if (props.referralMail) {
      handleSendMail();
    } else {
      addPatient();
    }
  };

  const addPatient = async () => {
    try {
      const response = await axios.post(
        `${host}/create_patient`,
        {
          patients: email,
          mail_message: message,
          add_user_flag: "true",
        },
        {
          headers: {
            Authorization: `${getAccessToken()}`,
          },
        }
      );

      if (response.data.success) {
        setIsNotifyError(false);
        handleApiNotify(response.data.message);
        setSpin(false);
        props.onClose();
        props.onHide();
      } else {
        setIsNotifyError(true);
        handleApiNotify(response.data.message);
        console.error("Invalid entry");
        setSpin(false);
      }
    } catch (error) {
      setIsNotifyError(true);
      handleApiNotify("Failed to add new entry");
      setSpin(false);
      console.error("Error", error);
    }
  };

  const handleSendMail = async () => {
    try {
      const response = await axios.post(
        `${host}/send_signup_mail`,
        {
          email_id: props.patientEmail,
          email_message: message,
        },
        {
          headers: {
            Authorization: `${getAccessToken()}`,
          },
        }
      );
      if (response.data.success) {
        setIsNotifyError(false);
        handleApiNotify(response.data.message);
        setSpin(false);
        props.onClose();
        props.onHide();
      } else {
        setIsNotifyError(true);
        handleApiNotify(response.data.message);
        setSpin(false);
      }
    } catch (error) {
      setIsNotifyError(true);
      handleApiNotify("Failed to send mail");
      setSpin(false);
      console.error("Error", error);
    }
  };

  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header>
          <Modal.Title>Add patient for referral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                defaultValue={props.patientEmail}
                onChange={(e) => setEmail([e.target.value])}
                disabled={props.disableFlag}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Text</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                defaultValue={defaultMessage}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => {
              handleClick();
            }}
            className="btn btn-primary"
          >
            {spin ? <Spinner animation="border" variant="info" size="sm" /> : "Send mail"}
          </Button>
        </Modal.Footer>
      </Modal>
      <NotifyModal
        notifyMessage={notify}
        show={showNotifyModal}
        onClose={handleCloseNotify}
        notifyError={isNotifyError}
      />
    </>
  );
};

export default PatientReferralModal;
