import React, { useState, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setFather,
  setMother,
  setIsEditMode,
  setIsSubmitted,
  setIsCollapsed as setIsParentsCollapsed,
} from "../../../store/parentsFormSlice";
import {setIsCollapsed as setIsProbandFullSiblingCollapsed} from "../../../store/probandFullSiblingFormSlice";
import { validateForm, clearFieldError, adjustLayout , scrollToElement, } from "../../../pages/utility/utility";
import "../../../style.css";
import {
  AdditionalInfoInput,
  AdoptionInput,
  DobOrAgeInput,
  NameInput,
  AliveOrDeceasedInput,
  DivorcedAndRelatedByBloodInput,
  PregnancyLossInput,
} from "../commonFormFields/CommonFormFields";
import CustomAccordion from "../../customAccordion/CustomAccordion";
import {CustomAlert, UpdateAlert} from "../../customAlert/CustomAlert";
import useEditModeFormNode from "../../../hooks/useEditModeFormNode";

const ParentsForm = ({ sendFormData, handleCancel, autoSaveFormData, nodeRelation, collapseAllExcept, savedChoice, currentChoice, formEditStatus }) => {
  const dispatch = useDispatch();
  const { father, mother, isSubmitted, isCollapsed, isEditMode } = useSelector((state) => state.parentsForm);
  const { conversationTone } = useSelector((state) => state.storage);
  const [errors, setErrors] = useState([]);
  const [isFatherCollapsed, setIsFatherCollapsed] = useState(false);
  const [isMotherCollapsed, setIsMotherCollapsed] = useState(true);
  const [isAdditionalInfoCollapsed, setIsAdditionalInfoCollapsed] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const areParentsBloodRelated = father.isBloodRelated || mother.isBloodRelated;
  const areParentsDivorced = father.isDivorced || mother.isDivorced;
  const disableFormFeilds = isSubmitted && !isEditMode;
  const [showSuccessAlert, setShowSuccessAlert] = useState(false); 
  const [showParentDetailsAlert, setShowParentDetailsAlert] = useState({ show: false, message: "" });
  const [initialFather, setInitialFather] = useState(father);
  const [initialMother, setInitialMother] = useState(mother);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const currentlyEditingFormId = useEditModeFormNode();
  useEffect(() => {
    if (isEditMode) {
      setInitialFather(father);
      setInitialMother(mother);
      setIsFormChanged(false);
    }
  }, [isEditMode]);

  useEffect(() => {
    const hasFatherChanged = JSON.stringify(father) !== JSON.stringify(initialFather);
    const hasMotherChanged = JSON.stringify(mother) !== JSON.stringify(initialMother);
    setIsFormChanged(hasFatherChanged || hasMotherChanged);
  }, [father, mother]);

  const [showSaveChangesAlert, setShowSaveChangesAlert] = useState(false);

  const formState = useSelector((state) => state.probandForm);
  const fatherAge = father.age; 
  const motherAge = mother.age; 
  const childAge = formState.age; 
  const toggleCollapse = (formAction) => {
    if( formEditStatus && formAction !== 'update' || formAction === 'continue'){
      setShowSaveChangesAlert(true);
      scrollToElement(currentlyEditingFormId);
      return;
    }
    dispatch(setIsEditMode(false));
    collapseAllExcept(nodeRelation, !isCollapsed);
  };

  const getFormData = (isParentsFormSubmitted) => {
    return {
      father: { ...father },
      mother: {
        ...mother,
        dateOfDeath: !mother.isAlive ? mother.dateOfDeath : null,
      },
      isFormSubmitted: isParentsFormSubmitted,
      isEditMode: isEditMode,
    };
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isSubmitted || isEditMode) {
        handleAutoSave();
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [father, mother, isSubmitted, isEditMode]);

  const handleAutoSave = () => {
    const isFormSubmitted = isEditMode ? true : false;
    const formData = getFormData(isFormSubmitted);
    const jsonFormData = JSON.stringify(formData);
    autoSaveFormData(jsonFormData, nodeRelation);
  };
  const validateAgeDifference = (fatherAge, motherAge, childAge) => {  
    fatherAge = fatherAge ? Number(fatherAge) : null; 
    motherAge = motherAge ? Number(motherAge) : null; 
    childAge = Number(childAge);
    const isFatherValid = fatherAge !== null ? fatherAge >= childAge + 15 : true;
    const isMotherValid = motherAge !== null ? motherAge >= childAge + 15 : true; 
    return {
      isFatherValid,
      isMotherValid,
      errors: {
        father: isFatherValid ? null : "The age difference between parents and their offspring must be more than 15 years",
        mother: isMotherValid ? null : "The age difference between parents and their offspring must be more than 15 years"
      }
    };
  };

  const submitForm = (e) => {
    e.preventDefault();
    const isFatherIncomplete = !father.firstName && !father.lastName && !father.age && !father.dob;
    const isMotherIncomplete = !mother.firstName && !mother.lastName && !mother.age && !mother.dob;  
    if (isFatherIncomplete && isMotherIncomplete) {
      setShowParentDetailsAlert({ show: true, message: "We have found that parents' details are not filled. Do you want to continue without entering them?" });
      return;
    } else if (isFatherIncomplete) {
      setShowParentDetailsAlert({ show: true, message: "We have found that father's details are not filled. Do you want to continue without entering them?" });
      return;
    } else if (isMotherIncomplete) {
      setShowParentDetailsAlert({ show: true, message: "We have found that mother's details are not filled. Do you want to continue without entering them?" });
      return;
    }
    if (!isEditMode || isFormChanged) {
      handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    const parents = {
      father: { ...father },
      mother: { ...mother },
    };
    const formErrors = validateForm(parents, nodeRelation);
    const hasErrors = Object.values(formErrors).some((value) => value !== undefined);
    if (!hasErrors) {
      setIsSubmitting(true);
      const formAction = isEditMode ? "update" : "continue";
      dispatch(setIsSubmitted(true));
      dispatch(setIsParentsCollapsed(true));
      const formData = getFormData(true);
      const jsonFormData = JSON.stringify(formData);
      sendFormData(jsonFormData, conversationTone, nodeRelation, "NA", formAction)
        .then(() => {
          if (isEditMode) {
            setShowSuccessAlert(true);
            toggleCollapse(formAction);
          }
        })
        .catch(() => {
          if (isEditMode) {
            alert("Error updating data. Please try again.");
          }
        });
      dispatch(setIsProbandFullSiblingCollapsed(true));
      dispatch(setIsEditMode(false));
      setIsSubmitting(false);
    } else {
      setErrors(formErrors);
    }
  };
  const handleAlertContinue = () => {
    setShowParentDetailsAlert({ show: false, message: "" });
    handleSubmit(); 
  };

  const handleAlertNo = () => {
    setShowParentDetailsAlert({ show: false, message: "" });
  };
  const handleCancelEdit = () => {
    setShowAlert(false);
    handleCancel(nodeRelation, "cancel");
  };

  const createParentFields = (parent, role, setParent) => (
    <>
      <NameInput
        disableFormFeilds={disableFormFeilds}
        firstName={parent.firstName}
        lastName={parent.lastName}
        setFirstName={(value) => {
          dispatch(setParent({ firstName: value }));
          clearFieldError(`${role}FirstName`, errors, setErrors);
        }}
        setLastName={(value) => dispatch(setParent({ lastName: value }))}
      />
      <DobOrAgeInput
        nodeRelation={nodeRelation}
        dob={parent.dob}
        age={parent.age}
        disableFormFeilds={disableFormFeilds}
        setDob={(value) => {
          dispatch(setParent({ dob: value }));
          clearFieldError(`${role}Age`, errors, setErrors);
        }}
        setAge={(value) => {
          dispatch(setParent({ age: value }));
          clearFieldError(`${role}Age`, errors, setErrors);
        }}
      />
      <AliveOrDeceasedInput
        nodeRelation={role}
        dob={parent.dob}
        age={parent.age}
        isAlive={parent.isAlive}
        dateOfDeath={parent.dateOfDeath}
        ageOfDeath={parent.ageOfDeath}
        disableFormFeilds={disableFormFeilds}
        setIsAlive={(value) => dispatch(setParent({ isAlive: value }))}
        setDateOfDeath={(value) => dispatch(setParent({ dateOfDeath: value }))}
        setAgeOfDeath={(value) => dispatch(setParent({ageOfDeath: value}))}
      />
      <AdoptionInput
        nodeRelation={role}
        isAdopted={parent.isAdopted}
        disableFormFeilds={disableFormFeilds}
        setIsAdopted={(value) => dispatch(setParent({ isAdopted: value }))}
      />
      {role === "mother" && (
        <PregnancyLossInput
          nodeRelation={role}
          entity={parent}
          setEntity={(data) => dispatch(setMother(data))}
          disableFormFeilds={disableFormFeilds}
          errors={errors}
          clearFieldError={clearFieldError}
          setErrors={setErrors}
        />
      )}
      <AdditionalInfoInput
        additionalInfo={parent.additionalInfo}
        disableFormFeilds={disableFormFeilds}
        setAdditionalInfo={(value) => dispatch(setParent({ additionalInfo: value }))}
      />
    </>
  );

  useEffect(() => {
    adjustLayout();
  }, [isCollapsed, isFatherCollapsed, isMotherCollapsed, isAdditionalInfoCollapsed]);

  const handleEditClick = () => {
    if(formEditStatus){
      setShowSaveChangesAlert(true);
      scrollToElement(currentlyEditingFormId);
      return;
    }
    dispatch(setIsEditMode(true)); 
    collapseAllExcept(nodeRelation, false); 
    setIsFatherCollapsed(true);
    setIsMotherCollapsed(true);
    setIsAdditionalInfoCollapsed(true);
  };
  const handleSaveChangesAlertClose = () => {
    setShowSaveChangesAlert(false);
  };

  return (
    <div className={`${!isCollapsed ? "chat-form-container" : "collapsed-chat-form-container"}`}>
      <CustomAccordion
        title={`Biological Parents`}
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
        isSubmitted={isSubmitted}
        isHeading={true}
        handleEditClick={handleEditClick}
        savedChoice={savedChoice}
        currentChoice = {currentChoice}
        formEditStatus={isEditMode}
      >
        <Form onSubmit={submitForm} className={`chat-form ${isCollapsed ? "" : "show"}`}>
          {!isCollapsed && (
            <>
              <CustomAccordion
                title={`Biological Father : ${father.firstName} ${father.lastName}`}
                isCollapsed={isFatherCollapsed}
                isSubmitted={isSubmitted}
                toggleCollapse={() => {
                  setIsFatherCollapsed(!isFatherCollapsed);
                  setIsMotherCollapsed(true);
                  setIsAdditionalInfoCollapsed(true);
                }}
                isSubSection = {true}
              >
                {createParentFields(father, "father", setFather)}
              </CustomAccordion>
              <hr className="chat-form-separator" />
              <CustomAccordion
                title={`Biological Mother : ${mother.firstName} ${mother.lastName}`}
                isCollapsed={isMotherCollapsed}
                isSubmitted={isSubmitted}
                toggleCollapse={() => {
                  setIsMotherCollapsed(!isMotherCollapsed);
                  setIsFatherCollapsed(true);
                  setIsAdditionalInfoCollapsed(true);
                }}
                isSubSection = {true}
              >
                {createParentFields(mother, "mother", setMother)}
              </CustomAccordion>
              <hr className="chat-form-separator" />
              <CustomAccordion
                title="Additional Information"
                isCollapsed={isAdditionalInfoCollapsed}
                isSubmitted={isSubmitted}
                toggleCollapse={() => {
                  setIsAdditionalInfoCollapsed(!isAdditionalInfoCollapsed);
                  setIsMotherCollapsed(true);
                  setIsFatherCollapsed(true);
                }}
                isSubSection = {true}
              >
                <DivorcedAndRelatedByBloodInput
                  nodeRelation="Parents"
                  isBloodRelated={areParentsBloodRelated}
                  setIsBloodRelated={(value) => {
                    dispatch(setFather({ isBloodRelated: value }));
                    dispatch(setMother({ isBloodRelated: value }));
                  }}
                  isDivorced={areParentsDivorced}
                  setIsDivorced={(value) => {
                    dispatch(setFather({ isDivorced: value }));
                    dispatch(setMother({ isDivorced: value }));
                  }}
                  disableFormFeilds={disableFormFeilds}
                />
              </CustomAccordion>
              <div className="update-cancel-buttons">
              {(!isSubmitted || isEditMode) && ( 
                  <Button className="form-submit-btn" type="submit" disabled={(!isFormChanged || isSubmitting) && isEditMode}>
                    {isSubmitting ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        {isEditMode ? " Updating..." : " Submitting..."}
                      </>
                    ) : (
                      isEditMode ? "Update" : "Continue"
                    )}
                  </Button>
              )}

              {
                isEditMode && (
                  <div>
                    <Button className="cancel-btn" onClick={() => {
                      if (isFormChanged) {
                        setShowAlert(true);
                      } else {
                        handleCancelEdit();
                      }
                    }}>
                      Cancel
                    </Button>
                  </div>
                )
              }
              </div>
            </>
          )}
        </Form>
      </CustomAccordion>
      {showSaveChangesAlert && (
        <UpdateAlert
          title="Unsaved Changes"
          message="Please save your changes in the previous form."
          onConfirm={handleSaveChangesAlertClose}
          confirmButtonText="OK"
        />
      )}
      {showParentDetailsAlert.show && (
        <CustomAlert
          title="Incomplete Parents' Details"
          message={showParentDetailsAlert.message}
          onConfirm={handleAlertContinue}
          onCancel={handleAlertNo}
          confirmButtonText="Continue"
          cancelButtonText="No"
        />
      )}
      {showSuccessAlert && (
        <UpdateAlert
          title="Successs!"
          message="The information has been successfully updated."
          onConfirm={() => setShowSuccessAlert(false)}
        />
      )}
      {showAlert && (
        <CustomAlert
          title="Are you sure you want to cancel?"
          message="Unsaved data may be lost."
          onConfirm={handleCancelEdit}
          onCancel={() => setShowAlert(false)}
        />
      )}
    </div>
  );
};

export default ParentsForm;
