import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { SERVER_HOST } from "../../env";
import "./RegisterPage.css";
import { useNavigate } from "react-router-dom";
import NotifyModal from "../../components/modals/notifyModal/NotifyModal";
import RegisterForm from "../../components/forms/registerForm/RegisterForm";

const host = SERVER_HOST;

function RegisterPage() {
  const [showOTP, setShowOTP] = useState(false);
  const [showSignup, setShowSignup] = useState(true);
  const [formData, setFormData] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [activeTab, setActiveTab] = useState("patient");
  const [timer, setTimer ] = useState(60);
  const [showSubmitOTP, setShowSubmitOTP] = useState(true); 
  const [userType, setUserType] = useState("");

  const navigate = useNavigate();

  const [notify, setNotify] = useState(false);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [notifyError, setNotifyError]= useState(true);
  const [loading, setLoading] = useState(false);

  const handleTabChange = (key) => {
    setActiveTab(key);
    setShowSignup(true);
    setShowSuccessMessage(false);
    setShowOTP(false);
    setNotify(null);
    setShowNotifyModal(false);
    setFormData({});
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 5 * 1024 * 1024) {
      e.target.setCustomValidity("File size should be less than 5 MB");
    } else if (file && file.type !== "application/pdf") {
      e.target.setCustomValidity("File must be in PDF format");
    } else {
      e.target.setCustomValidity("");
    }
  };

  const handleApiNotify = (notifyMessage) => {
    setNotify(notifyMessage);
    setShowNotifyModal(true);
  };

  const handleCloseNotify = () => {
    setNotify(null);
    setShowNotifyModal(false);
  };

  const formSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const otp = form.otp.value.trim();
    try {
      setLoading(true);
      const verifyResponse = await axios.get(
        `${host}/verify_otp?otp=${otp}&email_id=${formData.email_id.trim()}`
      );

      if (verifyResponse.data.success) {
        let endpoint = "";

        if (activeTab === "patient") {
          endpoint = `${host}/patient_signup`;
        } else if (activeTab === "admin") {
          endpoint = `${host}/admin_signup`;
        } else {
          endpoint = `${host}/doctor_signup`;
        }

        const response = await axios.post(endpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.success) {
          setShowOTP(false);
          setSuccessMessage(
            "We have received your information and will require up to 24 hours for verification. Rest assured, we will provide updates via email. Upon completion of the verification process, login credentials will be sent to your email address."
          );
          setShowSuccessMessage(true);
          setShowSignup(false);
          handleApiNotify("Your registration details have been successfully submitted.");
          setNotifyError(false);
        } else {
          handleApiNotify("Failed to send register credentials.");
          console.error("Failed to send register credentials.");
        }
      } else {
        handleApiNotify("Failed to verify OTP");
        console.error("Failed to verify OTP.");
      }
    } catch (error) {
      setNotifyError(true);
      handleApiNotify(error.response.data.message);
    }
    finally{
      setLoading(false);
    }
  };

  const startOtpTimer = () => {
    let intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(intervalId);
          setShowSubmitOTP(false);
          return 60; // reset timer
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
  };

  const otpSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    let storeFormData = {};

    if (activeTab === "patient") {
      setUserType("patient");
      storeFormData = {
        username: form.username.value.trim(),
        dob: form.dob.value.trim(),
        phone: form.phone.value.trim(),
        email_id: form.email_id.value.trim(),
      };
    } else if (activeTab === "admin") {
      setUserType("admin");
      storeFormData = {
        name: form.name.value.trim(),
        birth_date: form.birth_date.value.trim(),
        phone: form.phone.value.trim(),
        email_id: form.email_id.value.trim(),
        country: form.country.value.trim(),
        doc: form.doc.files[0],
      };
    } else {
      setUserType("doctor");
      storeFormData = {
        name: form.name.value.trim(),
        birth_date: form.birth_date.value.trim(),
        phone: form.phone.value.trim(),
        email_id: form.email_id.value.trim(),
        reg_id: form.reg_id.value.trim(),
        country: form.country.value.trim(),
        doc: form.doc.files[0],
      };
    }

    setFormData(storeFormData);

    const requestData = {
      email_id: form.email_id.value.trim(),
      user_type: userType,
    };

    try {
      setLoading(true);
      const response = await axios.post(`${host}/send_otp`, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setShowOTP(true);
        setShowSignup(false);
        setNotifyError(false);
        setShowSubmitOTP(true);
        handleApiNotify("OTP sent successfully");
        startOtpTimer();
      }
    } catch (error) {
      handleApiNotify(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const resendOtp = async(event) =>{
    const requestData = {
      email_id: formData.email_id,
      user_type: userType,
    };

    try {
      setLoading(true);
      const response = await axios.post(`${host}/send_otp`, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setNotifyError(false);
        setShowSubmitOTP(true);
        startOtpTimer();
        handleApiNotify("OTP sent successfully");
      }
    } catch (error) {
      handleApiNotify(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const navigateToLogin = () => {
    navigate("/");
  };

  return (
    <div className="register-page">
      {showSuccessMessage && (
        <div className="register-success-message">
          <p>{successMessage}</p>
          <Button type="submit" onClick={navigateToLogin}>
            Log In
          </Button>
        </div>
      )}
      <div className={showSignup ? "register-show-form" : "register-hide-form"}>
        <header className="register-signup-page-heading">
          <h1>Sign Up</h1>
        </header>
        <section>
          <div className="container-fluid h-custom">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-0.5">
                <div className="text-center">
                  <h4 className="mt-1 mb-5 pb-1"></h4>
                </div>
                {/* Tabs */}
                <Tabs
                  id="controlled-tab-example"
                  activeKey={activeTab}
                  onSelect={(key) => handleTabChange(key)}
                  className="mb-3"
                >
                  <Tab eventKey="patient" title="Patient">
                    {/* Patient Form */}
                    <RegisterForm
                      onSubmit={otpSubmit}
                      fields={[
                        {
                          name: "username",
                          id: "01",
                          type: "text",
                          title: "Please enter valid name (letters only)",
                          label: "Name",
                          pattern: "^[A-Za-z\\s]+$",
                        },
                        {
                          name: "dob",
                          id: "02",
                          type: "date",
                          title: "Please select valid date of birth",
                          max: new Date().toISOString().split("T")[0],
                          label: "Date of Birth",
                        },
                        {
                          name: "phone",
                          id: "03",
                          type: "text",
                          pattern: "^[0-9]{10}$",
                          title: "Please enter a valid 10-digit phone number",
                          label: "Phone number",
                        },
                        {
                          name: "email_id",
                          id: "04",
                          type: "email",
                          title: "Please enter a valid email address",
                          label: "Email",
                        },
                      ]}
                      loading={loading}
                    />
                  </Tab>
                  <Tab eventKey="admin" title="Admin">
                    {/* Admin Form */}
                    <RegisterForm
                      onSubmit={otpSubmit}
                      fields={[
                        {
                          name: "name",
                          id: "01",
                          type: "text",
                          title: "Please enter your name",
                          label: "Name",
                          pattern: "^[A-Za-z\\s]+$",
                        },
                        {
                          name: "birth_date",
                          id: "02",
                          type: "date",
                          title: "Please select your date of birth",
                          max: new Date().toISOString().split("T")[0],
                          label: "Date of Birth",
                        },
                        {
                          name: "phone",
                          id: "03",
                          type: "text",
                          pattern: "^[0-9]{10}$",
                          title: "Please enter a valid 10-digit phone number",
                          label: "Phone number",
                        },
                        {
                          name: "email_id",
                          id: "04",
                          type: "email",
                          title: "Please enter a valid email address",
                          label: "Email",
                        },
                        {
                          name: "country",
                          id: "06",
                          type: "text",
                          title: "Please enter your current country",
                          label: "Country",
                          pattern: "^[A-Za-z\\s]+$",
                        },
                        {
                          name: "doc",
                          id: "08",
                          type: "file",
                          accept: ".pdf",
                          onChange: handleFileChange,
                          label: "Document upload",
                        },
                      ]}
                      loading={loading}
                      handleFileChange={handleFileChange}
                    />
                  </Tab>
                  <Tab eventKey="doctor" title="Doctor">
                    {/* Doctor Form */}
                    <RegisterForm
                      onSubmit={otpSubmit}
                      fields={[
                        {
                          name: "name",
                          id: "01",
                          type: "text",
                          title: "Please enter your name",
                          label: "Name",
                          pattern: "^[A-Za-z\\s]+$",
                        },
                        {
                          name: "birth_date",
                          id: "02",
                          type: "date",
                          title: "Please select your date of birth",
                          max: new Date().toISOString().split("T")[0],
                          label: "Date",
                        },
                        {
                          name: "phone",
                          id: "03",
                          type: "text",
                          pattern: "^[0-9]{10}$",
                          title: "Please enter a 10-digit phone number",
                          label: "Phone number",
                        },
                        {
                          name: "email_id",
                          id: "04",
                          type: "email",
                          title: "Please enter a valid email address",
                          label: " Email",
                        },
                        {
                          name: "reg_id",
                          id: "05",
                          type: "text",
                          title: "Please enter your registration id",
                          label: "Registration ID",
                        },
                        {
                          name: "country",
                          id: "06",
                          type: "text",
                          title: "Please enter your current country",
                          label: "Country",
                          pattern: "^[A-Za-z\\s]+$",
                        },
                        {
                          name: "doc",
                          id: "08",
                          type: "file",
                          accept: ".pdf",
                          onChange: handleFileChange,
                          label: "Document upload",
                        },
                      ]}
                      loading={loading}
                      handleFileChange={handleFileChange}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="container-fluid h-custom">
        <div className={showOTP ? "register-show-form" : "register-hide-form"}>
          <h2 className="register-otp-heading">Enter OTP</h2>
          <div className="container-fluid h-custom">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-0.5">
                <Form onSubmit={formSubmit}>
                  {/* OTP input field */}
                  <Row className="register-otp-form-div mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Control
                        name="otp"
                        required
                        type="text"
                        placeholder="OTP"
                        title="Please enter your OTP"
                        defaultValue=""
                      />
                      <Form.Control.Feedback></Form.Control.Feedback>
                      {showSubmitOTP && (
                        <small className="register-otp-timer">
                          {Math.floor(timer / 60)}:{timer % 60}s remaining
                        </small>
                      )}
                    </Form.Group>
                  </Row>
                  {/* Submit button */}
                  <Row className="mb-2">
                    <Col className="d-flex justify-content-center">
                      {showSubmitOTP && (
                        <Button
                          type="submit"
                          className="register-otp-submit-btn"
                          disabled={loading}
                        >
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Submit OTP"
                          )}
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {/* Resend OTP Button */}
                  <Row>
                    <Col className="d-flex justify-content-center">
                      {!showSubmitOTP && (
                        <Button
                          className="register-otp-submit-btn"
                          onClick={resendOtp}
                          disabled={loading}
                        >
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Resend OTP"
                          )}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NotifyModal
        notifyMessage={notify}
        show={showNotifyModal}
        onClose={handleCloseNotify}
        isLoading={false}
        notifyError={notifyError}
      />
    </div>
  );
}

export default RegisterPage;
