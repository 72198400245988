import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./LogoutButton.css"
import { clearSession } from "../../sessionStateHandler/sessionStateHandler";
import {CustomAlert} from "../customAlert/CustomAlert";
import { useState } from "react";
import { CLEAR_SESSION } from "../../store/store";

const LogoutButton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState(false);

    const handleLogout = () => {
            localStorage.clear();
            dispatch(clearSession());
            dispatch({ type: CLEAR_SESSION }); 
            document.cookie = "accessToken=;";
            navigate("/");
    };
    return (
    <>
      <div className="logout-btn-container">
        <Button variant="primary" onClick={() => setShowAlert(true)} className="btn btn-primary logout-btn">
          Logout
        </Button>
        <Button variant="primary" onClick={() => setShowAlert(true)} className="btn btn-primary logout-power-btn">
          <i className="fa-solid fa-power-off"></i>
        </Button>
      </div>
      {showAlert && (
        <CustomAlert
          title="Are you sure you want to log out?"
          message="Any unsaved changes may be lost."
          onCancel={() => setShowAlert(false)}
          onConfirm={handleLogout}
        />
      )}
    </>
    );
};

export default LogoutButton;
