import React, { useState, useEffect } from "react";
import Toast from "react-bootstrap/Toast";
import "./NotifyModal.css";

const NotifyModal = ({ notifyMessage, show, onClose, timer = 5000, notifyError = true}) => {
  const [visible, setVisible] = useState(show)

  useEffect(() => {
    if (show) {
      setVisible(true);
      const modalTimer = setTimeout(() => {
        setVisible(false);
        onClose();
      }, timer);

      return () => {
        clearTimeout(modalTimer);
      };
    }
  }, [show, timer, onClose]);

  return (
    <Toast
      show={visible}
      onClose={onClose}
      className={`toast-modal ${ notifyError ? "error-background" : "success-background" }`}
    >
      <Toast.Body className="d-flex align-items-center">
        <p className={`alert-message ${ notifyError ? "error-text" : "success-text"}`}>{notifyMessage} </p>
      </Toast.Body>
    </Toast>
  );
};

export default NotifyModal;
