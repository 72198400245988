import React from "react";
import "./Loader.css";

const Loader = (props) => {
  return (
    <div className={"loader-container"}>
      <div className={"loader"}></div>
      <div className="loader-text">Creating pedigree tree from your conversation, please wait ...</div>
    </div>
  );
};

export default Loader;
