import { createSlice } from "@reduxjs/toolkit";

const initialGrandParentState = {
  firstName: "",
  lastName: "",
  dob: "",
  age: "",
  isAlive: true,
  dateOfDeath: null,
  ageOfDeath: null,
  isAdopted: null,
  additionalInfo: null,
  isBloodRelated: false,
  isDivorced: false,
};

const initialState = {
  grandFather: { ...initialGrandParentState },
  grandMother: {
    ...initialGrandParentState,
    pregnancyLoss: false,
    numberOfLosses: 0,
    lossCauses: [],
  },
  isSubmitted: false,
  isCollapsed: false,
  isEditMode: false,
};

const paternalGrandParentsFormSlice = createSlice({
  name: "paternalGrandParentsForm",
  initialState,
  reducers: {
    updatePaternalGrandFather: (state, action) => {
      state.grandFather = { ...state.grandFather, ...action.payload };
    },
    updatePaternalGrandMother: (state, action) => {
      state.grandMother = { ...state.grandMother, ...action.payload };
    },
    updatePaternalGrandFatherAge: (state, action) => {
      state.grandFather.age = action.payload;
    },
    updatePaternalGrandMotherAge: (state, action) => {
      state.grandMother.age = action.payload;
    },
    setIsSubmitted(state, action) {
      state.isSubmitted = action.payload;
    },
    setIsCollapsed(state, action){
      state.isCollapsed = action.payload
    },
    setIsEditMode(state, action) {
      state.isEditMode = action.payload;
    },
    resetForm(state) {
      Object.assign(state, initialState);
    }
  },
});

export const { 
  updatePaternalGrandFather, 
  updatePaternalGrandMother, 
  updatePaternalGrandFatherAge,
  updatePaternalGrandMotherAge,
  setIsSubmitted, 
  setIsCollapsed,
  setIsEditMode,
  resetForm 
} = paternalGrandParentsFormSlice.actions;
export default paternalGrandParentsFormSlice.reducer;
