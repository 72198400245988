import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import "./RegisterForm.css"

const RegisterForm = ({ onSubmit, fields, loading, handleFileChange }) => {
  return (
    <Form onSubmit={onSubmit}>
      {fields.map((field) => (
        <Row key={field.name} className="mb-3">
          <Form.Group
            as={Col}
            md="12"
            controlId={`validationCustom${field.id}`}
          >
            <Form.Label className="input-label">{field.label}</Form.Label>
            <Form.Control
              name={field.name}
              required
              type={field.type}
              placeholder={field.placeholder}
              title={field.title}
              defaultValue=""
              {...(field.accept && { accept: field.accept })}
              {...(field.pattern && { pattern: field.pattern })}
              {...(field.max && { max: field.max })}
              {...(field.onChange && { onChange: field.onChange })}
              {...(field.type === "file" && { onChange: handleFileChange })}
            />
            {field.type === "file" && (
              <Form.Control.Feedback type="invalid">
                File size should be less than 5 MB
              </Form.Control.Feedback>
            )}
            {field.feedback && (
              <Form.Control.Feedback type="invalid">
                {field.feedback}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Row>
      ))}
      {/* Submit button */}
      <Row className="mb-2">
        <Col className="d-flex justify-content-center">
          <Button type="submit" id="submit-button" className="form-submit-btn" disabled={loading}>
            {loading ? (
              <span
                className="spinner-border spinner-border-sm spinner-btn"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Submit"
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RegisterForm;
