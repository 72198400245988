import React, { useState ,useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { SERVER_HOST } from "../../env";
import "./FreeTreePage.css";
import $ from "jquery";
import NotifyModal from "../../components/modals/notifyModal/NotifyModal";
import LogoutButton from "../../components/logoutButton/LogoutButton";
import {generatePedigreeTree,getUserType,getAccessToken} from "../utility/utility";
import { LoginContext } from "../../contexts/LoginContext";

const host = SERVER_HOST;

function FreeTreePage() {
  const { hashId } = useParams();
  const navigate = useNavigate();

  const { isLink, setIsLink } = useContext(LoginContext);
  const { path, setPath } = useContext(LoginContext);
  const [notifyError, setNotifyError] = useState(true);

  const [notify, setNotify] = useState(null);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [loadingSaveChanges, setLoadingSaveChanges] = useState(false);
  const [userCheck, setUserCheck] = useState('')

  const handleApiNotify = (notifyMessage) => {
    setNotify(notifyMessage);
    setShowNotifyModal(true);
  };
  const handleCloseNotify = () => {
    setNotify(null);
    setShowNotifyModal(false);
  };

  const saveJson = () => {
    let maxN = 0;

    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);

      if (key && key.startsWith('PEDIGREE_pedigree_history_')) {
        const n = parseInt(key.replace('PEDIGREE_pedigree_history_', ''), 10);

        if (n > maxN) {
          maxN = n;
        }
      }
    }

    const key = `PEDIGREE_pedigree_history_${maxN}`;
    const editedJson = sessionStorage.getItem(key);

    const requestData = {
      hash_id: hashId,
      json_data: editedJson
    };


    setLoadingSaveChanges(true);
    axios
      .post(`${host}/save_edited_json`, requestData, {
        headers: {
          Authorization: `${getAccessToken()}`,
        }
      })
      .then((response) => {
        const response_data = response.data;

        if (response_data.success) {
          setNotifyError(false);
          handleApiNotify("Changes saved successfully");
        } else {
          handleApiNotify("Failed to save the changes");
        }
      })
      .catch((error) => {
        console.error(error);
        handleApiNotify("An error occurred while saving changes");
      })
      .finally(() => {
        setLoadingSaveChanges(false);
      });
  };

  useEffect(() => {
    const token= getAccessToken();
    const userType=getUserType(token);
    if (userType==="doctor" || userType==="admin") {
      setUserCheck(userType)
      setIsLink(false);
      const endpoint = `${host}/get_free_tree_json_data/?hash_id=${hashId}`;
      axios
        .get(
          endpoint, {
          headers: {
            Authorization: `${token}`,
          }
        })
        .then((response) => {
          const treeData = response.data;
          if (treeData == null)
            handleApiNotify("Please check your hashID");
          generatePedigreeTree(treeData);
        })
        .catch((error) => {
          console.error(error);
          handleApiNotify(error);
        });
    }
    else {
      setIsLink(true);
      setPath(window.location.pathname)
      navigate("/");
    }
  },[navigate]);

  return (
    <>
      <LogoutButton/>
      <div className="free-tree-page">
        <div className="save-btn">
          {userCheck !== "admin" && (
            <button
              type="save"
              onClick={() => saveJson()}
              className="btn btn-primary"
              title="save changes made in the tree"
              disabled={loadingSaveChanges}
            >
              {loadingSaveChanges ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              ) : (
                "Save Changes"
              )}
            </button>
          )
          }
        </div>
        <div>
          <div className="free-tree-container">
            <div id="pedigree_history"></div>
            <div id="ped"></div>
            <div id="node_properties"></div>
          </div>
        </div>

      </div>
      <NotifyModal
        notifyMessage={notify}
        show={showNotifyModal}
        onClose={handleCloseNotify}
        isLoading={false}
        notifyError={notifyError}
      />
    </>
  );
}

export default FreeTreePage;
