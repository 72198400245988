import React from "react";
import Login from "../../components/login/Login";
import "./SuperAdminPage.css";

const SuperAdminPage = () => {
  return (
    <div className="super-admin-page">
      <Login />
    </div>
  );
};

export default SuperAdminPage;
