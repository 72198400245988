import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NameInput,
  DobOrAgeInput,
  AliveOrDeceasedInput,
  AdoptionInput,
  AdditionalInfoInput,
  SiblingRelationInput,
  SelectCommonParentInput,
  CommonParentNameInput,
} from "../commonFormFields/CommonFormFields";
import {
  deleteSibling as deleteProbandFullSibling,
  toggleSiblingCollapse as toggleProbandFullSiblingCollapse,
  updateSiblingField as updateProbandFullSiblingField,
} from "../../../store/probandFullSiblingFormSlice";
import {
  deleteSibling as deleteProbandHalfSibling,
  toggleSiblingCollapse as toggleProbandHalfSiblingCollapse,
  updateSiblingField as updateProbandHalfSiblingField,
} from "../../../store/probandHalfSiblingFormSlice";
import {
  deleteSibling as deleteFatherFullSibling,
  toggleSiblingCollapse as toggleFatherFullSiblingCollapse,
  toggleChildrenCollapse as toggleFatherFullSiblingChildrenCollapse,
  toggleMarriedCollapse as toggleFatherFullSiblingMarriedCollapse,
  updateSiblingField as updateFatherFullSiblingField,
} from "../../../store/fatherFullSiblingFormSlice";
import {
  deleteSibling as deleteFatherHalfSibling,
  toggleSiblingCollapse as toggleFatherHalfSiblingCollapse,
  toggleChildrenCollapse as toggleFatherHalfSiblingChildrenCollapse,
  toggleMarriedCollapse as toggleFatherHalfSiblingMarriedCollapse,
  updateSiblingField as updateFatherHalfSiblingField,
} from "../../../store/fatherHalfSiblingFormSlice";
import {
  deleteSibling as deleteMotherFullSibling,
  toggleSiblingCollapse as toggleMotherFullSiblingCollapse,
  toggleChildrenCollapse as toggleMotherFullSiblingChildrenCollapse,
  toggleMarriedCollapse as toggleMotherFullSiblingMarriedCollapse,
  updateSiblingField as updateMotherFullSiblingField,
} from "../../../store/motherFullSiblingFormSlice";
import {
  deleteSibling as deleteMotherHalfSibling,
  toggleSiblingCollapse as toggleMotherHalfSiblingCollapse,
  toggleChildrenCollapse as toggleMotherHalfSiblingChildrenCollapse,
  toggleMarriedCollapse as toggleMotherHalfSiblingMarriedCollapse,
  updateSiblingField as updateMotherHalfSiblingField,
} from "../../../store/motherHalfSiblingFormSlice";
import "../../../style.css";
import { clearFieldError } from "../../../pages/utility/utility";
import CustomAccordion from "../../customAccordion/CustomAccordion";
import ChildForm from "../childForm/ChildForm";
import MarriageForm from "../marriageForm/MarriageForm";

const Sibling = ({ siblingIndex, nodeRelation, getSiblingHeading, errors, setErrors, setSiblingCount, siblingCount,hasErrors, setSpouseAlert, formErrors, setButtonClicked, buttonClicked, setContinueButtonClicked,continueButtonClicked }) => {
  const probandFullSiblings = useSelector((state) => state.probandFullSiblingForm.siblings[siblingIndex]);
  const probandHalfSiblings = useSelector((state) => state.probandHalfSiblingForm.siblings[siblingIndex]);
  const fatherFullSiblings = useSelector((state) => state.fatherFullSiblingForm.siblings[siblingIndex]);
  const fatherHalfSiblings = useSelector((state) => state.fatherHalfSiblingForm.siblings[siblingIndex]);
  const motherFullSiblings = useSelector((state) => state.motherFullSiblingForm.siblings[siblingIndex]);
  const motherHalfSiblings = useSelector((state) => state.motherHalfSiblingForm.siblings[siblingIndex]);

  const isProbandFullSiblingSubmitted = useSelector((state) => state.probandFullSiblingForm.isSubmitted);
  const isProbandHalfSiblingSubmitted = useSelector((state) => state.probandHalfSiblingForm.isSubmitted);
  const isFatherFullSiblingSubmitted = useSelector((state) => state.fatherFullSiblingForm.isSubmitted);
  const isFatherHalfSiblingSubmitted = useSelector((state) => state.fatherHalfSiblingForm.isSubmitted);
  const isMotherFullSiblingSubmitted = useSelector((state) => state.motherFullSiblingForm.isSubmitted);
  const isMotherHalfSiblingSubmitted = useSelector((state) => state.motherHalfSiblingForm.isSubmitted);

  const isEditModeInProbandFullSibling = useSelector((state) => state.probandFullSiblingForm.isEditMode);
  const isEditModeInProbandHalfSibling = useSelector((state) => state.probandHalfSiblingForm.isEditMode);
  const isEditModeInFatherFullSibling = useSelector((state) => state.fatherFullSiblingForm.isEditMode);
  const isEditModeInFatherHalfSibling = useSelector((state) => state.fatherHalfSiblingForm.isEditMode);
  const isEditModeInMotherFullSibling = useSelector((state) => state.motherFullSiblingForm.isEditMode);
  const isEditModeInMotherHalfSibling = useSelector((state) => state.motherHalfSiblingForm.isEditMode);

  const isProbandFullSiblingSkipped = useSelector((state) => state.probandFullSiblingForm.isSkipped);
  const isProbandHalfSiblingSkipped = useSelector((state) => state.probandHalfSiblingForm.isSkipped);
  const isFatherFullSiblingSkipped = useSelector((state) => state.fatherFullSiblingForm.isSkipped);
  const isFatherHalfSiblingSkipped = useSelector((state) => state.fatherHalfSiblingForm.isSkipped);
  const isMotherFullSiblingSkipped = useSelector((state) => state.motherFullSiblingForm.isSkipped);
  const isMotherHalfSiblingSkipped = useSelector((state) => state.motherHalfSiblingForm.isSkipped);

  const dispatch = useDispatch();

  const nodeRelationsWithCommonParent = ["PHS", "PFHS", "PMHS"];
  const nodeRelationsWithChildren = ["PFS", "PFHS", "PMS", "PMHS"];

  const getSiblingsData = () => {
    switch (nodeRelation) {
      case "PS":
        return {
          siblings: probandFullSiblings,
          isSubmitted: isProbandFullSiblingSubmitted,
          isSkipped: isProbandFullSiblingSkipped,
          isEditMode: isEditModeInProbandFullSibling,
        };
      case "PHS":
        return {
          siblings: probandHalfSiblings,
          isSubmitted: isProbandHalfSiblingSubmitted,
          isSkipped: isProbandHalfSiblingSkipped,
          isEditMode: isEditModeInProbandHalfSibling,
        };
      case "PFS":
        return {
          siblings: fatherFullSiblings,
          isSubmitted: isFatherFullSiblingSubmitted,
          isSkipped: isFatherFullSiblingSkipped,
          isEditMode: isEditModeInFatherFullSibling,
        };
      case "PFHS":
        return {
          siblings: fatherHalfSiblings,
          isSubmitted: isFatherHalfSiblingSubmitted,
          isSkipped: isFatherHalfSiblingSkipped,
          isEditMode: isEditModeInFatherHalfSibling,
        };
      case "PMS":
        return {
          siblings: motherFullSiblings,
          isSubmitted: isMotherFullSiblingSubmitted,
          isSkipped: isMotherFullSiblingSkipped,
          isEditMode: isEditModeInMotherFullSibling,
        };
      case "PMHS":
        return {
          siblings: motherHalfSiblings,
          isSubmitted: isMotherHalfSiblingSubmitted,
          isSkipped: isMotherHalfSiblingSkipped,
          isEditMode: isEditModeInMotherHalfSibling,
        };
      default:
        return { siblings: null, isSubmitted: null, isSkipped: null, isEditMode: null };
    }
  };

  const { siblings, isSubmitted, isSkipped, isEditMode } = getSiblingsData();

  const disableFormFeilds = isSubmitted && !isEditMode;

  const handleUpdateField = (field, value) => {
    switch (nodeRelation) {
      case "PS":
        dispatch(updateProbandFullSiblingField({ index: siblingIndex, field, value }));
        break;
      case "PHS":
        dispatch(updateProbandHalfSiblingField({ index: siblingIndex, field, value }));
        break;
      case "PFS":
        dispatch(updateFatherFullSiblingField({ index: siblingIndex, field, value }));
        break;
      case "PFHS":
        dispatch(updateFatherHalfSiblingField({ index: siblingIndex, field, value }));
        break;
      case "PMS":
        dispatch(updateMotherFullSiblingField({ index: siblingIndex, field, value }));
        break;
      case "PMHS":
        dispatch(updateMotherHalfSiblingField({ index: siblingIndex, field, value }));
        break;
      default:
        break;
    }
  };

  const handleDeleteSibling = () => {
    if (siblingCount === 1) {
      handleUpdateField("firstName", "");
      handleUpdateField("lastName", "");
      handleUpdateField("dob", "");
      handleUpdateField("age", "");
      handleUpdateField("relationship", "");
      handleUpdateField("isAlive", true); 
      handleUpdateField("dateOfDeath", "");
      handleUpdateField("ageOfDeath", "");
      handleUpdateField("commonParent", "");
      handleUpdateField("isAdopted", false); 
      handleUpdateField("additionalInfo", "");
      handleUpdateField("children", []);
      handleUpdateField("spouse", {});
      handleUpdateField("hasChild", false);
    } else {
      if (siblingIndex === 0) {
        switch (nodeRelation) {
          case "PS":
            dispatch(deleteProbandFullSibling(0));
            break;
          case "PHS":
            dispatch(deleteProbandHalfSibling(0));
            break;
          case "PFS":
            dispatch(deleteFatherFullSibling(0));
            break;
          case "PFHS":
            dispatch(deleteFatherHalfSibling(0));
            break;
          case "PMS":
            dispatch(deleteMotherFullSibling(0));
            break;
          case "PMHS":
            dispatch(deleteMotherHalfSibling(0));
            break;
          default:
            break;
        }
      } else {
        switch (nodeRelation) {
          case "PS":
            dispatch(deleteProbandFullSibling(siblingIndex));
            break;
          case "PHS":
            dispatch(deleteProbandHalfSibling(siblingIndex));
            break;
          case "PFS":
            dispatch(deleteFatherFullSibling(siblingIndex));
            break;
          case "PFHS":
            dispatch(deleteFatherHalfSibling(siblingIndex));
            break;
          case "PMS":
            dispatch(deleteMotherFullSibling(siblingIndex));
            break;
          case "PMHS":
            dispatch(deleteMotherHalfSibling(siblingIndex));
            break;
          default:
            break;
        }
      }
      setSiblingCount((prevCount) => prevCount - 1);
    }
  };

  const handleToggleSiblingCollapse = () => {
    switch (nodeRelation) {
      case "PS":
        dispatch(toggleProbandFullSiblingCollapse(siblingIndex));
        break;
      case "PHS":
        dispatch(toggleProbandHalfSiblingCollapse(siblingIndex));
        break;
      case "PFS":
        dispatch(toggleFatherFullSiblingCollapse(siblingIndex));
        break;
      case "PFHS":
        dispatch(toggleFatherHalfSiblingCollapse(siblingIndex));
        break;
      case "PMS":
        dispatch(toggleMotherFullSiblingCollapse(siblingIndex));
        break;
      case "PMHS":
        dispatch(toggleMotherHalfSiblingCollapse(siblingIndex));
        break;
      default:
        break;
    }
  };

  const handleToggleChildrenCollapse = () => {
    switch (nodeRelation) {
      case "PFS":
        dispatch(toggleFatherFullSiblingChildrenCollapse(siblingIndex));
        if (siblings?.isMarriedCollapsed === false) {
          dispatch(toggleFatherFullSiblingMarriedCollapse(siblingIndex));
        }
        break;
      case "PFHS":
        dispatch(toggleFatherHalfSiblingChildrenCollapse(siblingIndex));
        if (siblings?.isMarriedCollapsed === false) {
          dispatch(toggleFatherHalfSiblingMarriedCollapse(siblingIndex));
        }
        break;
      case "PMS":
        dispatch(toggleMotherFullSiblingChildrenCollapse(siblingIndex));
        if (siblings?.isMarriedCollapsed === false) {
          dispatch(toggleMotherFullSiblingMarriedCollapse(siblingIndex));
        }
        break;
      case "PMHS":
        dispatch(toggleMotherHalfSiblingChildrenCollapse(siblingIndex));
        if (siblings?.isMarriedCollapsed === false) {
          dispatch(toggleMotherHalfSiblingMarriedCollapse(siblingIndex));
        }
        break;
      default:
        break;
    }
  };

  const handleToggleMarriageFormCollapse = () => {
    if(hasErrors) {
      setSpouseAlert(true);
      setButtonClicked(true);
      return;
    }
    switch (nodeRelation) {
      case "PFS":
        dispatch(toggleFatherFullSiblingMarriedCollapse(siblingIndex));
        if (siblings?.isChildrenCollapsed === false) {
          dispatch(toggleFatherFullSiblingChildrenCollapse(siblingIndex));
        }
        break;
      case "PFHS":
        dispatch(toggleFatherHalfSiblingMarriedCollapse(siblingIndex));
        if (siblings?.isChildrenCollapsed === false) {
          dispatch(toggleFatherHalfSiblingChildrenCollapse(siblingIndex));
        }
        break;
      case "PMS":
        dispatch(toggleMotherFullSiblingMarriedCollapse(siblingIndex));
        if (siblings?.isChildrenCollapsed === false) {
          dispatch(toggleMotherFullSiblingChildrenCollapse(siblingIndex));
        }
        break;
      case "PMHS":
        dispatch(toggleMotherHalfSiblingMarriedCollapse(siblingIndex));
        if (siblings?.isChildrenCollapsed === false) {
          dispatch(toggleMotherHalfSiblingChildrenCollapse(siblingIndex));
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <CustomAccordion
        title={getSiblingHeading(siblings)}
        isCollapsed={siblings?.isSiblingCollapsed}
        toggleCollapse={handleToggleSiblingCollapse}
        isSubmitted={isSubmitted}
        isSkipped = {isSkipped}
        isSubSection = {true}
        nodeRelation={nodeRelation}
        siblingIndex={siblingIndex}
        deleteNode={handleDeleteSibling}
        isChildForm = {true}
        isEditMode = {isEditMode}
      >
        <div className="sibling-form-body">
          <NameInput
            firstName={siblings?.firstName}
            lastName={siblings?.lastName}
            disableFormFeilds={disableFormFeilds}
            setFirstName={(value) => {
              handleUpdateField("firstName", value);
              clearFieldError("firstName", errors, setErrors);
            }}
            setLastName={(value) => handleUpdateField("lastName", value)}
          />
          <DobOrAgeInput
            nodeRelation={nodeRelation}
            dob={siblings?.dob}
            age={siblings?.age}
            disableFormFeilds={disableFormFeilds}
            setDob={(value) => {
              handleUpdateField("dob", value);
              clearFieldError("age", errors, setErrors);
            }}
            setAge={(value) => {
              handleUpdateField("age", value);
              clearFieldError("age", errors, setErrors);
            }}
          />
          <SiblingRelationInput
            nodeRelation={nodeRelation}
            siblingIndex={siblingIndex}
            relationship={siblings?.relationship}
            handleRelationshipChange={(value) => {
              handleUpdateField("relationship", value);
              clearFieldError("relationship", errors, setErrors);
            }}
            disableFormFeilds={disableFormFeilds}
            error={errors?.relationship}
          />
          <AliveOrDeceasedInput
            nodeRelation={nodeRelation}
            dob={siblings?.dob}
            age={siblings?.age}
            isAlive={siblings?.isAlive}
            disableFormFeilds={disableFormFeilds}
            dateOfDeath={siblings?.dateOfDeath}
            ageOfDeath={siblings?.ageOfDeath}
            setIsAlive={(value) => handleUpdateField("isAlive", value)}
            setDateOfDeath={(value) => handleUpdateField("dateOfDeath", value)}
            setAgeOfDeath={(value) => handleUpdateField("ageOfDeath", value)}
            siblingIndex={siblingIndex}
          />
          {nodeRelationsWithCommonParent.includes(nodeRelation) && (
            <>
              <SelectCommonParentInput
                nodeRelation={nodeRelation}
                siblingIndex={siblingIndex}
                commonParent={siblings?.commonParent}
                handleCommonParentChange={(value) => handleUpdateField("commonParent", value)}
                disableFormFeilds={disableFormFeilds}
                error={errors?.commonParent}
                clearFieldError={() => clearFieldError("commonParent", errors, setErrors)}
              />
              {siblings?.commonParent && (
                <CommonParentNameInput
                  nodeRelation={nodeRelation}
                  siblingIndex={siblingIndex}
                  commonParent={siblings?.commonParent}
                  disableFormFeilds={disableFormFeilds}
                />
              )}
            </>
          )}
          <AdoptionInput
            nodeRelation={nodeRelation}
            isAdopted={siblings?.isAdopted}
            disableFormFeilds={disableFormFeilds}
            setIsAdopted={(value) => handleUpdateField("isAdopted", value)}
            siblingIndex={siblingIndex}
          />
          <AdditionalInfoInput
            disableFormFeilds={disableFormFeilds}
            additionalInfo={siblings?.additionalInfo}
            setAdditionalInfo={(value) => handleUpdateField("additionalInfo", value)}
          />
        </div>
        {nodeRelationsWithChildren.includes(nodeRelation) && (
          <>
            <hr className="chat-form-separator" />
            <CustomAccordion
              title={`Children (Optional)`}
              isCollapsed={siblings?.isChildrenCollapsed}
              toggleCollapse={handleToggleChildrenCollapse}
              isSubmitted={isSubmitted}
              isSkipped = {isSkipped}
              isSubSection = {true}
              nodeRelation={nodeRelation}
              siblingIndex={siblingIndex}
              headingFlag="childrenSection"
            >
              <ChildForm nodeRelation={nodeRelation} parentIndex={siblingIndex} errors={errors} setErrors={setErrors} hasErrors={hasErrors} formErrors={formErrors} setButtonClicked={setButtonClicked} buttonClicked={buttonClicked} setContinueButtonClicked={setContinueButtonClicked} continueButtonClicked={continueButtonClicked} />
            </CustomAccordion>
            <hr className="chat-form-separator" />
            <CustomAccordion
              title={`Spouse Information`}
              isCollapsed={siblings?.isMarriedCollapsed}
              toggleCollapse={handleToggleMarriageFormCollapse}
              isSubmitted={isSubmitted}
              isSkipped={isSkipped}
              isSubSection={true}
              nodeRelation={nodeRelation}
              siblingIndex={siblingIndex}
              headingFlag="marriageForm"
            >
              <MarriageForm
                siblingIndex={siblingIndex}
                nodeRelation={nodeRelation}
                errors={errors}
                setErrors={setErrors}
              />
            </CustomAccordion>
          </>
        )}
      </CustomAccordion>
      <hr className="chat-form-separator" />
    </>
  );
};

export default Sibling;
