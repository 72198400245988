import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import "./backButton.css"; 

const BackButton = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
    <>
        <Button 
            variant="primary" 
            onClick={handleGoBack} 
            className="btn btn-primary back-btn" 
        >
            Back
        </Button>
        <Button variant="primary" onClick={handleGoBack} className="btn btn-primary back-arrow-btn">
          <i className="fa-solid fa-arrow-left"></i>
        </Button>
    </>  
    );
};

export default BackButton;
