import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { SERVER_HOST } from "../../env";
import "./TranscriptPage.css";
import FilesUploadingModal from "../../components/modals/FilesUploadingModal";
import LogoutButton from "../../components/logoutButton/LogoutButton";
import sample_pdf from "../../samplePdf/transcript2.pdf";
import { getAccessToken } from "../utility/utility";

const host = SERVER_HOST;

function TranscriptPage() {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const goBackToChat = () => {
    navigate("/chat");
  };

  const handleClose = () => {
    setIsLoading(false); // Hide the loading modal
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUploadError(null);
    setUploadSuccess(false);
    setIsLoading(false);
    setSelectedFile(file);
  };

  const handleFileUpload = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(`${host}/generate_tree_from_pdf`, formData,
        {
          headers: {
            Authorization: `${getAccessToken()}`,
          },
        });

      if (response.status === 200) {
        const data = response.data;
        localStorage.setItem("treeData", JSON.stringify(data.json_data));
        setUploadSuccess(true);
        setUploadError(null);
        localStorage.setItem("isTranscriptSelected", true);
      } else {
        setUploadSuccess(false);
        setUploadError("File upload failed");
        console.error("File upload failed.");
      }
    } catch (error) {
      setUploadSuccess(false);
      setUploadError("Network error: " + error.message);
    } finally {
      setIsLoading(false);
      handleClose();
      navigate("/tree");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setUploadError(null);
    setUploadSuccess(false);
    setIsLoading(false);
    setSelectedFile(file);
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById('file-input');
    fileInput.click();
  }

  return (
    <div className="transcript-page">
      <LogoutButton />
      <div className="upload-pdf-section">
        <h2 className="transcript-page-heading">Upload PDF</h2>
        <div className="left-section">
          <div
            onDrop={handleDrop}
            onDragOver={preventDefault}
            onClick={triggerFileInput}
            className="upload-area"
          >
            {selectedFile !== null && selectedFile !== undefined ?
              (<p>{selectedFile.name}</p>) :
              (
                <div>
                  <p><strong>Drag & Drop file</strong></p>
                  <p><strong>Or</strong></p>
                  <p><strong><label for="file-input" class="browse-btn">Browse</label></strong></p>
                  <p><small>Supports PDF file</small></p>
                  <p><small>(Max 10MB)</small></p>
                </div>
              )
            }
            <input
              type="file"
              accept=".pdf"
              id="file-input"
              class="file-input"
              onChange={handleFileChange}
            />
          </div>

          <div className="btn-section">
            <div className="btn-div">
              <Button
                onClick={handleFileUpload}
                className="btn btn-primary upload-btn"
                disabled={!selectedFile}
              >
                Upload PDF & Generate tree
              </Button>
            </div>
          </div>
        </div>

        <FilesUploadingModal
          isOpen={isLoading}
          uploadSuccess={uploadSuccess}
          uploadError={uploadError}
          handleClose={handleClose}
        />
      </div>
      <div className="transcript-btn-options">
        <div className="transcript-btn-heading">
          <p>Use Sample Document or go back to Chat</p>
        </div>

        <div className="goto-chat-btn">
          <div className="transcript-btn-option">
            <a
              href={sample_pdf}
              download="transcript.pdf"
            >
              <div>Download Sample File</div>
            </a>
          </div>
          <div className="transcript-btn-option">
            <div onClick={goBackToChat}>
              Go Back to Chat
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TranscriptPage;
